import React, { useState, useEffect, useMemo, useCallback } from "react";
import axiosInstance from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import config from "../config";
import { Table, Input, Button, Form, notification } from "antd";
import GenericSearch from "./GenericSearch";
import entitySearchConfig from "../entitySearchConfig";
import { getColumns } from "./ClientCreditMemoColumns"; // Assuming columns logic remains similar
import "./ClientCreditMemo.css"; // Create and use a new CSS file for ClientCreditMemo

Modal.setAppElement("#root");

const clientSearchConfig = {
  entity: "clients",
  metadataEndpoint: `${config.API_URL}/metadata/clients`,
  searchEndpoint: `${config.API_URL}/clients/search`,
  title: "Clients",
};

const ClientCreditMemo = () => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [openInvoices, setOpenInvoices] = useState([]);
  const [creditMemoAmount, setCreditMemoAmount] = useState("");
  const [creditMemoNumber, setCreditMemoNumber] = useState("");
  const [allocations, setAllocations] = useState({});
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedClient) {
      fetchOpenInvoices(selectedClient.id);
    }
  }, [selectedClient]);

  const fetchOpenInvoices = async (clientId) => {
    try {
      const response = await axiosInstance.get(
        `${config.API_URL}/invoices/client/${clientId}/open-invoices`
      );
      setOpenInvoices(response.data);
    } catch (error) {
      console.error("Error fetching open invoices:", error);
    }
  };

  const openClientModal = () => {
    setIsClientModalOpen(true);
  };

  const handleClientSelect = (client) => {
    // Clear out all state related to the previous client
    setSelectedClient(client);
    setOpenInvoices([]); // Reset open invoices
    setCreditMemoAmount(""); // Reset credit memo amount
    setCreditMemoNumber(""); // Reset credit memo number
    setAllocations({}); // Reset allocations

    // Update the form field with the selected client name
    form.setFieldsValue({
      client: client.name,
    });

    setIsClientModalOpen(false);
  };

  const onChangeInput = useCallback(
    (e, invoiceId) => {
      const { value } = e.target;

      const invoice = openInvoices.find((inv) => inv.id === Number(invoiceId));

      if (!invoice) {
        notification.error({
          message: "Invoice Not Found",
          description: `Invoice with ID ${invoiceId} was not found.`,
        });
        return;
      }

      if (parseFloat(value) > parseFloat(invoice.balance)) {
        notification.error({
          message: "Allocation Exceeds Invoice Balance",
          description: `You cannot allocate more than the remaining balance of ${invoice.balance}.`,
        });
        return;
      }

      setAllocations((prevAllocations) => ({
        ...prevAllocations,
        [invoiceId]: value, // Store as a string
      }));
    },
    [openInvoices]
  );

  const handleCreditMemoAmountChange = (event) => {
    setCreditMemoAmount(event.target.value);
  };

  const handleCreditMemoNumberChange = (event) => {
    setCreditMemoNumber(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      const payments = Object.keys(allocations)
        .filter((invoiceId) => allocations[invoiceId] > 0)
        .map((invoiceId) => {
          const invoice = openInvoices.find((inv) => inv.id === Number(invoiceId));

          if (!invoice) {
            console.warn(
              `Invoice with ID ${invoiceId} was not found. Skipping allocation.`
            );
            return null;
          }

          return {
            invoiceId,
            amount: allocations[invoiceId],
            clientId: invoice.clientId,
            closeInvoice: allocations[invoiceId] > 0,
          };
        })
        .filter(Boolean);

      if (payments.length === 0) {
        notification.error({
          message: "No valid allocations",
          description: "Please allocate amounts to at least one invoice.",
        });
        return;
      }

      const totalAllocation = payments.reduce(
        (acc, payment) => acc + parseFloat(payment.amount),
        0
      );

      const remainingAmount = parseFloat(creditMemoAmount) - totalAllocation;

      if (remainingAmount < 0) {
        notification.error({
          message: "Negative Remaining Amount",
          description: "The remaining amount cannot be negative.",
        });
        return;
      }

      const payload = {
        date: new Date(),
        clientId: selectedClient.id,
        creditMemoAmount: parseFloat(creditMemoAmount),
        remainingAmount,
        payments,
      };

      await axiosInstance.post(`${config.API_URL}/credit-memos/`, payload);

      notification.success({
        message: "Credit Memo Recorded",
        description: "The credit memo has been recorded successfully.",
      });

      navigate("/credit-memo-list");
    } catch (error) {
      console.error("Error processing credit memo:", error);
      notification.error({
        message: "Error",
        description: "There was an error processing the credit memo.",
      });
    }
  };

  const totalAllocation = useMemo(() => {
    return Object.values(allocations)
      .map((value) => parseFloat(value) || 0)
      .reduce((acc, value) => acc + value, 0);
  }, [allocations]);

  const remainingAmount = creditMemoAmount - totalAllocation;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const columns = useMemo(
    () => getColumns(openInvoices, allocations, onChangeInput, formatCurrency),
    [openInvoices, allocations, onChangeInput]
  );

  return (
    <div className="flexDiv">
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          creditMemoAmount,
          creditMemoNumber,
          client: selectedClient ? selectedClient.name : "",
        }}
      >
        <h1>Client Credit Memo</h1>
        <div className="form-header">
          <div className="flex-item full-width">
            <Form.Item
              name="client"
              rules={[{ required: true, message: "Client is required" }]}
            >
              <label>
                Client:
                <div className="inline-search-container">
                  <GenericSearch
                    config={entitySearchConfig.client}
                    onSelectItem={handleClientSelect}
                    inlineSearch={true}
                    selectedValue={selectedClient ? selectedClient.name : ""}
                    autoFocus={true}
                    openModal={openClientModal}
                  />
                </div>
              </label>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="creditMemoNumber"
              rules={[
                { required: true, message: "Credit Memo number is required" },
              ]}
            >
              <label>
                Credit Memo Number:
                <Input
                  type="text"
                  value={creditMemoNumber}
                  onChange={handleCreditMemoNumberChange}
                />
              </label>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="creditMemoAmount"
              rules={[
                { required: true, message: "Credit Memo amount is required" },
                {
                  validator: (_, value) =>
                    value && parseFloat(value) > 0
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Credit Memo amount must be non-zero")
                        ),
                },
              ]}
            >
              <label>
                Credit Memo Amount:
                <Input
                  type="number"
                  value={creditMemoAmount}
                  onChange={handleCreditMemoAmountChange}
                  onFocus={(e) => e.target.select()}
                  placeholder="0.00"
                />
              </label>
            </Form.Item>
          </div>
        </div>

        <Modal
          isOpen={isClientModalOpen}
          onRequestClose={() => setIsClientModalOpen(false)}
          contentLabel="Client Search"
          className="search-modal"
          overlayClassName="search-modal-overlay"
        >
          <div className="search-content">
            <div className="search-header">
              <h2>Client Search</h2>
              <button onClick={() => setIsClientModalOpen(false)}>
                &times;
              </button>
            </div>
            <div className="search-body">
              <GenericSearch
                config={clientSearchConfig}
                onSelectItem={handleClientSelect}
                closeModal={() => setIsClientModalOpen(false)}
                inModal={true}
              />
            </div>
          </div>
        </Modal>

        <div className="total-amounts">
          <div>
            <strong>Total Allocation: {formatCurrency(totalAllocation)}</strong>
          </div>
          <div>
            <strong>Remaining Amount: {formatCurrency(remainingAmount)}</strong>
          </div>
        </div>

        <div className="table-container table-container-client-credit-memo">
          <Table
            columns={columns}
            dataSource={openInvoices}
            rowKey="id"
            pagination={false}
            scroll={{ y: "calc(100vh - 460px)" }}
            sticky
          />
        </div>

        <div className="fixed-form-buttons">
          <Button
            type="primary"
            htmlType="submit"
            disabled={remainingAmount !== 0}
          >
            Submit Credit Memo
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ClientCreditMemo;
