// src/components/AddFunding.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';

function AddFunding() {
  const [formData, setFormData] = useState({
    clientId: '',
    amount: '',
    date: '',
    status: ''
  });
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchClients() {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/clients`);
        setClients(response.data);
      } catch (err) {
        console.error('Error fetching clients:', err);
      }
    }
    fetchClients();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`${config.API_URL}/fundings`, formData);
      navigate('/fundings');
    } catch (error) {
      console.error('Error adding funding:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <select name="clientId" onChange={handleChange} required>
        <option value="">Select Client</option>
        {clients.map(client => (
          <option key={client.id} value={client.id}>{client.name}</option>
        ))}
      </select>
      <input type="number" name="amount" onChange={handleChange} placeholder="Amount" required />
      <input type="date" name="date" onChange={handleChange} placeholder="Date" required />
      <input type="text" name="status" onChange={handleChange} placeholder="Status" required />
      <button type="submit">Add Funding</button>
    </form>
  );
}

export default AddFunding;
