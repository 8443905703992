import React from 'react';
import ClientNotes from './ClientNotes';
import { addNote, deleteNote } from '../utils/apiClient';

function ClientNotesTab({ clientId, user, formData, setFormData }) {
  const handleAddNote = async (newNote) => {
    try {
      const addedNote = await addNote(clientId, newNote, user);
      setFormData(prevFormData => ({
        ...prevFormData,
        notes: [...prevFormData.notes, addedNote] // Add the new note to the notes array
      }));
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await deleteNote(noteId);
      setFormData(prevFormData => ({
        ...prevFormData,
        notes: prevFormData.notes.filter(note => note.id !== noteId)
      }));
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  return (
    <ClientNotes
      notes={formData.notes}
      newNote={formData.newNote}
      setNewNote={(newNote) => setFormData(prevFormData => ({ ...prevFormData, newNote }))}
      handleAddNote={handleAddNote}
      handleDeleteNote={handleDeleteNote}
    />
  );
}

export default ClientNotesTab;
