// src/components/FundingProvided.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function FundingProvided() {
  const [fundings, setFundings] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchFundingProvided() {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/reports/funding-provided`);
        setFundings(response.data);
      } catch (err) {
        console.error('Error fetching funding provided:', err);
        setError('Error fetching funding provided. Please try again later.');
      }
    }
    fetchFundingProvided();
  }, []);

  return (
    <div>
      <h1>Funding Provided</h1>
      {error && <p>{error}</p>}
      <ul>
        {fundings.map(funding => (
          <li key={funding.id}>
            {funding.amount} - {funding.date}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FundingProvided;
