import React, { useState, useEffect, useRef } from 'react';
import CustomerContacts from './CustomerContacts';
import { updateCustomerContacts } from '../utils/apiCustomer';

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function CustomerContactsTab({ formData, setFormData, customerId }) {
  const [isEditingContacts, setIsEditingContacts] = useState(false);
  const [originalContacts, setOriginalContacts] = useState([]);
  const isInitialLoad = useRef(true);

  useEffect(() => {
    if (isInitialLoad.current && formData) {
      console.log('Setting original contacts:', formData);
      setOriginalContacts(deepCopy(formData));
      isInitialLoad.current = false;
    }
  }, [formData]);

  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const newContacts = formData.map((contact, i) => (
      i === index ? { ...contact, [name]: value } : contact
    ));
    setFormData(newContacts);
    console.log('Contacts after change:', newContacts);
  };

  const addContact = () => {
    const newContacts = [...formData, { name: '', phoneNumber: '', email: '' }];
    setFormData(newContacts);
    console.log('Contacts after addition:', newContacts);
  };

  const removeContact = (index) => {
    const newContacts = formData.filter((_, i) => i !== index);
    setFormData(newContacts);
    console.log('Contacts after removal:', newContacts);
  };

  const handleSaveContacts = async (e) => {
    e.preventDefault();
    try {
      const updatedContacts = await updateCustomerContacts(customerId, formData);
      setFormData(updatedContacts);
      setIsEditingContacts(false);
      console.log('Contacts after save:', updatedContacts);
    } catch (error) {
      console.error('Error saving contacts:', error);
    }
  };

  const handleCancelContacts = () => {
    console.log('Cancelling changes. Restoring original contacts:', originalContacts);
    setFormData(deepCopy(originalContacts));
    setIsEditingContacts(false);
  };

  return (
    <div>
      {isEditingContacts ? (
        <form onSubmit={handleSaveContacts}>
          <h3>Contacts</h3>
          {(formData || []).map((contact, index) => (
            <div key={index} className="contact-group">
              <div className="form-group">
                <label htmlFor={`contact-name-${index}`}>Contact Name</label>
                <input
                  type="text"
                  name="name"
                  value={contact.name || ''}
                  onChange={(e) => handleContactChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`contact-phoneNumber-${index}`}>Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={contact.phoneNumber || ''}
                  onChange={(e) => handleContactChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`contact-email-${index}`}>Email</label>
                <input
                  type="email"
                  name="email"
                  value={contact.email || ''}
                  onChange={(e) => handleContactChange(e, index)}
                />
              </div>
              <button type="button" onClick={() => removeContact(index)} className="remove-contact-button">Remove Contact</button>
            </div>
          ))}
          <button type="button" onClick={addContact} className="add-contact-button">Add Another Contact</button>
          <button type="submit" className="submit-button">Save</button>
          <button type="button" className="cancel-button" onClick={handleCancelContacts}>Cancel</button>
        </form>
      ) : (
        <>
          <CustomerContacts contacts={formData || []} />
          <div className="customer-actions">
            <button onClick={() => setIsEditingContacts(true)}>Edit</button>
          </div>
        </>
      )}
    </div>
  );
}

export default CustomerContactsTab;
