// SupplierContactsTab.js
import React, { useState, useEffect, useCallback } from 'react';
import { fetchSupplierContacts, addSupplierContact, updateSupplierContact, deleteSupplierContact } from '../utils/apiSupplier';
import SupplierContacts from './SupplierContacts';

function SupplierContactsTab({ supplierId }) {
  const [contacts, setContacts] = useState([]);
  const [contactsToDelete, setContactsToDelete] = useState([]); // Track contacts to be deleted
  const [isEditingContacts, setIsEditingContacts] = useState(false);

  const fetchContacts = useCallback(async () => {
    try {
      const contactsData = await fetchSupplierContacts(supplierId);
      setContacts(contactsData);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  }, [supplierId]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const handleContactChange = (index, field, value) => {
    const newContacts = [...contacts];
    newContacts[index][field] = value;
    setContacts(newContacts);
  };

  const addContact = () => {
    setContacts([...contacts, { name: '', phoneNumber: '', email: '', tempId: `temp-${Math.random().toString(36).substr(2, 9)}` }]);
  };

  const markContactForDeletion = (index) => {
    const contactToDelete = contacts[index];
    if (contactToDelete.id) {
      setContactsToDelete([...contactsToDelete, contactToDelete.id]);
    }
    const newContacts = contacts.filter((_, i) => i !== index);
    setContacts(newContacts);
  };

  const handleSaveContacts = async (e) => {
    e.preventDefault();
    try {
      // Delete contacts marked for deletion
      for (const contactId of contactsToDelete) {
        await deleteSupplierContact(contactId);
      }

      // Add or update contacts
      for (const contact of contacts) {
        if (!contact.id) {
          await addSupplierContact(supplierId, contact);
        } else {
          await updateSupplierContact(contact.id, contact);
        }
      }

      await fetchContacts();
      setIsEditingContacts(false);
      setContactsToDelete([]); // Clear the contactsToDelete array
    } catch (error) {
      console.error('Error saving contacts:', error);
    }
  };

  const handleCancelContacts = () => {
    setIsEditingContacts(false);
    fetchContacts(); // Revert changes by refetching contacts
    setContactsToDelete([]); // Clear the contactsToDelete array
  };

  return (
    <div>
      {isEditingContacts ? (
        <form onSubmit={handleSaveContacts}>
          <h3>Contacts</h3>
          {contacts.map((contact, index) => (
            <div key={contact.id || contact.tempId} className="contact-group">
              <div className="form-group">
                <label>Contact Name</label>
                <input
                  type="text"
                  value={contact.name}
                  onChange={(e) => handleContactChange(index, 'name', e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  value={contact.phoneNumber}
                  onChange={(e) => handleContactChange(index, 'phoneNumber', e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={contact.email}
                  onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                />
              </div>
              <button type="button" onClick={() => markContactForDeletion(index)} className="remove-contact-button">Remove Contact</button>
            </div>
          ))}
          <button type="button" onClick={addContact} className="add-contact-button">Add Another Contact</button>
          <button type="submit" className="submit-button">Save</button>
          <button type="button" className="cancel-button" onClick={handleCancelContacts}>Cancel</button>
        </form>
      ) : (
        <>
          <SupplierContacts contacts={contacts} />
          <div className="supplier-actions">
            <button onClick={() => setIsEditingContacts(true)}>Edit</button>
          </div>
        </>
      )}
    </div>
  );
}

export default SupplierContactsTab;
