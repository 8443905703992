// src/components/ClientDashboard.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './ClientDashboard.css'; // Import the CSS file for styling

function ClientDashboard() {
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchMetrics() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/clients/metrics`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setMetrics(response.data);
      } catch (error) {
        console.error('Error fetching metrics:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchMetrics();
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Client Dashboard</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="metrics">
          <div className="metric">
            <h2>Total Invoices</h2>
            <p>{metrics.totalInvoices}</p>
          </div>
          <div className="metric">
            <h2>Total Funding</h2>
            <p>${metrics.totalFunding}</p>
          </div>
          <div className="metric">
            <h2>Total Payments</h2>
            <p>${metrics.totalPayments}</p>
          </div>
          <div className="metric">
            <h2>Outstanding Amount</h2>
            <p>${metrics.outstandingAmount}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientDashboard;
