import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import config from '../config';

function ReceiveOrder() {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/orders/${orderId}`);
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrder();
  }, [orderId]);

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Order Details</h1>
      <p><strong>PO#:</strong> {order.poNumber}</p>
      <p><strong>Amount:</strong> {order.amount}</p>
      <p><strong>Date:</strong> {new Date(order.date).toLocaleDateString()}</p>
      <p><strong>Customer:</strong> {order.customer.name}</p>
      <p><strong>Status:</strong> {order.status}</p>
      {order.approval && (
        <>
          <h2>Approval Details</h2>
          <p><strong>Credit Limit:</strong> {order.approval.creditLimit}</p>
          <p><strong>Risk Rating:</strong> {order.approval.riskRating}</p>
          <p><strong>Non-Factored:</strong> {order.approval.nonFactored ? 'Yes' : 'No'}</p>
        </>
      )}
    </div>
  );
}

export default ReceiveOrder;
