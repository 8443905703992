// src/components/PaymentHistory.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './PaymentHistory.css'; // Import the CSS file for styling

function PaymentHistory() {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPayments() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/customer/payments`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPayments(response.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchPayments();
  }, []);

  return (
    <div className="payment-history-container">
      <h1>Payment History</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="payments-table">
          <thead>
            <tr>
              <th>Payment ID</th>
              <th>Invoice ID</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.id}</td>
                <td>{payment.invoiceId}</td>
                <td>${payment.amount}</td>
                <td>{payment.status}</td>
                <td>{payment.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default PaymentHistory;
