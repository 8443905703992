import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import config from "../config";
import entitySearchConfig from "../entitySearchConfig";
import { UserContext } from "../context/UserContext";
import { NotificationContext } from "../context/NotificationContext";
import GenericSearch from "./GenericSearch";
import Modal from "react-modal";
import "./Notifications.css";

Modal.setAppElement("#root");

function Notifications() {
  const [message, setMessage] = useState("");
  const [targetUserId, setTargetUserId] = useState("");
  const [targetRole, setTargetRole] = useState("");
  const [notificationType, setNotificationType] = useState("public");
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRead, setShowRead] = useState(true);
  const [showAll, setShowAll] = useState(false); // Admin toggle to show all notifications
  const { user } = useContext(UserContext);
  const { notifications, fetchNotifications } = useContext(NotificationContext);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    fetchNotifications(showAll);
  }, [user, fetchNotifications, showAll]);

  const handleMarkAsRead = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axiosInstance.put(
        `${config.API_URL}/notifications/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchNotifications(showAll);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkAsUnread = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axiosInstance.put(
        `${config.API_URL}/notifications/${id}/unread`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchNotifications(showAll);
    } catch (error) {
      console.error("Error marking notification as unread:", error);
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axiosInstance.delete(`${config.API_URL}/notifications/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchNotifications(showAll);
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const handleCreateNotification = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem("token");
      await axiosInstance.post(
        `${config.API_URL}/notifications`,
        {
          message,
          userId: notificationType === "user" ? targetUserId : null,
          role: notificationType === "role" ? targetRole : null,
          public: notificationType === "public",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage("");
      setTargetUserId("");
      setTargetRole("");
      setNotificationType("public");
      fetchNotifications(showAll);
    } catch (error) {
      console.error("Error creating notification:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const roles = ["user", "employee", "customer", "client", "admin"];

  const openUserModal = () => {
    setIsUserModalOpen(true);
  };

  const handleUserSelect = (user) => {
    setTargetUserId(user.id);
    setUserName(user.username); // Also set the username here
    setIsUserModalOpen(false); // Close modal after selection
  };

  const userSearchConfig = {
    entity: "users",
    metadataEndpoint: `${config.API_URL}/metadata/users`,
    searchEndpoint: `${config.API_URL}/users/search`,
    title: "User Search",
  };

  const filteredNotifications = showRead
    ? notifications
    : notifications.filter((notification) => !notification.read);

  return (
    <div className="notification-page">
      {user && user.role === "admin" && (
        <div className="notification-form">
          <form className="notification-form" onSubmit={handleCreateNotification}>
            <div>
              <label>Message:</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="notificationType"
                  value="public"
                  checked={notificationType === "public"}
                  onChange={(e) => setNotificationType(e.target.value)}
                />
                Public
              </label>
              <label>
                <input
                  type="radio"
                  name="notificationType"
                  value="user"
                  checked={notificationType === "user"}
                  onChange={(e) => setNotificationType(e.target.value)}
                />
                User
              </label>
              <label>
                <input
                  type="radio"
                  name="notificationType"
                  value="role"
                  checked={notificationType === "role"}
                  onChange={(e) => setNotificationType(e.target.value)}
                />
                Role
              </label>
            </div>
            {notificationType === "user" && (
              <div>
                <label>User:</label>
                <GenericSearch
                  config={entitySearchConfig.user}
                  onSelectItem={handleUserSelect}
                  inlineSearch={true}
                  selectedValue={userName}
                  autoFocus={true}
                  openModal={openUserModal} // Pass open modal function
                />
              </div>
            )}
            {notificationType === "role" && (
              <div>
                <label>Target Role:</label>
                <select
                  value={targetRole}
                  onChange={(e) => setTargetRole(e.target.value)}
                >
                  <option value="">Select a role</option>
                  {roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <button type="submit" disabled={isSubmitting}>
              Create Notification
            </button>
          </form>
          {isUserModalOpen && (
            <Modal
              isOpen={isUserModalOpen}
              onRequestClose={() => setIsUserModalOpen(false)}
              contentLabel="User Search"
              className="search-modal"
              overlayClassName="search-modal-overlay"
            >
              <div className="search-content">
                <div className="search-header">
                  <h2>User Search</h2>
                  <button
                    className="close-button"
                    onClick={() => setIsUserModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <div className="search-body">
                  <GenericSearch
                    config={userSearchConfig}
                    onSelectItem={handleUserSelect}
                    closeModal={() => setIsUserModalOpen(false)}
                    inModal={true}
                  />
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
      <div className="notification-table">
        <div className="toggle-container">
          <label>
            <input
              type="checkbox"
              checked={showRead}
              onChange={(e) => setShowRead(e.target.checked)}
            />
            Show Read Notifications
          </label>
          {user && user.role === "admin" && (
            <label>
              <input
                type="checkbox"
                checked={showAll}
                onChange={(e) => setShowAll(e.target.checked)}
              />
              Show All Notifications
            </label>
          )}
        <table>
          <thead>
            <tr>
              <th>Message</th>
              <th>Group</th>
              <th>Created By</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredNotifications.map(
              (notification) =>
                notification && (
                  <tr
                    key={notification.id}
                    style={{
                      textDecoration: notification.read
                        ? "line-through"
                        : "none",
                    }}
                  >
                    <td>{notification.message}</td>
                    <td>
                      {notification.public
                        ? "Public"
                        : notification.role
                        ? "Role"
                        : "User"}
                    </td>
                    <td>{notification.createdBy}</td>
                    <td>
                      {new Date(notification.createdAt).toLocaleString()}
                    </td>
                    <td>
                      {notification.read ? (
                        <button
                          onClick={() => handleMarkAsUnread(notification.id)}
                        >
                          Mark as Unread
                        </button>
                      ) : (
                        <button
                          onClick={() => handleMarkAsRead(notification.id)}
                        >
                          Mark as Read
                        </button>
                      )}
                      {user && user.role === "admin" && (
                        <button
                          onClick={() =>
                            handleDeleteNotification(notification.id)
                          }
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
