import React from 'react';
import ClientCustomers from './ClientCustomers';

function ClientCustomersTab({ formData }) {
  return (
    <div className="associated-customers">
      <h2>Associated Customers</h2>
      <ClientCustomers customers={formData.customers} />
    </div>
  );
}

export default ClientCustomersTab;
