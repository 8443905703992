// columns.js
import { Input } from 'antd';

const getUniqueValues = (array, key) => {
  const uniqueValues = new Set(array.map((item) => key(item)));
  return Array.from(uniqueValues).map((value) => ({ text: value, value }));
};

export const getColumns = (openInvoices, allocations, onChangeInput, formatCurrency) => [
  {
    title: "Invoice Number",
    dataIndex: "invoiceNumber",
    key: "invoiceNumber",
    sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber),
    sortDirections: ["ascend", "descend"],
    filters: getUniqueValues(
      openInvoices,
      (invoice) => invoice.invoiceNumber
    ),
    onFilter: (value, record) => record.invoiceNumber.includes(value),
  },
  {
    title: "Customer Name",
    dataIndex: ["customer", "name"],
    key: "customerName",
    sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    sortDirections: ["ascend", "descend"],
    filters: getUniqueValues(openInvoices, (invoice) => invoice.customer.name),
    onFilter: (value, record) => record.customer.name.includes(value),
  },
  {
    title: "Schedule Number",
    dataIndex: ["schedule", "scheduleNumber"],
    key: "scheduleNumber",
    sorter: (a, b) =>
      (a.schedule?.scheduleNumber || "").localeCompare(b.schedule?.scheduleNumber || ""),
    sortDirections: ["ascend", "descend"],
    filters: getUniqueValues(
      openInvoices,
      (invoice) => invoice.schedule?.scheduleNumber || "N/A"
    ),
    onFilter: (value, record) =>
      (record.schedule?.scheduleNumber || "").includes(value),
  },
  {
    title: "Invoice Date",
    dataIndex: "invoiceDate",
    key: "invoiceDate",
    sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
    sortDirections: ["ascend", "descend"],
    render: (date) => new Date(date).toLocaleDateString(),
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    key: "dueDate",
    sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
    sortDirections: ["ascend", "descend"],
    render: (date) => new Date(date).toLocaleDateString(),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sorter: (a, b) => a.amount - b.amount,
    sortDirections: ["ascend", "descend"],
    render: (amount) => formatCurrency(amount),
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    sorter: (a, b) => a.balance - b.balance,
    sortDirections: ["ascend", "descend"],
    render: (balance) => formatCurrency(balance),
  },
  {
    title: "Allocate",
    key: "allocate",
    sorter: (a, b) => (parseFloat(allocations[a.id]) || 0) - (parseFloat(allocations[b.id]) || 0),
    sortDirections: ["ascend", "descend"],
    render: (text, record) => (
      <Input
        type="number"
        value={allocations[record.id] || ""}
        onChange={(e) => onChangeInput(e, record.id)}
        placeholder="Amount"
        step="0.01" // Optional: Helps browsers handle decimal inputs better
      />
    ),
  },
];
