// SupplierDetailsTab.js
import React, { useState } from 'react';

function SupplierDetailsTab({ formData, setFormData, supplierId, updateSupplier, supplier }) {
  const [isEditingDetails, setIsEditingDetails] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const updatedSupplier = await updateSupplier(supplierId, formData);
      setFormData(updatedSupplier);
      setIsEditingDetails(false);
    } catch (error) {
      console.error('Error saving supplier details:', error);
    }
  };

  const handleCancel = () => {
    setFormData(supplier);
    setIsEditingDetails(false);
  };

  return (
    <div>
      {isEditingDetails ? (
        <form onSubmit={handleSave}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              name="phone"
              value={formData.phone || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email || ''}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="submit-button">Save</button>
          <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button>
        </form>
      ) : (
        <div className="supplier-info">
          <p><strong>Name:</strong> {formData.name}</p>
          <p><strong>Phone:</strong> {formData.phone}</p>
          <p><strong>Email:</strong> {formData.email}</p>
          <div className="supplier-actions">
            <button onClick={() => setIsEditingDetails(true)}>Edit</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SupplierDetailsTab;
