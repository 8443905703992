// components/GenericMetadata.js
import React from 'react';
import Modal from 'react-modal';
import SimpleJsonViewer from './SimpleJsonViewer'; // Ensure this component exists
import './GenericMetadata.css';

Modal.setAppElement('#root'); // Add this line to ensure accessibility

function GenericMetadata({ metadata, showMetadata, handleClose }) {
  return (
    <Modal
      isOpen={showMetadata}
      onRequestClose={handleClose}
      contentLabel="Metadata"
      className="metadata-modal"
      overlayClassName="metadata-overlay"
    >
      <div className="metadata-content">
        <div className="metadata-header">
          <h2>Metadata</h2>
          <button onClick={handleClose}>&times;</button>
        </div>
        <div className="metadata-body">
          <SimpleJsonViewer data={metadata} />
        </div>
      </div>
    </Modal>
  );
}

export default GenericMetadata;
