import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useTable, useSortBy, usePagination } from 'react-table';
import { formatDate } from './utils/formatDate';
import './ClientPayments.css';

function ClientPayments() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    type: '',
    startDate: '',
    endDate: '',
    invoiceNumber: '',
    source: '',
    comment: '',
  });
  const [sortBy, setSortBy] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`${config.API_URL}/clients/transactions`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          page: page + 1,
          limit: pageSize,
          sortBy: sortBy.length ? sortBy[0].id : 'date',
          order: sortBy.length && sortBy[0].desc ? 'DESC' : 'ASC',
          ...filters,
        }
      });
      setTransactions(response.data.transactions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, filters, sortBy]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setPage(0);  // Reset to first page when filters change
  };

  const handleFilterReset = () => {
    setFilters({
      type: '',
      startDate: '',
      endDate: '',
      invoiceNumber: '',
      source: '',
      comment: '',
    });
    setPage(0);
  };

  const columns = useMemo(
    () => [
      { Header: 'Date', accessor: 'date', Cell: ({ value }) => formatDate(value) },
      { Header: 'Invoice Number', accessor: 'Invoice.invoiceNumber' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Type', accessor: 'type' },
      { Header: 'Source', accessor: 'source' },
      { Header: 'Comment', accessor: 'comment' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: tablePage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
    state: { pageIndex, sortBy: tableSortBy }
  } = useTable(
    {
      columns,
      data: transactions,
      manualSortBy: true,
      manualPagination: true,
      pageCount: totalPages,
      initialState: { pageIndex: page, pageSize, sortBy }
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (pageIndex !== page) {
      setPage(pageIndex);
    }
  }, [pageIndex, page]);

  useEffect(() => {
    if (tableSortBy !== sortBy) {
      setSortBy(tableSortBy);
    }
  }, [tableSortBy, sortBy]);

  useEffect(() => {
    setTablePageSize(pageSize);
  }, [pageSize, setTablePageSize]);

  const appliedFilters = useMemo(() => {
    const filterTexts = [];
    if (filters.type) filterTexts.push(`Type: ${filters.type}`);
    if (filters.startDate) filterTexts.push(`Start Date: ${filters.startDate}`);
    if (filters.endDate) filterTexts.push(`End Date: ${filters.endDate}`);
    if (filters.invoiceNumber) filterTexts.push(`Invoice Number: ${filters.invoiceNumber}`);
    if (filters.source) filterTexts.push(`Source: ${filters.source}`);
    if (filters.comment) filterTexts.push(`Comment: ${filters.comment}`);
    return filterTexts.join(', ');
  }, [filters]);

  return (
    <div className="payments-container">
      <h1>Client Payments</h1>
      <div className="filters-actions">
        <button onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </button>
        {appliedFilters && (
          <div className="applied-filters">
            <strong>Applied Filters:</strong> {appliedFilters}
          </div>
        )}
      </div>
      {showFilters && (
        <div className="filters-container">
          <label>
            Invoice Number:
            <input
              type="text"
              name="invoiceNumber"
              value={filters.invoiceNumber}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            Type:
            <input
              type="text"
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            Start Date:
            <input
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            Source:
            <input
              type="text"
              name="source"
              value={filters.source}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            Comment:
            <input
              type="text"
              name="comment"
              value={filters.comment}
              onChange={handleFilterChange}
            />
          </label>
          <button onClick={handleFilterReset}>Reset Filters</button>
        </div>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table {...getTableProps()} className="payments-table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {tablePage.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: '100px' }}
              />
            </span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
                setPage(0); // Reset to first page when page size changes
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
}

export default ClientPayments;
