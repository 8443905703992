// src/components/ClientReports.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './ClientReports.css'; // Import the CSS file for styling

function ClientReports() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchReports() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/client/reports`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchReports();
  }, []);

  return (
    <div className="reports-container">
      <h1>Client Reports</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul className="reports-list">
          {reports.map((report) => (
            <li key={report.id}>
              <h2>{report.title}</h2>
              <p>{report.description}</p>
              <a href={report.url} target="_blank" rel="noopener noreferrer">
                View Report
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ClientReports;
