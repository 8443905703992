// src/components/ClientInvoices.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import GenericSearch from './GenericSearch';
import { useTable, useSortBy, usePagination } from 'react-table';
import './ClientInvoices.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

function ClientInvoices() {
  const [invoices, setInvoices] = useState([]);
  const [newInvoice, setNewInvoice] = useState({ 
    amount: '', 
    dueDate: '', 
    invoiceDate: '', 
    terms: 'Net 30', 
    customer: '', 
    invoiceNumber: ''
  });
  const [loading, setLoading] = useState(true);
  const [showCustomerSearch, setShowCustomerSearch] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchInvoices() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/clients/invoices`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchInvoices();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(`${config.API_URL}/clients/invoices`, newInvoice, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setInvoices([...invoices, newInvoice]);
      setNewInvoice({ amount: '', dueDate: '', invoiceDate: '', terms: 'Net 30', customer: '', invoiceNumber: '' });
    } catch (error) {
      console.error('Error submitting invoice:', error);
    }
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setNewInvoice({ ...newInvoice, customer: customer.name });
    setShowCustomerSearch(false);
  };

  const customerSearchConfig = {
    entity: 'customers',
    metadataEndpoint: `${config.API_URL}/metadata/customers`,
    searchEndpoint: `${config.API_URL}/customers/search`,
    title: 'Customers'
  };

  const handleApplyCreditMemo = useCallback((invoiceId) => {
    navigate(`/apply-credit-memo/${invoiceId}`);
  }, [navigate]);

  const columns = useMemo(
    () => [
      { Header: 'Invoice Number', accessor: 'invoiceNumber' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Due Date', accessor: 'dueDate', Cell: ({ value }) => value.split('T')[0] },
      { Header: 'Invoice Date', accessor: 'invoiceDate', Cell: ({ value }) => value.split('T')[0] },
      { Header: 'Terms', accessor: 'paymentTerms' },
      { Header: 'Customer', accessor: 'Customer.name' },
      { Header: 'Balance', accessor: 'balance' },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <button onClick={() => handleApplyCreditMemo(row.original.id)}>Apply Credit Memo</button>
        ),
      }
    ],
    [handleApplyCreditMemo]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: invoices,
      initialState: { pageIndex: 0 }
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="invoices-container">
      <h1>Client Invoices</h1>
      <form onSubmit={handleSubmit} className="invoice-form">
        <div className="form-group">
          <label>Invoice Number:</label>
          <input
            type="text"
            value={newInvoice.invoiceNumber}
            onChange={(e) => setNewInvoice({ ...newInvoice, invoiceNumber: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>Amount:</label>
          <input
            type="number"
            value={newInvoice.amount}
            onChange={(e) => setNewInvoice({ ...newInvoice, amount: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>Due Date:</label>
          <input
            type="date"
            value={newInvoice.dueDate}
            onChange={(e) => setNewInvoice({ ...newInvoice, dueDate: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>Invoice Date:</label>
          <input
            type="date"
            value={newInvoice.invoiceDate}
            onChange={(e) => setNewInvoice({ ...newInvoice, invoiceDate: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label>Terms:</label>
          <select
            value={newInvoice.terms}
            onChange={(e) => setNewInvoice({ ...newInvoice, terms: e.target.value })}
            required
          >
            <option value="Net 30">Net 30</option>
            <option value="Net 60">Net 60</option>
            <option value="Net 90">Net 90</option>
          </select>
        </div>
        <div className="form-group">
          <label>Customer:</label>
          <input
            type="text"
            value={selectedCustomer ? selectedCustomer.name : ''}
            onClick={() => setShowCustomerSearch(true)}
            readOnly
            required
          />
        </div>
        <button type="submit" className="submit-button">Submit Invoice</button>
      </form>
      {showCustomerSearch && (
        <GenericSearch
          config={customerSearchConfig}
          onSelectItem={handleCustomerSelect}
          closeModal={() => setShowCustomerSearch(false)}
          inModal={true}
        />
      )}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table {...getTableProps()} className="invoices-table">
            <thead>
              {headerGroups.map(headerGroup => {
                const { key, ...rest } = headerGroup.getHeaderGroupProps();
                return (
                  <tr key={key} {...rest}>
                    {headerGroup.headers.map(column => {
                      const { key, ...rest } = column.getHeaderProps(column.getSortByToggleProps());
                      return (
                        <th key={key} {...rest}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                const { key, ...rest } = row.getRowProps();
                return (
                  <tr key={key} {...rest}>
                    {row.cells.map(cell => {
                      const { key, ...rest } = cell.getCellProps();
                      return (
                        <td key={key} {...rest}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />
            </span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
}

export default ClientInvoices;
