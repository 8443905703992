import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Input } from "antd";
import axiosInstance from "../axiosConfig";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import { ClipLoader } from 'react-spinners'; // Import the spinner
import "./GenericSearch.css";

function GenericSearch({
  config,
  onSelectItem,
  closeModal,
  inModal,
  initialFormData = {},
  inlineSearch = false,
  selectedValue,
  autoFocus = false,
  openModal,
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page"), 10) || 1;

  const [formData, setFormData] = useState({ ...initialFormData });
  const [results, setResults] = useState([]);
  const [inlineResults, setInlineResults] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);
  const [pageInput, setPageInput] = useState("");
  const [metadata, setMetadata] = useState([]);
  const [inlineSearchValue, setInlineSearchValue] = useState(
    selectedValue || ""
  );
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [searching, setSearching] = useState(false);
  const [resultClicked, setResultClicked] = useState(false);
  // const [isTabularView, setIsTabularView] = useState(true);

  const [focusedFieldName, setFocusedFieldName] = useState(null); // Track the name of the last focused field
  const inputRefs = useRef({}); // Store refs for all input fields

    // Save the field name when focusing on an input
  const handleFocus = (name) => {
    setFocusedFieldName(name);
  };

  // Restore focus after the search is completed
  useEffect(() => {
    if (!searching && focusedFieldName && inputRefs.current[focusedFieldName]) {
      inputRefs.current[focusedFieldName].focus();
    }
  }, [searching, focusedFieldName]);

  const inlineResultsRef = useRef(null);
  const searchInputRef = useRef(null);

  const { entity, metadataEndpoint, searchEndpoint, title } = config;

  const memoizedSearchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await axiosInstance.get(metadataEndpoint);
        console.log("Metadata fetched:", response.data); // Log the fetched metadata
        setMetadata(response.data);

        if (response.data && response.data.searchFields) {
          const initialFormData = response.data.searchFields.reduce(
            (acc, field) => {
              acc[field.name] = memoizedSearchParams.get(field.name) || "";
              return acc;
            },
            {}
          );
          setFormData((prevFormData) => ({
            ...initialFormData,
            ...prevFormData,
          }));
        } else {
          console.error("Metadata searchFields are missing or empty.");
        }
      } catch (err) {
        console.error(`Error fetching metadata for ${entity}:`, err);
      }
    };
    fetchMetadata();
  }, [metadataEndpoint, entity, memoizedSearchParams]);

  const handleSearch = useMemo(() => debounce(async (page, data) => {
      setSearching(true);
      //console.log("Searching with data: ", data, " on page: ", page);
      try {
        const response = await axiosInstance.get(searchEndpoint, {
          params: {
            ...data,
            page,
            limit: 5,
          },
        });
        const items = response.data.items || response.data[entity];
        setResults(items || []);
        setTotalPages(response.data.totalPages || 1);
        setCurrentPage(response.data.currentPage || 1);
        setError("");
      } catch (err) {
        console.error(`Error searching ${entity}:`, err);
        setError(`Error searching ${entity}. Please try again later.`);
      } finally {
        setSearching(false);
      }
    }, 500),
    [searchEndpoint, entity]);

  const debouncedSearch = useCallback((page, data) => {
    handleSearch(page, data);
  }, [handleSearch]);

  const handleChange = (e) => {
    const updatedFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(updatedFormData);
    setCurrentPage(1);
    debouncedSearch(1, updatedFormData);
  };

  const debouncedInlineSearchFunction = useMemo(() => debounce(async (value) => {
    if (!value.trim()) {
        setInlineResults([]);
        setSearching(false);
        return;
    }

    setSearching(true);
    try {
        const searchField = metadata.searchFields[0].name || "name"; 
        console.log("Searching with field:", searchField, "and value:", value); 

        const response = await axiosInstance.get(searchEndpoint, {
            params: {
                [searchField]: value,
                limit: 5,
            },
        });
        const items = response.data.items || response.data[entity];
        setInlineResults(items || []);
        setHighlightedIndex(0);
        setResultClicked(false);
    } catch (err) {
        console.error(`Error performing inline search for ${entity}:`, err);
        setInlineResults([]);
    }
    setSearching(false);
}, 500), [searchEndpoint, entity, metadata]);
  
  const debouncedInlineSearch = useCallback(
    (value) => {
      debouncedInlineSearchFunction(value);
    },
    [debouncedInlineSearchFunction]
  );

  const handleInlineChange = (e) => {
    const value = e.target.value;
    setInlineSearchValue(value);
    debouncedInlineSearch(value);

    if (!value.trim() && onSelectItem) {
      onSelectItem({ id: "", name: "" });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, inlineResults.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex >= 0 && highlightedIndex < inlineResults.length) {
        handleResultClick(inlineResults[highlightedIndex]);
      }
    } else if (e.key === "Escape") {
      setInlineResults([]);
    }
  };

  useEffect(() => {
    if (
      inlineResultsRef.current &&
      highlightedIndex >= 0 &&
      highlightedIndex < inlineResults.length
    ) {
      const highlightedItem =
        inlineResultsRef.current.children[highlightedIndex];
      if (highlightedItem) {
        highlightedItem.scrollIntoView({ block: "nearest" });
      }
    }
  }, [highlightedIndex, inlineResults]);

  const handleResultClick = (item) => {
    if (onSelectItem) {
      onSelectItem(item);
      setInlineSearchValue(item.name);
      setInlineResults([]);
      setSearching(false);
      setResultClicked(true);
      if (inModal) {
        setResults([]);
        handleModalClose();
      }
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      handleSearch(newPage, formData);
    }
  };

  const handlePageInputChange = (e) => {
    setPageInput(e.target.value);
  };

  const handlePageInputSubmit = (e) => {
    e.preventDefault();
    const page = parseInt(pageInput, 10);
    if (page > 0 && page <= totalPages) {
      handlePageChange(page);
    }
  };

  useEffect(() => {
    if (!inlineSearch) {
      // Trigger the search only when not using inline search
      handleSearch(1, formData);
    }
  }, [formData, handleSearch, inlineSearch]);
  

  useEffect(() => {
    if (selectedValue) {
      setInlineSearchValue(selectedValue);
      setResultClicked(true);
      setInlineResults([]);
    }
  }, [selectedValue]);

  const handleModalClose = () => {
    closeModal && closeModal();
  };

  if (
    !metadata ||
    !metadata.searchFields ||
    metadata.searchFields.length === 0
  ) {
    return <div>Loading...</div>;
  }

  // const renderCardView = () => (
  //   <ul className="generic-results">
  //     {results.length > 0 ? (
  //       results.map((item) => (
  //         <li
  //           key={item.id}
  //           onClick={() => handleResultClick(item)}
  //           className="generic-result-item"
  //         >
  //           <div className="generic-info">
  //             {(metadata.displayFields || []).map((field) => (
  //               <p key={field.name}>
  //                 <strong>{field.label}:</strong> {item[field.name] || "N/A"}
  //               </p>
  //             ))}
  //           </div>
  //         </li>
  //       ))
  //     ) : (
  //       <p>No results found</p>
  //     )}
  //   </ul>
  // );

  const renderTabularView = () => (
    <table className="generic-table">
      <thead>
        <tr>
          {(metadata.displayFields || []).map((field) => (
            <th key={field.name}>{field.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {searching ? (
          <tr>
            <td colSpan={metadata.displayFields.length} className="spinner-container">
              <ClipLoader color="#007bff" size={40} />
            </td>
          </tr>
        ) : results.length > 0 ? (
          results.map((item) => (
            <tr key={item.id} onClick={() => handleResultClick(item)} className="generic-table-row">
              {(metadata.displayFields || []).map((field) => (
                <td key={field.name}>
                  {field.type === "array" && Array.isArray(item[field.name])
                    ? item[field.name]
                        .map((subItem) => (
                          field.subFields
                            .map(subField => `${subItem[subField.name] || "N/A"}`)
                            .join(', ')
                        ))
                        .join('\n') // Join rows with new line
                    : item[field.name] || "N/A"}
                </td>
              ))}
            </tr>

          ))
        ) : (
          <tr>
            <td colSpan={metadata.displayFields.length}>No results found</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  if (inlineSearch) {
    return (
      <div className="inline-search">
        <div className="inline-search-input-container">
          <Input
            type="text"
            placeholder={`Search by ${
              metadata?.searchFields[0]?.label || "name"
            }`}
            value={inlineSearchValue}
            onChange={handleInlineChange}
            onKeyDown={handleKeyDown}
            ref={searchInputRef}
            autoFocus={autoFocus}
          />
          <button
            type="button"
            className="inline-search-button"
            onClick={() => {
              openModal();
            }}
            tabIndex={-1}
          >
            <i className="fas fa-search"></i>
          </button>
        </div>
        <ul
          className={`inline-results ${
            inlineResults.length > 0 || (inlineSearchValue && !resultClicked)
              ? "visible"
              : ""
          }`}
          ref={inlineResultsRef}
        >
          {searching ? (
            <li className="searching">Searching...</li>
          ) : inlineResults.length > 0 ? (
            inlineResults.map((item, index) => (
              <li
                key={item.id}
                onClick={() => handleResultClick(item)}
                className={`inline-result-item ${
                  index === highlightedIndex ? "highlighted" : ""
                }`}
              >
                {item[metadata?.searchFields?.[0]?.name || "N/A"]}
              </li>
            ))
          ) : (
            inlineSearchValue &&
            !searching && <li className="no-results">No results found</li>
          )}
        </ul>
      </div>
    );
  }

  return (
    <div className={inModal ? "search-modal" : "generic-search"}>
      <div className={inModal ? "search-modal-content" : "flexDiv"}>
        {inModal && (
          <div className="modal-header">
            <h1>{title || `${entity} Search`}</h1>
            <button className="close-button" onClick={handleModalClose}>
              ×
            </button>
          </div>
        )}
        {!inModal && (
          <div className="page-header">
            <h1>{title || `${entity} Search`}</h1>
          </div>
        )}
        
        {/* Main search content */}
        <div className="generic-search">
          <div className="search-fields">
            {metadata.searchFields.map((field) => (
              <div key={field.name}>
                <label>
                  {field.label}:
                  <input
                    type={field.type || "text"}
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleChange}
                    onFocus={() => handleFocus(field.name)} 
                    ref={(el) => (inputRefs.current[field.name] = el)} 
                    autoComplete="new-password"
                    disabled={searching} // Disable inputs during search
                  />
                </label>
              </div>
            ))}
          </div>
          {error && <p>{error}</p>}
          
          {/* Table and pagination content */}
          <div className={`table-container ${searching ? 'disabled' : ''}`}>
            {renderTabularView()}
          </div>
          
          <div className={`pagination ${searching ? 'disabled' : ''}`}>

          <div className="pagination-controls">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || searching}>
              Previous
            </button>
            
            {currentPage > 2 && (
              <button onClick={() => handlePageChange(1)} disabled={searching}>1</button>
            )}
            {currentPage > 3 && <span>...</span>}
            
            {currentPage > 1 && (
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={searching}>
                {currentPage - 1}
              </button>
            )}
            
            <button className="current-page" disabled>
              {currentPage}
            </button>
            
            {currentPage < totalPages && (
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={searching}>
                {currentPage + 1}
              </button>
            )}
            
            {currentPage < totalPages - 2 && <span>...</span>}
            
            {currentPage < totalPages - 1 && (
              <button onClick={() => handlePageChange(totalPages)} disabled={searching}>
                {totalPages}
              </button>
            )}
            
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || searching}>
              Next
            </button>
          </div>

          <form className="pagination-form" onSubmit={handlePageInputSubmit}>
            <input
              type="number"
              value={pageInput}
              onChange={handlePageInputChange}
              placeholder="Go to page"
              min="1"
              max={totalPages}
              disabled={searching} // Disable input during search
            />
            <button type="submit" disabled={searching}>Go</button>
          </form>
        </div>

        </div>
      </div>
    </div>

  );
  
}

export default GenericSearch;
