import React, { useEffect, useState, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import axiosInstance from '../axiosConfig';
import { useUserContext } from '../context/UserContext'; // Import the custom hook to access UserContext
import './TokenExpiryIndicator.css';

const FULL_DASH_ARRAY = 283;

function TokenExpiryIndicator() {
    const [timeLeft, setTimeLeft] = useState(null);
    const [tokenExpirationTime, setTokenExpirationTime] = useState(null);
    const [pathColor, setPathColor] = useState("green");
    const { logoutUser } = useUserContext(); // Get the logoutUser function from UserContext

    const updateTimeLeft = useCallback((exp) => {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeRemaining = exp - currentTime;
        setTimeLeft(timeRemaining * 1000);

        const timeFraction = timeRemaining / (tokenExpirationTime / 1000);

        if (timeRemaining <= 0) {
            logoutUser(); // Log out the user when the timer reaches zero
            return;
        }

        if (timeFraction <= 0.25) {
            setPathColor("red");
        } else if (timeFraction <= 0.5) {
            setPathColor("orange");
        } else {
            setPathColor("green");
        }
    }, [tokenExpirationTime, logoutUser]);

    useEffect(() => {
        const fetchTokenExpirationTime = async () => {
            try {
                const response = await axiosInstance.get('/auth/token-expiration-time');
                const expirationTimeString = response.data.tokenExpirationTime;

                const expirationTimeInMilliseconds = parseExpirationTime(expirationTimeString);
                
                if (!isNaN(expirationTimeInMilliseconds)) {
                    setTokenExpirationTime(expirationTimeInMilliseconds);
                    console.log('Token expiration time set to:', expirationTimeInMilliseconds);
                } else {
                    console.error('Failed to parse token expiration time:', expirationTimeString);
                }
            } catch (error) {
                console.error('Failed to fetch token expiration time:', error);
            }
        };

        fetchTokenExpirationTime();
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && tokenExpirationTime) {
            const decodedToken = jwtDecode(token);
            updateTimeLeft(decodedToken.exp);

            const timerInterval = setInterval(() => {
                const currentToken = localStorage.getItem('token');
                const currentDecodedToken = jwtDecode(currentToken);
                updateTimeLeft(currentDecodedToken.exp);
            }, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [tokenExpirationTime, updateTimeLeft]);

    const parseExpirationTime = (timeString) => {
        const timeValue = parseInt(timeString.slice(0, -1), 10);
        const timeUnit = timeString.slice(-1);

        switch (timeUnit) {
            case 's':
                return timeValue * 1000; // Convert seconds to milliseconds
            case 'm':
                return timeValue * 60 * 1000; // Convert minutes to milliseconds
            case 'h':
                return timeValue * 60 * 60 * 1000; // Convert hours to milliseconds
            case 'd':
                return timeValue * 24 * 60 * 60 * 1000; // Convert days to milliseconds
            default:
                return NaN;
        }
    };

    const handleRefreshToken = async () => {
        try {
            const response = await axiosInstance.post('/auth/refresh-token', null, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            const newToken = response.data.token;
            localStorage.setItem('token', newToken);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

            const decodedToken = jwtDecode(newToken);
            updateTimeLeft(decodedToken.exp); // Update timeLeft after refreshing the token

            alert('Session refreshed successfully!');
        } catch (error) {
            console.error('Failed to refresh token:', error);
            alert('Failed to refresh session. Please log in again.');
        }
    };

    if (timeLeft === null || tokenExpirationTime === null) {
        return <div>Loading...</div>;
    }

    const timeFraction = timeLeft / tokenExpirationTime;
    const circleDasharray = `${(timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;

    const minutes = Math.floor(timeLeft / 60000);
    const seconds = ((timeLeft % 60000) / 1000).toFixed(0).padStart(2, '0');

    return (
        <div className="token-expiry-indicator" onClick={handleRefreshToken} style={{ cursor: 'pointer' }}>
            <div className="base-timer">
                <svg className="base-timer__svg" viewBox="0 0 100 100">
                    <g className="base-timer__circle">
                        <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                        <path
                            id="base-timer-path-remaining"
                            strokeDasharray={circleDasharray}
                            className={`base-timer__path-remaining ${pathColor}`}
                            d="
                                M 50, 50
                                m -45, 0
                                a 45,45 0 1,0 90,0
                                a 45,45 0 1,0 -90,0
                            "
                        ></path>
                    </g>
                </svg>
                <span className="base-timer__label">
                    {minutes}:{seconds}
                </span>
            </div>
        </div>
    );
}

export default TokenExpiryIndicator;
