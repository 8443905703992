// src/components/Support.js
import React from 'react';
import './Support.css'; // Import the CSS file for styling

function Support() {
  return (
    <div className="support-container">
      <h1>Support</h1>
      <p>If you need any assistance, our support team is here to help. You can reach out to us through the following channels:</p>
      
      <div className="support-section">
        <h2>Email Support</h2>
        <p>Email us at <a href="mailto:support@factoringapp.com">support@factoringapp.com</a> and we will get back to you as soon as possible.</p>
      </div>

      <div className="support-section">
        <h2>Phone Support</h2>
        <p>Call us at 1-800-123-4567 from Monday to Friday, 9 AM to 5 PM (EST).</p>
      </div>

      <div className="support-section">
        <h2>Live Chat</h2>
        <p>Use the live chat feature on our website for instant support during business hours.</p>
      </div>

      <div className="support-section">
        <h2>Help Center</h2>
        <p>Visit our <a href="/help-center">Help Center</a> for articles, tutorials, and guides on using the Factoring Application.</p>
      </div>
    </div>
  );
}

export default Support;
