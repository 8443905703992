// src/components/ClientDocuments.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function ClientDocuments() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [documentType, setDocumentType] = useState('');

  useEffect(() => {
    async function fetchDocuments() {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/clients/documents`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchDocuments();
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('document', file);
    formData.append('documentName', documentName);
    formData.append('documentType', documentType);

    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(`${config.API_URL}/clients/documents`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      // Refresh the document list
      const response = await axiosInstance.get(`${config.API_URL}/clients/documents`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDocuments(response.data);
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  const handleDownload = async (documentId, documentName) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`${config.API_URL}/clients/documents/${documentId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
      });

      // Create a URL for the file blob and trigger a download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName); // Specify the filename
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setDocumentName(selectedFile.name);
      setDocumentType(selectedFile.type);
    }
  };

  return (
    <div className="documents-container">
      <h1>Client Documents</h1>
      <form onSubmit={handleUpload} className="upload-form">
        <div className="form-group">
          <label>Document Name:</label>
          <input
            type="text"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Document Type:</label>
          <input
            type="text"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Document File:</label>
          <input
            type="file"
            onChange={handleFileChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">Upload Document</button>
      </form>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="documents-table">
          <thead>
            <tr>
              <th>Document ID</th>
              <th>Document Name</th>
              <th>Document Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map(document => (
              <tr key={document.id}>
                <td>{document.id}</td>
                <td>{document.documentName}</td>
                <td>{document.documentType}</td>
                <td>
                  <button onClick={() => handleDownload(document.id, document.documentName)}>
                    Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ClientDocuments;
