import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function AccountsReceivableList() {
  const [accountsReceivable, setAccountsReceivable] = useState([]);

  useEffect(() => {
    const fetchAccountsReceivable = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/accounts-receivable`);
        setAccountsReceivable(response.data);
      } catch (error) {
        console.error('Error fetching accounts receivable:', error);
      }
    };

    fetchAccountsReceivable();
  }, []);

  return (
    <div>
      <h1>Accounts Receivable</h1>
      <ul>
        {accountsReceivable.map(entry => (
          <li key={entry.id}>
            Customer ID: {entry.customerId} - Invoice ID: {entry.invoiceId} - Amount: {entry.amount} - Due Date: {entry.dueDate}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AccountsReceivableList;
