import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './CollectionReportsDashboard.css';

function CollectionReportsDashboard() {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/clients`);
      setClients(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setLoading(false);
    }
  };

  const fetchReports = async (clientId) => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/reports/collection-reports/client/${clientId}`);
      setReports(response.data);
      setSelectedClient(clientId);
    } catch (error) {
      console.error('Error fetching collection reports:', error);
    }
  };

  const generateReport = async (year, month) => {
    try {
      await axiosInstance.post(`${config.API_URL}/reports/generate-collection-report/all`, { year, month });
      fetchReports(selectedClient); // Refresh reports after generating a new one
    } catch (error) {
      console.error('Error generating collection report:', error);
    }
  };

  const viewReport = (reportId) => {
    // Implement the logic to navigate to the report details view
  };

  return (
    <div className="dashboard">
      <h1>Collection Reports Dashboard</h1>
      {loading ? (
        <p>Loading clients...</p>
      ) : (
        <div>
          <select onChange={(e) => fetchReports(e.target.value)} value={selectedClient || ''}>
            <option value="" disabled>Select a client</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>{client.name}</option>
            ))}
          </select>

          {selectedClient && (
            <div className="reports-grid">
              {Array.from({ length: 12 }, (_, index) => {
                const month = index + 1;
                const report = reports.find(r => new Date(r.startDate).getMonth() + 1 === month);
                return (
                  <div key={month} className="report-card">
                    <h3>{new Date(0, month - 1).toLocaleString('default', { month: 'long' })}</h3>
                    {report ? (
                      <button onClick={() => viewReport(report.id)}>View Report</button>
                    ) : (
                      <button onClick={() => generateReport(new Date().getFullYear(), month)}>Generate Report</button>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CollectionReportsDashboard;
