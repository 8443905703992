import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function AccountsPayableList() {
  const [accountsPayable, setAccountsPayable] = useState([]);

  useEffect(() => {
    const fetchAccountsPayable = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/accounts-payable`);
        setAccountsPayable(response.data);
      } catch (error) {
        console.error('Error fetching accounts payable:', error);
      }
    };

    fetchAccountsPayable();
  }, []);

  return (
    <div>
      <h1>Accounts Payable</h1>
      <ul>
        {accountsPayable.map(entry => (
          <li key={entry.id}>
            Client ID: {entry.clientId} - Invoice ID: {entry.invoiceId} - Amount: {entry.amount} - Due Date: {entry.dueDate}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AccountsPayableList;
