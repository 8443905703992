import React from 'react';
import FeatureTemplate from './FeatureTemplate';

const OrderManagement = () => {
  const title = "Order Management";
  const description = "Manage orders efficiently.";
  const screenshots = [
    // Add the paths to your screenshots here
    "/path/to/screenshot1.png",
    "/path/to/screenshot2.png"
  ];
  const next = "/features/call-center";
  const prev = "/features/reports";

  return <FeatureTemplate title={title} description={description} screenshots={screenshots} next={next} prev={prev} />;
};

export default OrderManagement;
