// src/components/ClientFunding.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './ClientFunding.css'; // Import the CSS file for styling

function ClientFunding() {
  const [fundings, setFundings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchFundings() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/client/fundings`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setFundings(response.data);
      } catch (error) {
        console.error('Error fetching fundings:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchFundings();
  }, []);

  return (
    <div className="funding-container">
      <h1>Client Funding</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="funding-table">
          <thead>
            <tr>
              <th>Funding ID</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {fundings.map((funding) => (
              <tr key={funding.id}>
                <td>{funding.id}</td>
                <td>${funding.amount}</td>
                <td>{funding.date}</td>
                <td>{funding.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ClientFunding;
