// SupplierDocumentsTab.js
import React, { useState, useEffect } from 'react';
import { fetchSupplierDocuments, downloadSupplierDocument, deleteSupplierDocument } from '../utils/apiSupplier';
import axiosInstance from '../../axiosConfig';
import config from '../../config';

function SupplierDocumentsTab({ supplierId, user }) {
  const [documents, setDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [file, setFile] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [documentType, setDocumentType] = useState('');

  useEffect(() => {
    const initializeDocuments = async () => {
      try {
        const supplierDocuments = await fetchSupplierDocuments(supplierId);
        setDocuments(supplierDocuments);
      } catch (error) {
        console.error('Error initializing documents:', error);
      } finally {
        setLoadingDocuments(false);
      }
    };

    initializeDocuments();
  }, [supplierId]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setDocumentName(selectedFile.name);
      setDocumentType(selectedFile.type);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('document', file);
    formData.append('documentName', documentName);
    formData.append('documentType', documentType);

    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(`${config.API_URL}/suppliers/${supplierId}/documents`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      // Refresh the document list
      const response = await axiosInstance.get(`${config.API_URL}/suppliers/${supplierId}/documents`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setDocuments(response.data);
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  const handleDownload = async (documentId, documentName) => {
    try {
      await downloadSupplierDocument(supplierId, documentId, documentName);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      await deleteSupplierDocument(supplierId, documentId);
      // Refresh the document list
      const response = await axiosInstance.get(`${config.API_URL}/suppliers/${supplierId}/documents`);
      setDocuments(response.data);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  return (
    <div className="documents-section">
      <h2>Uploaded Documents</h2>
      <form onSubmit={handleUpload} className="upload-form">
        <div className="form-group">
          <label>Document Name:</label>
          <input
            type="text"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Document Type:</label>
          <input
            type="text"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Document File:</label>
          <input
            type="file"
            onChange={handleFileChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">Upload Document</button>
      </form>
      {loadingDocuments ? (
        <p>Loading documents...</p>
      ) : (
        <div className="documents-table-container">
          <table className="documents-table">
            <thead>
              <tr>
                <th>Document ID</th>
                <th>Document Name</th>
                <th>Document Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {documents.map(document => (
                <tr key={document.id}>
                  <td>{document.id}</td>
                  <td>{document.documentName}</td>
                  <td>{document.documentType}</td>
                  <td>
                    <button onClick={() => handleDownload(document.id, document.documentName)}>
                      Download
                    </button>
                    {user.role === 'admin' && (
                      <button onClick={() => handleDelete(document.id)}>
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SupplierDocumentsTab;
