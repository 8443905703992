import React, { useState, useEffect } from 'react';
import './FactoringRateComponent.css';

const defaultSlidingScale = [
  { startDay: 0, endDay: 15, rate: 2.5 },
  { startDay: 16, endDay: 30, rate: 3.5 },
  { startDay: 31, endDay: 45, rate: 4.5 },
  { startDay: 46, endDay: 60, rate: 5.5 },
  { startDay: 61, endDay: 75, rate: 6.5 },
  { startDay: 76, endDay: 90, rate: 7.5 }
];

const convertToUpperLimits = (ranges) => {
  return ranges.map(item => ({ days: item.endDay, rate: item.rate }));
};

const convertFromUpperLimits = (upperLimits) => {
  let startDay = 0;
  return upperLimits.map(item => {
    const range = { startDay, endDay: item.days, rate: item.rate };
    startDay = item.days + 1;
    return range;
  });
};

function FactoringRateComponent({ onRateChange, initialRateData }) {
  const [rateType, setRateType] = useState('sliding');
  const [flatRate, setFlatRate] = useState('');
  const [additionalRate, setAdditionalRate] = useState('');
  const [slidingScale, setSlidingScale] = useState(defaultSlidingScale);

  useEffect(() => {
    if (initialRateData) {
      if (initialRateData.type === 'flat') {
        setRateType('flat');
        setFlatRate(initialRateData.rates.flatRate ? (initialRateData.rates.flatRate).toString() : '');
        setAdditionalRate(initialRateData.rates.additionalRate ? (initialRateData.rates.additionalRate).toString() : '');
        setSlidingScale([]);
      } else if (initialRateData.type === 'sliding') {
        const initialSlidingScale = initialRateData.rates.length
          ? convertFromUpperLimits(initialRateData.rates)
          : defaultSlidingScale; // Use default sliding scale if no rates provided
        setRateType('sliding');
        setSlidingScale(initialSlidingScale);
      }
    } else {
      setRateType('sliding');
      setFlatRate('');
      setAdditionalRate('');
      setSlidingScale(defaultSlidingScale);
    }
  }, [initialRateData]);

  const handleRateTypeChange = (e) => {
    const type = e.target.value;
    setRateType(type);
    if (type === 'flat') {
      onRateChange({ type, rates: { flatRate: parseFloat(flatRate) / 100, additionalRate: parseFloat(additionalRate) / 100 } });
    } else {
      onRateChange({ type, rates: slidingScale.length ? convertToUpperLimits(slidingScale) : convertToUpperLimits(defaultSlidingScale) });
    }
  };

  const handleFlatRateChange = (e, rateType) => {
    const value = e.target.value;
    if (rateType === 'flatRate') {
      setFlatRate(value);
      onRateChange({ type: 'flat', rates: { flatRate: value !== '' ? parseFloat(value) : null, additionalRate: additionalRate !== '' ? parseFloat(additionalRate) : null } }); // Change here
    } else if (rateType === 'additionalRate') {
      setAdditionalRate(value);
      onRateChange({ type: 'flat', rates: { flatRate: flatRate !== '' ? parseFloat(flatRate) : null, additionalRate: value !== '' ? parseFloat(value) : null } });
    }
  };

  const handleSlidingScaleChange = (index, field, value) => {
    const newSlidingScale = slidingScale.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setSlidingScale(newSlidingScale);
    if (rateType === 'sliding') {
      onRateChange({ type: 'sliding', rates: convertToUpperLimits(newSlidingScale) });
    }
  };

  const addSlidingRate = () => {
    const lastEndDay = slidingScale.length ? parseInt(slidingScale[slidingScale.length - 1].endDay, 10) : 0;
    const newRates = [...slidingScale, { startDay: lastEndDay + 1, endDay: '', rate: '' }];
    setSlidingScale(newRates);
    if (rateType === 'sliding') {
      onRateChange({ type: 'sliding', rates: convertToUpperLimits(newRates) });
    }
  };

  const removeSlidingRate = (index) => {
    const newRates = slidingScale.filter((_, i) => i !== index);
    setSlidingScale(newRates);
    if (rateType === 'sliding') {
      onRateChange({ type: 'sliding', rates: newRates.length ? convertToUpperLimits(newRates) : convertToUpperLimits(defaultSlidingScale) });
    }
  };

  return (
    <div className="factoring-rate-component">
      <div className="rate-type-selection">
        <label>
          <input
            type="radio"
            value="flat"
            checked={rateType === 'flat'}
            onChange={handleRateTypeChange}
          />
          Flat Rate
        </label>
        <label>
          <input
            type="radio"
            value="sliding"
            checked={rateType === 'sliding'}
            onChange={handleRateTypeChange}
          />
          Sliding Scale
        </label>
      </div>

      {rateType === 'flat' ? (
        <div className="flat-rate">
          <label>
            Flat Factoring Rate:
            <input
              type="number"
              value={flatRate}
              onChange={(e) => handleFlatRateChange(e, 'flatRate')}
              placeholder="Flat Factoring Rate (%)"
            />
          </label>
          <label>
            Additional Rate:
            <input
              type="number"
              value={additionalRate}
              onChange={(e) => handleFlatRateChange(e, 'additionalRate')}
              placeholder="Additional Rate (%)"
            />
          </label>
        </div>
      ) : (
        <div className="sliding-scale">
          <h4>Sliding Scale Rates</h4>
          <table className="sliding-scale-table">
            <thead>
              <tr>
                <th>Start Day</th>
                <th>End Day</th>
                <th>Rate (%)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {slidingScale.length ? slidingScale.map((item, index) => (
                <tr key={index}>
                  <td>{item.startDay}</td>
                  <td>
                    <input
                      type="number"
                      value={item.endDay}
                      onChange={(e) => handleSlidingScaleChange(index, 'endDay', parseInt(e.target.value, 10))}
                      placeholder="End Day"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.rate}
                      onChange={(e) => handleSlidingScaleChange(index, 'rate', e.target.value)}
                      placeholder="Rate"
                    />
                  </td>
                  <td>
                    <button type="button" onClick={() => removeSlidingRate(index)}>Remove</button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="4">No sliding scale rates. Add one to start.</td>
                </tr>
              )}
            </tbody>
          </table>
          <button type="button" onClick={addSlidingRate}>Add Another Sliding Rate</button>
        </div>
      )}
    </div>
  );
}

export default FactoringRateComponent;
