import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { Card, Col, Row, Button, Table, notification, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const FactoringDashboard = () => {
  const [invoiceMetrics, setInvoiceMetrics] = useState({});
  const [paymentMetrics, setPaymentMetrics] = useState({});
  const [loadingInvoiceMetrics, setLoadingInvoiceMetrics] = useState(true);
  const [loadingPaymentMetrics, setLoadingPaymentMetrics] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInvoiceMetrics();
    fetchPaymentMetrics();
  }, []);

  const fetchInvoiceMetrics = async () => {
    setLoadingInvoiceMetrics(true);
    try {
      const invoiceRes = await axiosInstance.get('/invoices/metrics');
      setInvoiceMetrics(invoiceRes.data);
    } catch (error) {
      notification.error({ message: 'Error fetching invoice metrics', description: error.message });
    } finally {
      setLoadingInvoiceMetrics(false);
    }
  };

  const fetchPaymentMetrics = async () => {
    setLoadingPaymentMetrics(true);
    try {
      const paymentRes = await axiosInstance.get('/invoices/payments/metrics');
      setPaymentMetrics(paymentRes.data);
    } catch (error) {
      notification.error({ message: 'Error fetching payment metrics', description: error.message });
    } finally {
      setLoadingPaymentMetrics(false);
    }
  };

  const handleGenerateCollectionReportsForAll = async () => {
    try {
      await axiosInstance.post('/clients/generate-collection-reports');
      notification.success({ message: 'Collection reports generated for all clients with outstanding invoices' });
      // Trigger clients refresh
      const clientsComponent = document.getElementById('clients-component');
      if (clientsComponent) clientsComponent.dispatchEvent(new CustomEvent('refreshClients'));
    } catch (error) {
      notification.error({ message: 'Error generating collection reports', description: error.message });
    }
  };

  const handleTriggerProcessTransactions = async () => {
    try {
      await axiosInstance.post('/jobs/trigger-process-transactions');
      notification.success({ message: 'Process transactions job triggered' });
      // Trigger clients refresh
      const clientsComponent = document.getElementById('clients-component');
      if (clientsComponent) clientsComponent.dispatchEvent(new CustomEvent('refreshClients'));
    } catch (error) {
      notification.error({ message: 'Error triggering job', description: error.message });
    }
  };

  const navigateToCreatePayment = (client) => {
    navigate('/create-payment', {
      state: {
        payeeType: 'Client',
        payee: client
      }
    });
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Card
            title="Invoice Metrics"
            extra={<Button icon={<ReloadOutlined />} onClick={fetchInvoiceMetrics} />}
          >
            {loadingInvoiceMetrics ? <Spin /> : (
              <>
                <p>Total Invoices: {invoiceMetrics.total}</p>
                <p>Factored Invoices: {invoiceMetrics.factored}</p>
                <p>Open Zero Balance Invoices: {invoiceMetrics.openZeroBalance}</p>
                <p>Eligible for Collection: {invoiceMetrics.eligibleForCollection}</p>
                <p>Past Due Invoices: {invoiceMetrics.pastDue}</p>
                <p>Closed Invoices: {invoiceMetrics.closedInvoices}</p>
              </>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Payment Metrics"
            extra={<Button icon={<ReloadOutlined />} onClick={fetchPaymentMetrics} />}
          >
            {loadingPaymentMetrics ? <Spin /> : (
              <>
                <p>Total Payments: {paymentMetrics.totalPayments}</p>
                <p>Payments Applied: {paymentMetrics.totalApplied}</p>
                <p>Outstanding Payments: {paymentMetrics.totalOutstanding}</p>
              </>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Actions">
            <Button type="primary" onClick={handleGenerateCollectionReportsForAll}>
              Generate Collection Reports for All
            </Button>
            <Button type="primary" style={{ marginTop: '10px' }} onClick={handleTriggerProcessTransactions}>
              Trigger Process Transactions
            </Button>
          </Card>
        </Col>
      </Row>
      <ClientsComponent navigateToCreatePayment={navigateToCreatePayment} />
    </div>
  );
};

const ClientsComponent = ({ navigateToCreatePayment }) => {
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true);
  const [filters, setFilters] = useState({
    activeFactoredInvoices: false,
    invoicesReadyForCollection: false,
    pastDueInvoices: false,
    unpaidCollectionReports: false,
  });

  const fetchClients = useCallback(async () => {
    setLoadingClients(true);
    try {
      const filterParams = Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key]) => key)
        .join(',');

      const url = filterParams ? `/clients/metrics?filter=${filterParams}` : '/clients/metrics';

      const clientsRes = await axiosInstance.get(url);
      setClients(clientsRes.data);
    } catch (error) {
      notification.error({ message: 'Error fetching clients', description: error.message });
    } finally {
      setLoadingClients(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchClients();
  }, [filters, fetchClients]);

  useEffect(() => {
    const handleRefreshClients = () => fetchClients();
    const clientsComponent = document.getElementById('clients-component');
    clientsComponent.addEventListener('refreshClients', handleRefreshClients);
    return () => clientsComponent.removeEventListener('refreshClients', handleRefreshClients);
  }, [fetchClients]);

  const toggleFilter = (filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey],
    }));
  };

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Active Factored Invoices',
      dataIndex: 'activeFactoredInvoices',
      key: 'activeFactoredInvoices',
      sorter: (a, b) => a.activeFactoredInvoices - b.activeFactoredInvoices,
    },
    {
      title: 'Invoices Ready for Collection',
      dataIndex: 'invoicesReadyForCollection',
      key: 'invoicesReadyForCollection',
      sorter: (a, b) => a.invoicesReadyForCollection - b.invoicesReadyForCollection,
    },
    {
      title: 'Past Due Invoices',
      dataIndex: 'pastDueInvoices',
      key: 'pastDueInvoices',
      sorter: (a, b) => a.pastDueInvoices - b.pastDueInvoices,
    },
    {
      title: 'Unpaid Collection Reports',
      dataIndex: 'unpaidCollectionReports',
      key: 'unpaidCollectionReports',
      sorter: (a, b) => a.unpaidCollectionReports - b.unpaidCollectionReports,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          {record.unpaidCollectionReports > 0 && (
            <Button onClick={() => navigateToCreatePayment(record)}>
              Create Payment
            </Button>
          )}
          {record.invoicesReadyForCollection > 0 && (
            <Button onClick={() => handleGenerateCollectionReport(record.id)}>
              Generate Collection Report
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleGenerateCollectionReport = async (clientId) => {
    try {
      await axiosInstance.post(`/clients/${clientId}/collection-reports`);
      notification.success({ message: 'Collection report generated for client' });
      fetchClients(); // Refresh clients data
    } catch (error) {
      notification.error({ message: 'Error generating collection report', description: error.message });
    }
  };

  return (
    <div id="clients-component">
      <Card
        title="Clients"
        extra={<Button icon={<ReloadOutlined />} onClick={fetchClients} />}
      >
        <Row gutter={8}>
          <Col>
            <Button
              type={filters.activeFactoredInvoices ? 'primary' : 'default'}
              onClick={() => toggleFilter('activeFactoredInvoices')}
            >
              Active Factored Invoices
            </Button>
          </Col>
          <Col>
            <Button
              type={filters.invoicesReadyForCollection ? 'primary' : 'default'}
              onClick={() => toggleFilter('invoicesReadyForCollection')}
            >
              Invoices Ready for Collection
            </Button>
          </Col>
          <Col>
            <Button
              type={filters.pastDueInvoices ? 'primary' : 'default'}
              onClick={() => toggleFilter('pastDueInvoices')}
            >
              Past Due Invoices
            </Button>
          </Col>
          <Col>
            <Button
              type={filters.unpaidCollectionReports ? 'primary' : 'default'}
              onClick={() => toggleFilter('unpaidCollectionReports')}
            >
              Unpaid Collection Reports
            </Button>
          </Col>
        </Row>
        {loadingClients ? <Spin /> : (
          <Table
            dataSource={clients}
            columns={columns}
            rowKey="id"
          />
        )}
      </Card>
    </div>
  );
};

export default FactoringDashboard;
