import React, { useState } from "react";
import axiosInstance from "../axiosConfig";
import config from "../config";
import Modal from "react-modal";
import GenericSearch from "./GenericSearch";
import "./RoleManagement.css";

Modal.setAppElement("#root");

function RoleManagement() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [role, setRole] = useState("");
  const [entityId, setEntityId] = useState("");
  const [entityName, setEntityName] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [currentEntityName, setCurrentEntityName] = useState("");
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false); // Define state for entity modal
  const [updating, setUpdating] = useState(false);
  const [message, setMessage] = useState("");

  const [clientInlineSearchValue, setClientInlineSearchValue] = useState("");
  const [customerInlineSearchValue, setCustomerInlineSearchValue] =
    useState("");
  const [employeeInlineSearchValue, setEmployeeInlineSearchValue] =
    useState("");

  const handleSelectUser = async (user) => {
    setSelectedUser(user);
    setRole(""); // Clear the role to ensure the user selects a new role
    setEntityId("");
    setEntityName("");
    setCurrentRole(user.role); // Set current role for display
    setCurrentEntityName(""); // Reset entity name display

    if (user.role) {
      let entityEndpoint = "";
      if (user.role === "client")
        entityEndpoint = `${config.API_URL}/clients/${user.clientId}`;
      if (user.role === "customer")
        entityEndpoint = `${config.API_URL}/customers/${user.customerId}`;
      if (user.role === "employee" || user.role === "admin")
        entityEndpoint = `${config.API_URL}/employees/${user.employeeId}`;

      if (entityEndpoint) {
        try {
          const entityResponse = await axiosInstance.get(entityEndpoint);
          setEntityId(user[`${user.role}Id`]);
          setEntityName(
            entityResponse.data.name || entityResponse.data.companyName
          );
          setCurrentEntityName(
            entityResponse.data.name || entityResponse.data.companyName
          ); // Set entity name for display
        } catch (error) {
          console.error("Error fetching entity data:", error);
          setMessage("Error fetching entity data");
        }
      }
    }
  };

  const handleChangeRole = (e) => {
    setRole(e.target.value);
    setEntityId("");
    setEntityName("");

    // Clear the inline search values when the role changes
    setClientInlineSearchValue("");
    setCustomerInlineSearchValue("");
    setEmployeeInlineSearchValue("");

    // Close any open entity modals when the role changes
    setIsEntityModalOpen(false);
  };

  const handleSelectClient = (client) => {
    setEntityId(client.id);
    setEntityName(client.name);
    setClientInlineSearchValue(client.name);
    setIsEntityModalOpen(false); // Close the modal after selecting a client
  };

  const handleSelectCustomer = (customer) => {
    setEntityId(customer.id);
    setEntityName(customer.name);
    setCustomerInlineSearchValue(customer.name);
    setIsEntityModalOpen(false); // Close the modal after selecting a customer
  };

  const handleSelectEmployee = (employee) => {
    setEntityId(employee.id);
    setEntityName(employee.name);
    setEmployeeInlineSearchValue(employee.name);
    setIsEntityModalOpen(false); // Close the modal after selecting an employee
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setMessage("");
    try {
      const updateData = { userId: selectedUser.id, role };
      let updatedEntityName = entityName; // Store the entity name to use after update

      if (role === "client") {
        updateData.clientId = entityId;
        updatedEntityName = clientInlineSearchValue || entityName;
      }
      if (role === "customer") {
        updateData.customerId = entityId;
        updatedEntityName = customerInlineSearchValue || entityName;
      }
      if (role === "employee" || role === "admin") {
        updateData.employeeId = entityId;
        updatedEntityName = employeeInlineSearchValue || entityName;
      }

      await axiosInstance.put(
        `${config.API_URL}/users/update-role`,
        updateData
      );

      // Update the selectedUser state with the new role and entity information
      setSelectedUser((prevUser) => ({
        ...prevUser,
        role,
        [`${role === "admin" ? "employee" : role}Id`]: entityId,
      }));

      // Ensure the Current Role and Associated Entity are updated
      setCurrentRole(role);
      setCurrentEntityName(updatedEntityName);

      setMessage("Role and association updated successfully");
    } catch (error) {
      console.error("Error updating role and association:", error);
      setMessage("Error updating role and association");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="role-management">
      <h2>Manage User Roles and Associations</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>User:</label>
          <GenericSearch
            config={{
              entity: "users",
              metadataEndpoint: `${config.API_URL}/metadata/users`,
              searchEndpoint: `${config.API_URL}/users/search`,
              title: "User Search",
            }}
            onSelectItem={handleSelectUser}
            inlineSearch={true}
            selectedValue={selectedUser ? selectedUser.username : ""}
            autoFocus={true}
            openModal={() => setIsUserModalOpen(true)}
          />
        </div>

        {selectedUser && (
          <div className="user-details">
            <p>
              <strong>Current Role:</strong> {currentRole}
            </p>
            {currentEntityName && (
              <p>
                <strong>
                  Associated{" "}
                  {currentRole.charAt(0).toUpperCase() + currentRole.slice(1)}:
                </strong>{" "}
                {currentEntityName}
              </p>
            )}
          </div>
        )}

        <div className="form-group">
          <label>New Role:</label>
          <select value={role} onChange={handleChangeRole} required>
            <option value="">Select a role</option>
            <option value="admin">Admin</option>
            <option value="employee">Employee</option>
            <option value="client">Client</option>
            <option value="customer">Customer</option>
            <option value="user">User</option>
          </select>
        </div>

        {(role === "employee" || role === "admin") && (
          <div className="form-group">
            <label>Employee:</label>
            <GenericSearch
              config={{
                entity: "employees",
                metadataEndpoint: `${config.API_URL}/metadata/employees`,
                searchEndpoint: `${config.API_URL}/employees/search`,
                title: "Employee Search",
              }}
              onSelectItem={handleSelectEmployee}
              inlineSearch={true}
              selectedValue={employeeInlineSearchValue}
              autoFocus={false}
              openModal={() => setIsEntityModalOpen(true)}
            />
          </div>
        )}

        {role === "client" && (
          <div className="form-group">
            <label>Client:</label>
            <GenericSearch
              config={{
                entity: "clients",
                metadataEndpoint: `${config.API_URL}/metadata/clients`,
                searchEndpoint: `${config.API_URL}/clients/search`,
                title: "Client Search",
              }}
              onSelectItem={handleSelectClient}
              inlineSearch={true}
              selectedValue={clientInlineSearchValue}
              autoFocus={false}
              openModal={() => setIsEntityModalOpen(true)}
            />
          </div>
        )}

        {role === "customer" && (
          <div className="form-group">
            <label>Customer:</label>
            <GenericSearch
              config={{
                entity: "customers",
                metadataEndpoint: `${config.API_URL}/metadata/customers`,
                searchEndpoint: `${config.API_URL}/customers/search`,
                title: "Customer Search",
              }}
              onSelectItem={handleSelectCustomer}
              inlineSearch={true}
              selectedValue={customerInlineSearchValue}
              autoFocus={false}
              openModal={() => setIsEntityModalOpen(true)}
            />
          </div>
        )}

        <button type="submit" disabled={updating} className="submit-button">
          {updating ? "Updating..." : "Update Role and Association"}
        </button>
      </form>
      {message && <p>{message}</p>}

      {/* User Search Modal */}
      <Modal
        isOpen={isUserModalOpen}
        onRequestClose={() => setIsUserModalOpen(false)}
        contentLabel="User Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>User Search</h2>
            <button onClick={() => setIsUserModalOpen(false)}>&times;</button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={{
                entity: "users",
                metadataEndpoint: `${config.API_URL}/metadata/users`,
                searchEndpoint: `${config.API_URL}/users/search`,
                title: "User Search",
              }}
              onSelectItem={(item) => {
                handleSelectUser(item);
                setIsUserModalOpen(false);
              }}
              closeModal={() => setIsUserModalOpen(false)}
              inModal={true}
            />
          </div>
        </div>
      </Modal>

      {/* Entity Search Modal */}
      <Modal
        isOpen={isEntityModalOpen}
        onRequestClose={() => setIsEntityModalOpen(false)}
        contentLabel="Entity Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>{role.charAt(0).toUpperCase() + role.slice(1)} Search</h2>
            <button onClick={() => setIsEntityModalOpen(false)}>&times;</button>
          </div>
          <div className="search-body">
            {role === "client" && (
              <GenericSearch
                config={{
                  entity: "clients",
                  metadataEndpoint: `${config.API_URL}/metadata/clients`,
                  searchEndpoint: `${config.API_URL}/clients/search`,
                  title: "Client Search",
                }}
                onSelectItem={handleSelectClient}
                closeModal={() => setIsEntityModalOpen(false)}
                inModal={true}
              />
            )}
            {role === "customer" && (
              <GenericSearch
                config={{
                  entity: "customers",
                  metadataEndpoint: `${config.API_URL}/metadata/customers`,
                  searchEndpoint: `${config.API_URL}/customers/search`,
                  title: "Customer Search",
                }}
                onSelectItem={handleSelectCustomer}
                closeModal={() => setIsEntityModalOpen(false)}
                inModal={true}
              />
            )}
            {role === "employee" || role === "admin" ? (
              <GenericSearch
                config={{
                  entity: "employees",
                  metadataEndpoint: `${config.API_URL}/metadata/employees`,
                  searchEndpoint: `${config.API_URL}/employees/search`,
                  title: "Employee Search",
                }}
                onSelectItem={handleSelectEmployee}
                closeModal={() => setIsEntityModalOpen(false)}
                inModal={true}
              />
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RoleManagement;
