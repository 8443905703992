// components/CustomerDetails/CustomerMetadataTab.js
import React from 'react';

function CustomerMetadataTab({ metadata }) {
  return (
    <div>
      <h2>Customer Metadata</h2>
      {metadata ? (
        <table className="metadata-table">
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(metadata).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value !== null ? value.toString() : 'null'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No metadata available.</p>
      )}
    </div>
  );
}

export default CustomerMetadataTab;
