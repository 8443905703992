import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';

function AddAccountsReceivable() {
  const [formData, setFormData] = useState({
    customerId: '',
    invoiceId: '',
    amount: '',
    dueDate: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`${config.API_URL}/accounts-receivable`, formData);
      navigate('/accounts-receivable');
    } catch (error) {
      console.error('Error adding accounts receivable:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="customerId" onChange={handleChange} placeholder="Customer ID" required />
      <input type="text" name="invoiceId" onChange={handleChange} placeholder="Invoice ID" required />
      <input type="number" name="amount" onChange={handleChange} placeholder="Amount" required />
      <input type="date" name="dueDate" onChange={handleChange} required />
      <button type="submit">Add Accounts Receivable</button>
    </form>
  );
}

export default AddAccountsReceivable;
