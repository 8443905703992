import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';

function AddJournalEntry() {
  const [formData, setFormData] = useState({
    date: '',
    description: '',
    debitAccount: '',
    creditAccount: '',
    amount: '',
    transactionReference: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`${config.API_URL}/journal-entries`, formData);
      navigate('/journal-entries');
    } catch (error) {
      console.error('Error adding journal entry:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="date" name="date" onChange={handleChange} required />
      <input type="text" name="description" onChange={handleChange} placeholder="Description" required />
      <input type="text" name="debitAccount" onChange={handleChange} placeholder="Debit Account" required />
      <input type="text" name="creditAccount" onChange={handleChange} placeholder="Credit Account" required />
      <input type="number" name="amount" onChange={handleChange} placeholder="Amount" required />
      <input type="text" name="transactionReference" onChange={handleChange} placeholder="Transaction Reference" required />
      <button type="submit">Add Journal Entry</button>
    </form>
  );
}

export default AddJournalEntry;
