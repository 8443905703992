import React from 'react';
import FeatureTemplate from './FeatureTemplate';

const ReportFeature = () => {
  const title = "Reports and Collections";
  const description = "Generate and view detailed reports and collections.";
  const screenshots = [
    // Add the paths to your screenshots here
    "/path/to/screenshot1.png",
    "/path/to/screenshot2.png"
  ];
  const next = "/features/order-management";
  const prev = "/features/transactions";

  return <FeatureTemplate title={title} description={description} screenshots={screenshots} next={next} prev={prev} />;
};

export default ReportFeature;
