import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import './ReviewPayments.css';

const ReviewPayments = ({ payments, onEditPayment, onSavePayment, savedPayments }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [checkNumber, setCheckNumber] = useState(null);

  useEffect(() => {
    const fetchNextCheckNumber = async () => {
      try {
        const response = await axiosInstance.get('/payments/next-check-number');
        setCheckNumber(response.data.nextCheckNumber);
      } catch (error) {
        console.error('Error fetching next check number:', error);
      }
    };

    fetchNextCheckNumber();
  }, []);

  const handleNext = () => {
    if (currentIndex < payments.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleSave = async () => {
    const payment = payments[currentIndex];
  
    // Ensure required fields are not null
    const paymentData = {
      ...payment,
      number: checkNumber !== null ? checkNumber : payment.number,
      amount: payment.paymentAmount !== null ? payment.paymentAmount : 0, // Provide a default amount if missing
      payee: payment.entityName !== null ? payment.entityName : "Unknown Payee", // Default to "Unknown Payee"
      payeeAddress: payment.entityAddress !== null ? payment.entityAddress : "Unknown Payee Address",
      date: payment.paymentDate !== null ? payment.paymentDate : new Date().toISOString().split('T')[0], // Default to today's date
      type: payment.paymentType !== null ? payment.paymentType : "Check", // Default to "Check"
      account: payment.account !== null ? payment.account : "Unknown Account", // Default to "Unknown Account"
      comments: payment.comments || 'No comments', // Default to "No comments"
      metadata: payment.metadata || {}, // Default to an empty object if metadata is missing
      items: payment.items.map(item => ({
        ...item,
        id: item.id,
        accountsPayableId: item.id,
        payableSourceType: item.payableSourceType,
        payableSourceId: item.payableSourceId,
        paymentAmount: item.paymentAmount
      })),
      paymentType: payment.paymentType
    };
  
    console.log('Prepared Payment Data:', paymentData);
  
    try {
      const response = await axiosInstance.post('/payments', paymentData);
      const savedPayment = { 
        ...payment, 
        checkNumber, 
        encodedContent: response.data.encodedContent, 
        encoding: response.data.encoding 
      };
      console.log('Saved Payment:', savedPayment);
      onSavePayment(currentIndex, savedPayment);
      setCheckNumber(checkNumber + 1);
    } catch (error) {
      console.error('Error saving payment:', error);
    }
  };
  
  

  const handleDownload = () => {
    const payment = savedPayments[currentIndex];
    if (payment && payment.encodedContent) {
      const linkSource = `data:${payment.encoding};base64,${payment.encodedContent}`;
      const downloadLink = document.createElement('a');
      const fileName = `Payment_${payment.checkNumber || payment.id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  const payment = payments[currentIndex];
  const isSaved = !!savedPayments[currentIndex];

  return (
    <div className="review-payments">
      <div className="payment-review">
        <h4>Payment {currentIndex + 1} of {payments.length}</h4>
        <p><strong>Payee:</strong> {payment.entityName}</p>
        <p><strong>Type:</strong> {payment.paymentType}</p>
        <p><strong>Date:</strong> {payment.paymentDate}</p>
        {payment.paymentType === 'Check' && (
          <p><strong>Check Number:</strong> {isSaved ? savedPayments[currentIndex].checkNumber : checkNumber}</p>
        )}
        <h4>Line Items:</h4>
        <ul>
          {payment.items.map((item) => (
            <li key={item.id}>
              {item.payableSourceType} - {item.payableSourceId}: {item.balance}
            </li>
          ))}
        </ul>
        <button onClick={() => onEditPayment(currentIndex)} disabled={isSaved}>Edit</button>
        <button onClick={handleSave} disabled={isSaved}>Save</button>
        {isSaved && payment.paymentType === 'Check' && <button onClick={handleDownload}>Download PDF</button>}
      </div>
      <div className="review-navigation">
        <button onClick={handlePrevious} disabled={currentIndex === 0}>Previous</button>
        <button onClick={handleNext} disabled={currentIndex === payments.length - 1}>Next</button>
      </div>
    </div>
  );
};

export default ReviewPayments;
