import React from 'react';
import FeatureTemplate from './FeatureTemplate';

const InvoiceManagement = () => {
  const title = "Invoice and Schedule Management";
  const description = "Manage your invoices and schedules with ease.";
  const screenshots = [
    // Add the paths to your screenshots here
    "/path/to/screenshot1.png",
    "/path/to/screenshot2.png"
  ];
  const next = "/features/transactions";
  const prev = "/features/client-management";

  return <FeatureTemplate title={title} description={description} screenshots={screenshots} next={next} prev={prev} />;
};

export default InvoiceManagement;
