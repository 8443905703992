import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table';
import Modal from 'react-modal';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './PurchaseOrderDetails.css'; // Import the CSS file

Modal.setAppElement('#root'); // Ensure accessibility

function PurchaseOrderDetails() {
  const { orderId } = useParams(); // Ensure the correct parameter is used
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [metadata, setMetadata] = useState(null);
  const [showMetadata, setShowMetadata] = useState(false);

  useEffect(() => {
    const fetchPurchaseOrderDetails = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/orders/${orderId}`);
        setPurchaseOrder(response.data);
        setInvoices(response.data.invoices || []); // Assuming the related invoices are included
      } catch (error) {
        console.error('Error fetching purchase order details:', error);
      }
    };

    fetchPurchaseOrderDetails();
  }, [orderId]);

  const columns = useMemo(() => [
    {
      Header: 'Invoice Number',
      accessor: 'invoiceNumber'
    },
    {
      Header: 'Invoice Date',
      accessor: 'invoiceDate',
      Cell: ({ value }) => new Date(value).toLocaleDateString() // Format date
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : '' // Format date
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) // Format as currency
    },
    {
      Header: 'Status',
      accessor: 'status'
    }
  ], []);

  const data = useMemo(() => invoices, [invoices]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = tableInstance;

  const handleShowMetadata = (metadata) => {
    setMetadata(metadata);
    setShowMetadata(true);
  };

  if (!purchaseOrder) {
    return <div>Loading...</div>;
  }

  return (
    <div className="purchase-order-details">
      <h1>Purchase Order Details</h1>
      <p><strong>Purchase Order Number:</strong> {purchaseOrder.poNumber}</p>
      <p><strong>Amount:</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(purchaseOrder.amount)}</p>
      <p><strong>Order Date:</strong> {new Date(purchaseOrder.date).toLocaleDateString()}</p>
      <p><strong>Client Name:</strong> {purchaseOrder.client ? purchaseOrder.client.name : 'N/A'}</p>
      <p><strong>Customer Name:</strong> {purchaseOrder.customer ? purchaseOrder.customer.name : 'N/A'}</p>
      <p><strong>Status:</strong> {purchaseOrder.status}</p>
      <p><strong>Balance:</strong> {purchaseOrder.balance}</p>
      <p><strong>Completion Date:</strong> {new Date(purchaseOrder.completionDate).toLocaleDateString()}</p>
      <p><strong>Terms:</strong> {purchaseOrder.terms}</p>
      <p><strong>Approval Status:</strong> {purchaseOrder.approvalStatus}</p>
      <p><strong>Limit:</strong> {purchaseOrder.limit}</p>
      <p><strong>Comments:</strong> {purchaseOrder.comments}</p>
      <p><strong>Approval Date:</strong> {purchaseOrder.approvalDate ? new Date(purchaseOrder.approvalDate).toLocaleDateString() : 'N/A'}</p>
      <p><strong>Approved By:</strong> {purchaseOrder.approvedBy || 'N/A'}</p>
      <button className="view-metadata-button" onClick={() => handleShowMetadata(purchaseOrder.metadata)}>View Metadata</button>

      <h2>Related Invoices</h2>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={headerGroupKey} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key: columnKey, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th key={columnKey} {...restColumnProps}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...restRowProps } = row.getRowProps();
            return (
              <tr key={rowKey} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...restCellProps } = cell.getCellProps();
                  return (
                    <td key={cellKey} {...restCellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <Modal
        isOpen={showMetadata}
        onRequestClose={() => setShowMetadata(false)}
        contentLabel="Metadata"
        className="metadata-modal"
        overlayClassName="metadata-overlay"
      >
        <div className="metadata-content">
          <div className="metadata-header">
            <h2>Metadata</h2>
            <button onClick={() => setShowMetadata(false)}>&times;</button>
          </div>
          <div className="metadata-body">
            <pre>{JSON.stringify(metadata, null, 2)}</pre>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PurchaseOrderDetails;
