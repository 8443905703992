import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { UserContext } from '../context/UserContext';
import { NotificationContext } from '../context/NotificationContext';
import TokenExpiryIndicator from './TokenExpiryIndicator';
import './TopBar.css';
import { notification as antdNotification } from 'antd';

function TopBar({ toggleSidebar, hoverSidebar, closeSidebar, isSidebarOpen, isSidebarPinned }) {
  const { user, logoutUser } = useContext(UserContext);
  const { unreadCount, setNewNotificationHandler } = useContext(NotificationContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [association, setAssociation] = useState('');
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const fetchAssociation = useCallback(async () => {
    if (user) {
      try {
        const token = localStorage.getItem('token');
        let endpoint = '';
        if (user.role === 'client') {
          endpoint = `${config.API_URL}/clients/${user.clientId}`;
        } else if (user.role === 'customer') {
          endpoint = `${config.API_URL}/customers/${user.customerId}`;
        } else if (user.role === 'employee') {
          endpoint = `${config.API_URL}/employees/${user.employeeId}`;
        }
        if (endpoint) {
          const response = await axiosInstance.get(endpoint, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setAssociation(response.data.name || response.data.companyName);
        }
      } catch (error) {
        console.error('Error fetching association:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchAssociation();
  }, [fetchAssociation]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleLogout = () => {
    logoutUser();
    navigate('/login');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    setNewNotificationHandler((notification) => {
      if (notification && !notification.read && notification.message) {
        antdNotification.info({
          message: 'New Notification',
          description: notification.message,
        });
      }
    });
  }, [setNewNotificationHandler]);

  return (
    <div className="top-bar">
      <div className="top-bar-left">
        <button
          className="hamburger"
          onClick={toggleSidebar}
          onMouseEnter={hoverSidebar}
          onMouseLeave={closeSidebar}
        >
          {/* Show '☰' during hover or when closed, and '✕' only if pinned open */}
          {isSidebarPinned ? '✕' : '☰'}
        </button>
        <Link to="/" className="logo">American Factor</Link>
      </div>
      <div className="welcome-message">
        {user && (
          <span>
            Welcome, {user.username} ({user.role}){association && ` - ${association}`}
          </span>
        )}
      </div>
      <div className="menu">
        {user ? (
          <div className="profile-menu" ref={menuRef}>
            <TokenExpiryIndicator />
            <Link to="/notifications" className="notification-bell">
              <i className="fa fa-bell"></i>
              {unreadCount > 0 && <span className="notification-dot"></span>}
            </Link>
            <img
              src="/images/00000000000000000000000000000000.png"
              alt="Profile"
              className="profile-icon"
              onClick={toggleMenu}
            />
            {menuOpen && (
              <div className="dropdown-menu">
                <Link to="/profile" onClick={toggleMenu}>Profile</Link>
                <button onClick={handleLogout}>Logout</button>
              </div>
            )}
          </div>
        ) : (
          <>
            <Link to="/login">Login</Link>
            <Link to="/register">Register</Link>
          </>
        )}
      </div>
    </div>
  );
}

export default TopBar;
