import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Modal from 'react-modal';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './ScheduleDetails.css'; // Reuse the same CSS for styling

Modal.setAppElement('#root'); // Ensure accessibility

function AccountsPayableDetails() {
  const { accountsPayableId } = useParams();
  const [accountsPayable, setAccountsPayable] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [showMetadata, setShowMetadata] = useState(false);

  useEffect(() => {
    const fetchAccountsPayableDetails = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/accounts-payable/${accountsPayableId}`);
        setAccountsPayable(response.data);
      } catch (error) {
        console.error('Error fetching accounts payable details:', error);
      }
    };

    fetchAccountsPayableDetails();
  }, [accountsPayableId]);

  const handleShowMetadata = (metadata) => {
    setMetadata(metadata);
    setShowMetadata(true);
  };

  if (!accountsPayable) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Accounts Payable Details</h1>
      <p><strong>Description:</strong> {accountsPayable.description}</p>
      <p><strong>Amount:</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(accountsPayable.amount)}</p>
      <p><strong>Balance:</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(accountsPayable.balance)}</p>
      <p><strong>Due Date:</strong> {new Date(accountsPayable.dueDate).toLocaleDateString()}</p>
      {/* <p><strong>Status:</strong> {accountsPayable.status}</p> */}

      <p>
        <strong>Payable Source:</strong>{' '}
        <Link to={`/${accountsPayable.payableSourceType.toLowerCase()}s/${accountsPayable.payableSourceId}`}>
          View {accountsPayable.payableSourceType} Details
        </Link>
      </p>

      <p>
        <strong>Payable Entity:</strong>{' '}
        <Link to={`/${accountsPayable.payableEntityType.toLowerCase()}s/${accountsPayable.payableEntityId}`}>
          View {accountsPayable.payableEntityType} Details
        </Link>
      </p>

      <button className="view-metadata-button" onClick={() => handleShowMetadata(accountsPayable.metadata)}>Show Metadata</button>

      <Modal
        isOpen={showMetadata}
        onRequestClose={() => setShowMetadata(false)}
        contentLabel="Metadata"
        className="metadata-modal"
        overlayClassName="metadata-overlay"
      >
        <div className="metadata-content">
          <div className="metadata-header">
            <h2>Metadata</h2>
            <button onClick={() => setShowMetadata(false)}>&times;</button>
          </div>
          <div className="metadata-body">
            <pre>{JSON.stringify(metadata, null, 2)}</pre>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AccountsPayableDetails;
