import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate, useParams } from 'react-router-dom';

function EditPayment() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    invoiceId: '',
    amount: '',
    date: '',
    status: ''
  });
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPayment() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token provided.');
          return;
        }

        const response = await axiosInstance.get(`${config.API_URL}/payments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const paymentData = response.data;
        // Ensure the date is formatted correctly as yyyy-MM-dd
        paymentData.date = paymentData.date.split('T')[0];
        console.log('Fetched payment data:', paymentData);
        setFormData(paymentData);
      } catch (err) {
        console.error('Error fetching payment:', err);
      }
    }

    async function fetchInvoices() {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/invoices`);
        setInvoices(response.data);
      } catch (err) {
        console.error('Error fetching invoices:', err);
      }
    }

    fetchPayment();
    fetchInvoices();
  }, [id]);

  const handleChange = (e) => {
    console.log('Handle change:', e.target.name, e.target.value);
    setFormData(prevState => {
      const newState = {
        ...prevState,
        [e.target.name]: e.target.value
      };
      console.log('Updated formData:', newState);
      return newState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const updatedFormData = {
        ...formData,
        date: formData.date // No need to convert the date as it's already in yyyy-MM-dd format
      };
      console.log('Submitting updated formData:', updatedFormData);
      await axiosInstance.put(`${config.API_URL}/payments/${id}`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      navigate('/payments');
    } catch (error) {
      console.error('Error updating payment:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <select name="invoiceId" value={formData.invoiceId} onChange={handleChange} required>
        <option value="">Select Invoice</option>
        {invoices.map(invoice => (
          <option key={invoice.id} value={invoice.id}>{invoice.description}</option>
        ))}
      </select>
      <input type="number" name="amount" value={formData.amount} onChange={handleChange} placeholder="Amount" required />
      <input type="date" name="date" value={formData.date} onChange={handleChange} placeholder="Date" required />
      <input type="text" name="status" value={formData.status} onChange={handleChange} placeholder="Status" required />
      <button type="submit">Update Payment</button>
    </form>
  );
}

export default EditPayment;
