import { useNavigate, useLocation } from 'react-router-dom';
import GenericSearch from './GenericSearch';
import './CustomerSearch.css';
import entitySearchConfig from '../entitySearchConfig';

function CustomerSearch() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerName = searchParams.get('customerName') || '';

  const handleSelectEntity = (entity) => {
    navigate(`/customers/${entity.id}`);
  };

  return (
    <div className="flexDiv">
      <GenericSearch
        config={entitySearchConfig.customer}
        onSelectItem={handleSelectEntity}
        closeModal={() => {}}
        inModal={false}
        initialFormData={{ customerName }}
      />
    </div>
  );
}

export default CustomerSearch;
