import React from 'react';

function SupplierAddresses({ addresses }) {
  return (
    <div>
      <h2>Addresses</h2>
      <ul>
        {addresses.map((address, index) => (
          <li key={index}>
            <p><strong>Type:</strong> {address.type}</p>
            <p><strong>Addressee:</strong> {address.addressee}</p>
            <p><strong>Address Line 1:</strong> {address.addressLine1}</p>
            {address.addressLine2 && <p><strong>Address Line 2:</strong> {address.addressLine2}</p>}
            <p><strong>City:</strong> {address.city}</p>
            <p><strong>State:</strong> {address.state}</p>
            <p><strong>Postal Code:</strong> {address.postalCode}</p>
            <p><strong>Country:</strong> {address.country}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SupplierAddresses;
