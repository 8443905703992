import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import Modal from 'react-modal';
import GenericSearch from './GenericSearch';
import './GenerateCollectionReport.css';

Modal.setAppElement('#root'); // Add this line to ensure accessibility

function GenerateCollectionReport() {
  const [clientId, setClientId] = useState('');
  const [clientName, setClientName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [report, setReport] = useState(null);
  const [allClients, setAllClients] = useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);

  useEffect(() => {
    if (clientId) {
      axiosInstance.get(`${config.API_URL}/clients/${clientId}`)
        .then(response => {
          setClientName(response.data.name);
        })
        .catch(error => {
          console.error('Error fetching client name:', error);
        });
    }
  }, [clientId]);

  const handleClientSelect = (client) => {
    setClientId(client.id);
    setClientName(client.name);
    setIsClientModalOpen(false);
  };

  const handleGenerateReport = async () => {
    const url = allClients
      ? `${config.API_URL}/reports/generate-collection-report/all`
      : `${config.API_URL}/reports/generate-collection-report/client`;
    const data = allClients
      ? { startDate, endDate }
      : { clientId, startDate, endDate };

    try {
      const response = await axiosInstance.post(url, data);
      setReport(response.data);
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const entitySearchConfig = {
    client: {
      entity: 'clients',
      metadataEndpoint: `${config.API_URL}/metadata/clients`,
      searchEndpoint: `${config.API_URL}/clients/search`,
      title: 'Client Search',
    }
  };

  return (
    <div className="container">
      <h1>Generate Collection Report</h1>
      <div className="form-header">
        <label>
          Generate for all clients:
          <input type="checkbox" checked={allClients} onChange={() => setAllClients(!allClients)} />
        </label>
        {!allClients && (
          <div className="client-selector">
            <label>
              Client:
              <input
                type="text"
                name="clientId"
                value={clientName || ''}
                readOnly
                onClick={() => setIsClientModalOpen(true)}
                required
              />
            </label>
          </div>
        )}
        <label>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
        </label>
        <label>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
        </label>
        <button onClick={handleGenerateReport}>Generate Report</button>
      </div>

      <Modal
        isOpen={isClientModalOpen}
        onRequestClose={() => setIsClientModalOpen(false)}
        contentLabel="Client Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Client Search</h2>
            <button className="close-button" onClick={() => setIsClientModalOpen(false)}>&times;</button>
          </div>
          <div className="search-body">
            <GenericSearch config={entitySearchConfig.client} onSelectItem={handleClientSelect} closeModal={() => setIsClientModalOpen(false)} inModal={true} />
          </div>
        </div>
      </Modal>

      {report && (
        <div>
          <h2>Collection Report</h2>
          {Array.isArray(report) ? report.map(clientReport => (
            <div key={clientReport.clientId}>
              <h3>Client ID: {clientReport.clientId}</h3>
              <p>Total Amount: {clientReport.totalAmount}</p>
              <ul>
                {clientReport.invoices.map(invoice => (
                  <li key={invoice.id}>
                    Invoice ID: {invoice.id}, Amount: {invoice.amount}
                  </li>
                ))}
              </ul>
            </div>
          )) : (
            <div>
              <h3>Client ID: {report.clientId}</h3>
              <p>Total Amount: {report.totalAmount}</p>
              <ul>
                {report.invoices.map(invoice => (
                  <li key={invoice.id}>
                    Invoice ID: {invoice.id}, Amount: {invoice.amount}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GenerateCollectionReport;
