import React, { useState } from 'react';
import PaymentOptionForm from '../PaymentOptionForm';

function ClientPaymentOptionsTab({ formData, setFormData, clientId, updateClient, client, setClient }) {
  const [isEditingPaymentOptions, setIsEditingPaymentOptions] = useState(false);

  const handlePaymentOptionChange = (index, paymentOption) => {
    const newPaymentOptions = formData.paymentOptions.map((option, i) => (
      i === index ? paymentOption : option
    ));
    setFormData({
      ...formData,
      paymentOptions: newPaymentOptions
    });
  };

  const addPaymentOption = () => {
    setFormData({
      ...formData,
      paymentOptions: [...formData.paymentOptions, { type: 'Check', preferenceRank: formData.paymentOptions.length + 1 }]
    });
  };

  const removePaymentOption = (index) => {
    const newPaymentOptions = formData.paymentOptions.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      paymentOptions: newPaymentOptions
    });
  };

  const handleSavePaymentOptions = async (e) => {
    e.preventDefault();
    try {
      const updatedClient = await updateClient(clientId, { paymentOptions: formData.paymentOptions });
      setClient(updatedClient);
      setIsEditingPaymentOptions(false);
    } catch (error) {
      console.error('Error saving payment options:', error);
    }
  };

  const handleCancelPaymentOptions = () => {
    setFormData({
      ...formData,
      paymentOptions: client.paymentOptions || []
    });
    setIsEditingPaymentOptions(false);
  };

  return (
    <div>
      {isEditingPaymentOptions ? (
        <form onSubmit={handleSavePaymentOptions}>
          <h3>Payment Options</h3>
          {formData.paymentOptions.map((paymentOption, index) => (
            <PaymentOptionForm
              key={index}
              index={index}
              paymentOption={paymentOption}
              onChange={handlePaymentOptionChange}
              onRemove={removePaymentOption}
            />
          ))}
          <button type="button" onClick={addPaymentOption} className="add-contact-button">Add Payment Option</button>
          <button type="submit" className="submit-button">Save</button>
          <button type="button" className="cancel-button" onClick={handleCancelPaymentOptions}>Cancel</button>
        </form>
      ) : (
        <>
          <div className="client-payment-options">
            {formData.paymentOptions && formData.paymentOptions.map((paymentOption, index) => (
              <div key={index} className="payment-option-group">
                <p><strong>Type:</strong> {paymentOption.type}</p>
                {paymentOption.type === 'Check' && (
                  <p><strong>Payee Name:</strong> {paymentOption.payeeName}</p>
                )}
                {paymentOption.type === 'ACH' && (
                  <>
                    <p><strong>Bank Name:</strong> {paymentOption.bankName}</p>
                    <p><strong>Account Number:</strong> {paymentOption.accountNumber}</p>
                    <p><strong>Routing Number:</strong> {paymentOption.routingNumber}</p>
                  </>
                )}
                <p><strong>Preference Rank:</strong> {paymentOption.preferenceRank}</p>
              </div>
            ))}
          </div>
          <div className="client-actions">
            <button onClick={() => setIsEditingPaymentOptions(true)}>Edit</button>
          </div>
        </>
      )}
    </div>
  );
}

export default ClientPaymentOptionsTab;
