import React from 'react';
import { Link } from 'react-router-dom';
import './FeatureTemplate.css'; // Import the CSS file for styling

function FeatureTemplate({ title, description, screenshots, next, prev }) {
  return (
    <div className="feature-container">
      <h1>{title}</h1>
      <p>{description}</p>
      <div className="screenshots">
        {screenshots.map((src, index) => (
          <img key={index} src={src} alt={`${title} screenshot ${index + 1}`} />
        ))}
      </div>
      <div className="navigation">
        {prev && <Link to={prev}>Previous</Link>}
        <Link to="/">Home</Link>
        {next && <Link to={next}>Next</Link>}
      </div>
    </div>
  );
}

export default FeatureTemplate;
