import { useNavigate, useLocation } from 'react-router-dom';
import GenericSearch from './GenericSearch';
import './PurchaseOrderSearch.css';
import config from '../config';

function PurchaseOrderSearch() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clientName = searchParams.get('clientName') || '';

  const purchaseOrderSearchConfig = {
    entity: 'purchaseorders',
    metadataEndpoint: `${config.API_URL}/metadata/orders`,
    searchEndpoint: `${config.API_URL}/orders/search`,
    title: 'Purchase Order Search',
  };

  const handleSelectEntity = (entity) => {
    navigate(`/purchase-orders/${entity.id}`);
  };

  return (
    <div className="flexDiv">
      <GenericSearch
        config={purchaseOrderSearchConfig}
        onSelectItem={handleSelectEntity}
        closeModal={() => {}}
        inModal={false}
        initialFormData={{ clientName }}
      />
    </div>
  );
}

export default PurchaseOrderSearch;
