import React from 'react';
import FeatureTemplate from './FeatureTemplate';

const ClientManagement = () => {
  const title = "Client and Customer Management";
  const description = "Manage your clients and customers efficiently with our intuitive interface.";
  const screenshots = [
    // Add the paths to your screenshots here
    "/path/to/screenshot1.png",
    "/path/to/screenshot2.png"
  ];
  const next = "/features/invoice-management";
  const prev = null;

  return <FeatureTemplate title={title} description={description} screenshots={screenshots} next={next} prev={prev} />;
};

export default ClientManagement;
