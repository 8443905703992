import React, { useEffect } from 'react';
import './EditLineItems.css';

const EditLineItems = ({ selectedAccountsPayable = [], onUpdateAmount }) => {
  // Calculate the total amount as the sum of paymentAmounts or balances if paymentAmount is not defined
  const totalAmount = selectedAccountsPayable.reduce(
    (sum, ap) => sum + parseFloat(ap.paymentAmount !== undefined ? ap.paymentAmount : ap.balance || 0),
    0
  );

  useEffect(() => {
    // Initialize paymentAmount with balance for items where paymentAmount is undefined
    selectedAccountsPayable.forEach((ap) => {
      if (ap.paymentAmount === undefined) {
        onUpdateAmount(ap.id, parseFloat(ap.balance));
      }
    });
  }, [selectedAccountsPayable, onUpdateAmount]);

  // Format the total amount with $ and commas
  const formattedTotalAmount = totalAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div>
      <table className="accounts-payable-table">
        <thead>
          <tr>
            <th>Payable Source</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Balance</th>
            <th>Due Date</th>
            <th>Payment Amount</th>
          </tr>
        </thead>
        <tbody>
          {selectedAccountsPayable.map((ap) => (
            <tr key={ap.id}>
              <td>{ap.collectionReport ? `Collection Report (${ap.collectionReport.date})` : `Schedule (${ap.schedule.scheduleNumber})`}</td>
              <td>{ap.description}</td>
              <td>{ap.amount}</td>
              <td>{ap.balance}</td>
              <td>{new Date(ap.dueDate).toLocaleDateString()}</td>
              <td>
                <input
                  type="number"
                  value={ap.paymentAmount !== undefined ? ap.paymentAmount : ap.balance}
                  onChange={(e) => onUpdateAmount(ap.id, parseFloat(e.target.value))}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="total-amount">Total Payment Amount: {formattedTotalAmount}</div>
    </div>
  );
};

export default EditLineItems;
