import React, { createContext, useState, useEffect, useRef, useCallback, useContext } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { UserContext } from './UserContext';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const eventListenerRef = useRef();
  const ws = useRef(null);
  const retryTimeout = useRef(null);
  const newNotificationRef = useRef(null);

  const fetchNotifications = useCallback(async (all = false) => {
    console.log('Fetching notifications');
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found in localStorage');
      return;
    }

    try {
      const url = all ? `${config.API_URL}/notifications/all` : `${config.API_URL}/notifications`;
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const fetchedNotifications = response.data;
      setNotifications(fetchedNotifications);
      setUnreadCount(fetchedNotifications.filter(n => n && !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [setNotifications, setUnreadCount]);

  const handleNewNotification = useCallback((event) => {
    console.log('New notification:', event.detail);
    const { action, notification } = event.detail || {};
    switch (action) {
      case 'create':
        newNotificationRef.current(notification);
        fetchNotifications();
        break;
      case 'delete':
        fetchNotifications();
        break;
      default:
        console.log('Unknown action:', action);
    }
  }, [fetchNotifications]);

  const establishWebSocketConnection = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!ws.current && token) {
      console.log('Establishing WebSocket connection');
      const wsURL = `${config.API_URL.replace(/^http/, 'ws')}/ws?token=${token}`;
      ws.current = new WebSocket(wsURL);

      ws.current.onopen = () => {
        console.log('WebSocket connection established');
        if (retryTimeout.current) {
          clearTimeout(retryTimeout.current);
          retryTimeout.current = null;
          fetchNotifications();
        }
      };

      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'notification') {
          window.dispatchEvent(new CustomEvent('new-notification', { detail: message }));
        } else if (message.type === 'notification-update') {
          setUnreadCount(message.unreadCount);
        }
      };

      ws.current.onclose = () => {
        console.log('WebSocket connection closed');
        ws.current = null;
        if (!retryTimeout.current) {
          retryTimeout.current = setTimeout(() => {
            establishWebSocketConnection();
          }, 5000);
        }
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.current.close();
      };
    }
  }, [fetchNotifications]);

  useEffect(() => {
    console.log('NotificationContext useEffect triggered');
    if (user) {
      console.log('User logged in, attempting to establish WebSocket connection');
      establishWebSocketConnection();
    } else {
      console.log('No user logged in, skipping WebSocket connection');
    }

    return () => {
      if (ws.current) {
        console.log('Cleaning up WebSocket connection');
        ws.current.close();
      }
      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current);
      }
    };
  }, [user, establishWebSocketConnection]);

  useEffect(() => {
    eventListenerRef.current = handleNewNotification;
    window.addEventListener('new-notification', eventListenerRef.current);

    return () => {
      if (eventListenerRef.current) {
        window.removeEventListener('new-notification', eventListenerRef.current);
      }
    };
  }, [handleNewNotification]);

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user, fetchNotifications]);

  const setNewNotificationHandler = (handler) => {
    newNotificationRef.current = handler;
  };

  // Adding the sendNotification function
  const sendNotification = async ({ message, userId, role, public: isPublic }) => {
    try {
      const token = localStorage.getItem("token");
      await axiosInstance.post(
        `${config.API_URL}/notifications`,
        {
          message,
          userId: userId || null,
          role: role || null,
          public: isPublic || false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchNotifications(); // Optionally refetch notifications after sending one
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  return (
    <NotificationContext.Provider value={{ 
      notifications, 
      unreadCount, 
      setNotifications, 
      setUnreadCount, 
      fetchNotifications, 
      setNewNotificationHandler, 
      establishWebSocketConnection,
      sendNotification // Provide the sendNotification function to the context
    }}>
      {children}
    </NotificationContext.Provider>
  );
};
