import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import './FinancialStatements.css'; // Ensure you import the CSS file

const FinancialStatement = () => {
    const [endDate, setEndDate] = useState(new Date('2023-12-31'));
    const [statementType, setStatementType] = useState('balance-sheet');
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleGenerate = async () => {
        setLoading(true);
        setError(null);
        setResult(null);

        try {
            const response = await axiosInstance.get(`${config.API_URL}/financials/${statementType}`, {
                params: {
                    endDate: endDate.toISOString().split('T')[0]
                }
            });
            console.log('API Response:', response.data); // Debugging log
            setResult(response.data);
        } catch (err) {
            console.log('Error:', err); // Debugging log
            setError(err.response?.data?.error || 'An error occurred while generating the financial statement.');
        } finally {
            setLoading(false);
        }
    };

    const processDataForCSV = (data) => {
        const rows = [];

        const processEntries = (category1, category2, category3, accountType, accountData) => {
            const row = { category1, category2, category3, accountType, accountPrefix: accountData.accountPrefix };

            for (const year in accountData.years) {
                row[`totalDebit_${year}`] = accountData.years[year].totalDebit || 0;
                row[`totalCredit_${year}`] = accountData.years[year].totalCredit || 0;
                row[`netBalance_${year}`] = accountData.years[year].netBalance || 0;
            }

            rows.push(row);
        };

        for (const category1 in data) {
            for (const category2 in data[category1]) {
                for (const category3 in data[category1][category2]) {
                    for (const accountType in data[category1][category2][category3]) {
                        processEntries(
                            category1,
                            category2,
                            category3,
                            accountType,
                            data[category1][category2][category3][accountType]
                        );
                    }
                }
            }
        }

        return rows;
    };

    const renderEntriesWithSubtotal = (entries, label) => {
        const years = Object.keys(entries[Object.keys(entries)[0]].years); // Get the list of years from the first entry

        const subtotals = years.reduce((acc, year) => {
            acc[year] = entries.reduce((sum, entry) => sum + (entry.years[year]?.netBalance || 0), 0);
            return acc;
        }, {});

        return (
            <>
                <table className="nested-table">
                    <thead>
                        <tr>
                            <th>Account Prefix</th>
                            <th>Account Type</th>
                            {years.map(year => (
                                <th key={year}>Net Balance {year}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {entries.map((entry, index) => (
                            <tr key={index}>
                                <td>{entry.accountPrefix}</td>
                                <td>{entry.accountType}</td>
                                {years.map(year => (
                                    <td key={year}>{parseFloat(entry.years[year]?.netBalance || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                ))}
                            </tr>
                        ))}
                        <tr className="subtotal-row">
                            <td colSpan="2"><strong>{label}</strong></td>
                            {years.map(year => (
                                <td key={year}><strong>{subtotals[year].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </>
        );
    };

    const renderCategory3 = (category3Data) => {
        return Object.keys(category3Data).map((category3, index) => {
            const entries = Object.values(category3Data[category3]);
            return (
                <div key={index} className="nested-category">
                    <h4>{category3}</h4>
                    {renderEntriesWithSubtotal(entries, `Total ${category3}`)}
                </div>
            );
        });
    };

    const renderCategory2 = (category2Data) => {
        return Object.keys(category2Data).map((category2, index) => {
            const category3Components = renderCategory3(category2Data[category2]);
            const flattenedEntries = Object.values(category2Data[category2]).flatMap(category3 => Object.values(category3));
            const years = Object.keys(flattenedEntries[0]?.years || {});
            const subtotals = years.reduce((acc, year) => {
                acc[year] = flattenedEntries.reduce((sum, entry) => sum + (entry.years[year]?.netBalance || 0), 0);
                return acc;
            }, {});

            return (
                <div key={index} className="nested-category">
                    <h3>{category2}</h3>
                    {category3Components}
                    <table className="nested-table">
                        <tbody>
                            <tr className="subtotal-row">
                                <td colSpan="2"><strong>Total {category2}</strong></td>
                                {years.map(year => (
                                    <td key={year}><strong>{subtotals[year].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        });
    };

    const renderCategory1 = (category1Data) => {
        return Object.keys(category1Data).map((category1, index) => {
            const category2Components = renderCategory2(category1Data[category1]);
            const flattenedEntries = Object.values(category1Data[category1]).flatMap(category2 => Object.values(category2).flatMap(category3 => Object.values(category3)));
            const years = Object.keys(flattenedEntries[0]?.years || {});
            const subtotals = years.reduce((acc, year) => {
                acc[year] = flattenedEntries.reduce((sum, entry) => sum + (entry.years[year]?.netBalance || 0), 0);
                return acc;
            }, {});

            return (
                <div key={index} className="nested-category">
                    <h2>{category1}</h2>
                    {category2Components}
                    <table className="nested-table">
                        <tbody>
                            <tr className="subtotal-row">
                                <td colSpan="2"><strong>Total {category1}</strong></td>
                                {years.map(year => (
                                    <td key={year}><strong>{subtotals[year].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        });
    };

    const renderNestedTables = (data) => {
        return renderCategory1(data);
    };

    return (
        <div>
            <h2>Generate Financial Statement</h2>
            <div>
                <label>
                    End Date:
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                </label>
            </div>
            <div>
                <label>
                    Statement Type:
                    <select value={statementType} onChange={(e) => setStatementType(e.target.value)}>
                        <option value="balance-sheet">Balance Sheet</option>
                        <option value="income-statement">Income Statement</option>
                    </select>
                </label>
            </div>
            <button onClick={handleGenerate} disabled={loading}>
                {loading ? 'Generating...' : 'Generate'}
            </button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {result && (
                <div>
                    <h3>Result</h3>
                    {renderNestedTables(result.balanceSheet)}
                    <CSVLink
                        data={processDataForCSV(result.balanceSheet)}
                        filename="financial_statement.csv"
                        className="btn btn-primary"
                        style={{ marginTop: '20px', display: 'inline-block' }}
                    >
                        Export to CSV
                    </CSVLink>
                </div>
            )}
        </div>
    );
};

export default FinancialStatement;
