// src/components/PaymentsReceived.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function PaymentsReceived() {
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchPaymentsReceived() {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/reports/payments-received`);
        setPayments(response.data);
      } catch (err) {
        console.error('Error fetching payments received:', err);
        setError('Error fetching payments received. Please try again later.');
      }
    }
    fetchPaymentsReceived();
  }, []);

  return (
    <div>
      <h1>Payments Received</h1>
      {error && <p>{error}</p>}
      <ul>
        {payments.map(payment => (
          <li key={payment.id}>
            {payment.amount} - {payment.date}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PaymentsReceived;
