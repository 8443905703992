// SupplierMetadataTab.js
import React from 'react';

function SupplierMetadataTab({ metadata }) {
  return (
    <div className="metadata-section">
      <h2>Metadata</h2>
      {metadata ? (
        <div className="metadata-content">
          {Object.entries(metadata).map(([key, value]) => (
            <p key={key}><strong>{key}:</strong> {value}</p>
          ))}
        </div>
      ) : (
        <p>No metadata available.</p>
      )}
    </div>
  );
}

export default SupplierMetadataTab;
