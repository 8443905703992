// SupplierSearch.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericSearch from './GenericSearch';
import entitySearchConfig from '../entitySearchConfig';

function AccountsPayableSearch() {
  const navigate = useNavigate();

  const handleSelectEntity = (entity) => {
    navigate(`/accounts-payable/${entity.id}`);
  };

  return (
    <div className="flexDiv">
      <GenericSearch
        config={entitySearchConfig.accountsPayable}
        onSelectItem={handleSelectEntity}
        closeModal={() => {}} // No modal, so this can be empty or no-op
        inModal={false}
      />
    </div>
  );
}

export default AccountsPayableSearch;
