import React from 'react';
import FeatureTemplate from './FeatureTemplate';

const Transactions = () => {
  const title = "Deposits and Transactions";
  const description = "Track deposits and transactions effortlessly.";
  const screenshots = [
    // Add the paths to your screenshots here
    "/path/to/screenshot1.png",
    "/path/to/screenshot2.png"
  ];
  const next = "/features/reports";
  const prev = "/features/invoice-management";

  return <FeatureTemplate title={title} description={description} screenshots={screenshots} next={next} prev={prev} />;
};

export default Transactions;
