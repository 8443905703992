// src/axiosConfig.js
import axios from 'axios';
import config from './config';

const axiosInstance = axios.create({
  baseURL: config.API_URL,
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    // Check for new token in response headers and update local storage if present
    // console.log('AXIOS Interceptor - Response Headers:', response.headers);
    const newToken = response.headers['x-new-token'];
    if (newToken) {
      // console.log('AXIOS Interceptor: x-new-token found, updating token.');
      localStorage.setItem('token', newToken);
    } else {
      // console.log('AXIOS Interceptor: No x-new-token found.');
    }
    return response;
  },
  async error => {
    const originalRequest = error.config;

    // Handle token expiration
    if (error.response && error.response.status === 401 && error.response.data.message === 'Token expired.') {
      // Remove token from localStorage
      console.log('AXIOS Interceptor: Token expired, redirecting to login.');
      localStorage.removeItem('token');

      // Redirect to /login
      window.location.href = '/login';
      return Promise.reject(error);
    }

    // Handle token refresh
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const token = localStorage.getItem('token');
        if (token) {
          console.log('AXIOS Interceptor: Attempting to refresh token.');
          const refreshTokenResponse = await axiosInstance.post('/auth/refresh-token', null, {
            headers: { 'Authorization': `Bearer ${token}` }
          });

          if (refreshTokenResponse.status === 200) {
            const newToken = refreshTokenResponse.data.token;
            console.log('AXIOS Interceptor: Token refreshed successfully.');
            localStorage.setItem('token', newToken);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            return axiosInstance(originalRequest);
          }
        }
      } catch (err) {
        console.error('AXIOS Interceptor: Failed to refresh token, redirecting to login.', err);
        // Redirect to login if refresh fails
        window.location.href = '/login';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
