import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { UserContext } from '../context/UserContext';
import { CSVLink } from 'react-csv';

function Reports() {
  const { user } = useContext(UserContext);
  const [reportData, setReportData] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    async function fetchReportData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/invoices`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setReportData(response.data);
        setFilteredData(response.data);
        console.log('Fetched Data:', response.data);
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    }

    if (user) {
      fetchReportData();
    }
  }, [user]);

  const handleFilterChange = (e) => {
    const filterValue = e.target.value;
    setFilter(filterValue);

    const filtered = reportData.filter(item => {
      const clientName = item.client ? item.client.name : ''; // Access nested client name
      return clientName.toLowerCase().includes(filterValue.toLowerCase());
    });

    setFilteredData(filtered);
    console.log('Filter:', filterValue);
    console.log('Filtered Data:', filtered);
  };

  return (
    <div>
      <h2>Reports</h2>
      <div>
        <label>Filter by Client Name:</label>
        <input 
          type="text" 
          value={filter} 
          onChange={handleFilterChange} 
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Client Name</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.client ? item.client.name : ''}</td>
                <td>{item.amount}</td>
                <td>{new Date(item.createdAt).toLocaleDateString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No results found</td>
            </tr>
          )}
        </tbody>
      </table>
      <CSVLink data={filteredData} filename={"report.csv"}>
        Export to CSV
      </CSVLink>
    </div>
  );
}

export default Reports;
