import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import config from '../config';

function ApproveOrder() {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [formData, setFormData] = useState({
    creditLimit: '',
    riskRating: '',
    nonFactored: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/orders/${orderId}`);
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrder();
  }, [orderId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`${config.API_URL}/orders/${orderId}/approve`, formData);
      navigate('/orders');
    } catch (error) {
      console.error('Error approving order:', error);
    }
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Approve Order</h1>
      <p><strong>PO#:</strong> {order.poNumber}</p>
      <p><strong>Amount:</strong> {order.amount}</p>
      <p><strong>Date:</strong> {new Date(order.date).toLocaleDateString()}</p>
      <p><strong>Customer:</strong> {order.customer.name}</p>
      <form onSubmit={handleSubmit}>
        <label>
          Credit Limit:
          <input
            type="number"
            name="creditLimit"
            value={formData.creditLimit}
            onChange={handleChange}
            disabled={formData.nonFactored}
            required={!formData.nonFactored}
          />
        </label>
        <label>
          Risk Rating:
          <input
            type="text"
            name="riskRating"
            value={formData.riskRating}
            onChange={handleChange}
            disabled={formData.nonFactored}
            required={!formData.nonFactored}
          />
        </label>
        <label>
          Non-Factored:
          <input
            type="checkbox"
            name="nonFactored"
            checked={formData.nonFactored}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Approve</button>
      </form>
    </div>
  );
}

export default ApproveOrder;
