import React, { useState } from "react";
import GenericSearch from "./GenericSearch";
import Modal from "react-modal";
import moment from "moment";
import "./PayrollInput.css"; // Add similar CSS for styling

Modal.setAppElement("#root");

function PayrollInput() {
  const [formData, setFormData] = useState({
    date: moment().format("YYYY-MM-DD"),
    ssPercent: 6.2,
    medicarePercent: 1.45,
    fitPercent: 20.0,
    retirementPercent: 3.0,
    items: [
      {
        employeeId: "",
        employeeName: "",
        gross: 0,
        ss: 0,
        medicare: 0,
        fit: 0,
        retirement: 0,
        loan: 0,
        netCheck: 0,
      },
    ],
  });

  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const entitySearchConfig = {
    employee: {
      entity: "employees",
      metadataEndpoint: `/metadata/employees`,
      searchEndpoint: `/employees/search`,
      title: "Employee Search",
    },
  };

  const openEmployeeModal = () => {
    setIsEmployeeModalOpen(true);
  };

  const closeEmployeeModal = () => {
    setIsEmployeeModalOpen(false);
  };

  const handleEmployeeSelect = (employee) => {
    if (
      currentItemIndex !== null &&
      currentItemIndex >= 0 &&
      currentItemIndex < formData.items.length
    ) {
      const updatedItems = [...formData.items];
      updatedItems[currentItemIndex] = {
        ...updatedItems[currentItemIndex],
        employeeId: employee.id,
        employeeName: employee.name,
      };
      setFormData({ ...formData, items: updatedItems });
      setIsEmployeeModalOpen(false);
    }
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...formData.items];
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: parseFloat(value) || 0,
    };

    // Automatically calculate percentage fields based on gross
    if (name === "gross") {
      updatedItems[index].ss =
        (updatedItems[index].gross * formData.ssPercent) / 100;
      updatedItems[index].medicare =
        (updatedItems[index].gross * formData.medicarePercent) / 100;
      updatedItems[index].fit =
        (updatedItems[index].gross * formData.fitPercent) / 100;
      updatedItems[index].retirement =
        (updatedItems[index].gross * formData.retirementPercent) / 100;
    }

    // Recalculate net check
    updatedItems[index].netCheck =
      updatedItems[index].gross -
      updatedItems[index].ss -
      updatedItems[index].medicare -
      updatedItems[index].fit -
      updatedItems[index].retirement -
      updatedItems[index].loan;

    setFormData({ ...formData, items: updatedItems });
  };

  const handlePercentageChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: parseFloat(value) || 0 });
  };

  const calculateTotals = () => {
    const totalGross = formData.items.reduce((acc, row) => acc + row.gross, 0);
    const totalSS = formData.items.reduce((acc, row) => acc + row.ss, 0);
    const totalMedicare = formData.items.reduce(
      (acc, row) => acc + row.medicare,
      0
    );
    const totalFIT = formData.items.reduce((acc, row) => acc + row.fit, 0);
    const totalRetirement = formData.items.reduce(
      (acc, row) => acc + row.retirement,
      0
    );
    const totalLoan = formData.items.reduce((acc, row) => acc + row.loan, 0);
    const totalNetCheck = formData.items.reduce(
      (acc, row) => acc + row.netCheck,
      0
    );

    return {
      totalGross,
      totalSS,
      totalMedicare,
      totalFIT,
      totalRetirement,
      totalLoan,
      totalNetCheck,
    };
  };

  const addItem = () => {
    setFormData({
      ...formData,
      items: [
        ...formData.items,
        {
          employeeId: "",
          employeeName: "",
          gross: 0,
          ss: 0,
          medicare: 0,
          fit: 0,
          retirement: 0,
          loan: 0,
          netCheck: 0,
        },
      ],
    });
  };

  const removeItem = (index) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    setFormData({ ...formData, items: updatedItems });
  };

  const totals = calculateTotals();

  return (
    <div className="flexDiv">
      <form>
        <h1>Payroll Input Screen</h1>
        <div className="form-header">
          <div>
            <label>Date:</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
            />
          </div>
        </div>

        <div className="table-container">
          <table>
            {/* Row for percentage fields */}
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>
                  <input
                    type="number"
                    name="ssPercent"
                    value={formData.ssPercent}
                    onChange={handlePercentageChange}
                  />
                </th>
                <th>
                  <input
                    type="number"
                    name="medicarePercent"
                    value={formData.medicarePercent}
                    onChange={handlePercentageChange}
                  />
                </th>
                <th>
                  <input
                    type="number"
                    name="fitPercent"
                    value={formData.fitPercent}
                    onChange={handlePercentageChange}
                  />
                </th>
                <th>
                  <input
                    type="number"
                    name="retirementPercent"
                    value={formData.retirementPercent}
                    onChange={handlePercentageChange}
                  />
                </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>Employee</th>
                <th>Gross</th>
                <th>SS</th>
                <th>Medicare</th>
                <th>FIT</th>
                <th>401k/SIMPLE</th>
                <th>Loan Acct Pmt</th>
                <th>Net Check Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {formData.items.map((item, index) => (
                <tr
                  key={index}
                  onFocus={() => setCurrentItemIndex(index)}
                  onClick={() => setCurrentItemIndex(index)}
                >
                  <td>
                    <GenericSearch
                      config={entitySearchConfig.employee}
                      onSelectItem={(employee) => {
                        handleEmployeeSelect(employee);
                      }}
                      inlineSearch={true}
                      selectedValue={item.employeeName}
                      openModal={openEmployeeModal}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="gross"
                      value={item.gross}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="ss"
                      value={item.ss}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="medicare"
                      value={item.medicare}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="fit"
                      value={item.fit}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="retirement"
                      value={item.retirement}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="loan"
                      value={item.loan}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </td>
                  <td>{item.netCheck.toFixed(2)}</td>
                  <td>
                    <button type="button" onClick={() => removeItem(index)}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{totals.totalGross.toFixed(2)}</td>
                <td>{totals.totalSS.toFixed(2)}</td>
                <td>{totals.totalMedicare.toFixed(2)}</td>
                <td>{totals.totalFIT.toFixed(2)}</td>
                <td>{totals.totalRetirement.toFixed(2)}</td>
                <td>{totals.totalLoan.toFixed(2)}</td>
                <td>{totals.totalNetCheck.toFixed(2)}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        
        <div className="fixed-form-buttons">
          {/* <button type="button" onClick={copyLastRow}>Copy Last Row</button> */}
          <button type="button" onClick={addItem}>Add Employee</button>
          {/* <button type="submit">Save</button> */}
        </div>
      </form>
      {/* Employee Search Modal */}
      <Modal
        isOpen={isEmployeeModalOpen}
        onRequestClose={closeEmployeeModal}
        contentLabel="Employee Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Employee Search</h2>
            <button className="close-button" onClick={closeEmployeeModal}>
              &times;
            </button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={entitySearchConfig.employee}
              onSelectItem={handleEmployeeSelect}
              closeModal={closeEmployeeModal}
              inModal={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PayrollInput;
