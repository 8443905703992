import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCustomer, fetchMetadata, fetchStatements, updateCustomer, updateCustomerDetails } from '../utils/apiCustomer';
import { useUserContext } from '../../context/UserContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './CustomerDetails.css';
import CustomerDetailsTab from './CustomerDetailsTab';
import CustomerContactsTab from './CustomerContactsTab';
import CustomerPaymentOptionsTab from './CustomerPaymentOptionsTab';
import CustomerNotesTab from './CustomerNotesTab';
import CustomerClientsTab from './CustomerClientsTab';
import CustomerDocumentsTab from './CustomerDocumentsTab';
import CustomerMetadataTab from './CustomerMetadataTab';
import CustomerAddressesTab from './CustomerAddressesTab';

function CustomerDetails() {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const { user } = useUserContext();
  const [metadata, setMetadata] = useState(null);
  const [statement, setStatement] = useState([]); // Initialize statement as an empty array

  useEffect(() => {
    const initializeData = async () => {
      try {
        const customerData = await fetchCustomer(customerId);
        setCustomer({
          ...customerData,
          addresses: customerData.addresses || [],  // Initialize addresses
          contacts: customerData.contacts || [], // Initialize contacts as an empty array if not present
          paymentOptions: customerData.paymentOptions || [], // Initialize paymentOptions as an empty array if not present
          customerNotes: customerData.customerNotes || [], // Initialize customerNotes as an empty array if not present
          clients: customerData.clients || [], // Initialize clients as an empty array if not present
          newNote: { message: '' } // Initialize newNote
        });

        const metadataData = await fetchMetadata(customerId);
        setMetadata(metadataData);

        const statementData = await fetchStatements(customerId);
        setStatement(statementData);
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    };

    initializeData();
  }, [customerId]);

  if (!customer) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customer-details-container">
      <h1>Customer Details</h1>
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Addresses</Tab>
          <Tab>Contacts</Tab>
          <Tab>Payment Options</Tab>
          <Tab>Notes</Tab>
          <Tab>Clients</Tab>
          <Tab>Documents</Tab>
          <Tab>Metadata</Tab>
        </TabList>

        <TabPanel>
          <CustomerDetailsTab
            customer={customer}
            customerId={customerId}
            setCustomer={setCustomer} // Pass the setCustomer function
          />
        </TabPanel>

        <TabPanel>
          <CustomerAddressesTab
            formData={customer}
            setFormData={setCustomer}
            customerId={customerId}
            updateCustomer={updateCustomer}
          />
        </TabPanel>

        <TabPanel>
          <CustomerContactsTab
            formData={customer.contacts}
            setFormData={(updatedContacts) => setCustomer(prevCustomer => ({
              ...prevCustomer,
              contacts: updatedContacts
            }))}
            customerId={customerId}
            updateCustomer={updateCustomerDetails}
            customer={customer}
          />
        </TabPanel>

        <TabPanel>
          <CustomerPaymentOptionsTab
            formData={customer.paymentOptions}
            setFormData={(updatedPaymentOptions) => setCustomer(prevCustomer => ({
              ...prevCustomer,
              paymentOptions: updatedPaymentOptions
            }))}
            customerId={customerId}
            updateCustomer={updateCustomerDetails}
            customer={customer}
          />
        </TabPanel>

        <TabPanel>
          <CustomerNotesTab
            customerId={customerId}
            user={user}
            formData={customer}
            setFormData={setCustomer}
          />
        </TabPanel>

        <TabPanel>
          <CustomerClientsTab
            formData={customer.clients}
          />
        </TabPanel>

        <TabPanel>
          <CustomerDocumentsTab
            customerId={customerId}
            user={user}
            statement={statement} // Pass the statement state
          />
        </TabPanel>

        <TabPanel>
          <CustomerMetadataTab
            metadata={metadata}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default CustomerDetails;
