import React from 'react';
import './SelectPayeeType.css';

const SelectPayeeType = ({ onSelectPayeeType }) => {
  const handleSelect = (type) => {
    onSelectPayeeType(type);
  };

  return (
    <div className="select-payee-type-container">
      <h2>Select Payee Type</h2>
      <div className="button-group">
        <button className="payee-type-button" onClick={() => handleSelect('Client')}>Client</button>
        <button className="payee-type-button" onClick={() => handleSelect('Customer')}>Customer</button>
        <button className="payee-type-button" onClick={() => handleSelect('Supplier')}>Supplier</button>
      </div>
    </div>
  );
};

export default SelectPayeeType;
