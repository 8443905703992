import config from './config';

const entitySearchConfig = {
  client: {
    entity: 'clients',
    metadataEndpoint: `${config.API_URL}/metadata/clients`,
    searchEndpoint: `${config.API_URL}/clients/search`,
    title: 'Client Search',
  },
  customer: {
    entity: 'customers',
    metadataEndpoint: `${config.API_URL}/metadata/customers`,
    searchEndpoint: `${config.API_URL}/customers/search`,
    title: 'Customer Search'
  },
  accountsPayable: {
    entity: 'accounts-payable',
    metadataEndpoint: `${config.API_URL}/metadata/accounts-payable`,
    searchEndpoint: `${config.API_URL}/accounts-payable/search`,
    title: 'Accounts Payable'
  },
  supplier: {
    entity: 'suppliers',
    metadataEndpoint: `${config.API_URL}/metadata/suppliers`,
    searchEndpoint: `${config.API_URL}/suppliers/search`,
    title: 'Supplier Search',
  },
  user: {
    entity: 'users',
    metadataEndpoint: `${config.API_URL}/metadata/users`,
    searchEndpoint: `${config.API_URL}/users/search`,
    title: 'User Search',
  }
};

export default entitySearchConfig;
