import React from 'react';

function ClientContacts({ contacts }) {
  return (
    <div>
      <h2>Contacts</h2>
      <ul>
        {contacts.map(contact => (
          <li key={contact.id}>
            <p><strong>Name:</strong> {contact.name}</p>
            <p><strong>Phone:</strong> {contact.phoneNumber}</p>
            <p><strong>Email:</strong> {contact.email}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ClientContacts;
