import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function JournalEntriesList() {
  const [journalEntries, setJournalEntries] = useState([]);

  useEffect(() => {
    const fetchJournalEntries = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/journal-entries`);
        setJournalEntries(response.data);
      } catch (error) {
        console.error('Error fetching journal entries:', error);
      }
    };

    fetchJournalEntries();
  }, []);

  return (
    <div>
      <h1>Journal Entries</h1>
      <ul>
        {journalEntries.map(entry => (
          <li key={entry.id}>
            {entry.date} - {entry.description} - Debit: {entry.debitAccount} - Credit: {entry.creditAccount} - Amount: {entry.amount}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default JournalEntriesList;
