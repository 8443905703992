// apiSupplier.js
import axiosInstance from '../../axiosConfig';
import config from '../../config';

export const fetchSupplier = async (supplierId) => {
  const response = await axiosInstance.get(`${config.API_URL}/suppliers/${supplierId}`);
  return response.data;
};

export const updateSupplier = async (supplierId, formData) => {
  const response = await axiosInstance.put(`${config.API_URL}/suppliers/${supplierId}`, formData);
  return response.data;
};

export const fetchSupplierMetadata = async (supplierId) => {
  try {
    const response = await axiosInstance.get(`${config.API_URL}/suppliers/${supplierId}/metadata`);
    return response.data;
  } catch (error) {
    console.error('Error fetching metadata:', error);
    throw error;
  }
};

export const fetchSupplierContacts = async (supplierId) => {
  const response = await axiosInstance.get(`${config.API_URL}/suppliers/${supplierId}/contacts`);
  return response.data;
};

export const addSupplierContact = async (supplierId, contact) => {
  const response = await axiosInstance.post(`${config.API_URL}/suppliers/${supplierId}/contacts`, contact);
  return response.data;
};

export const updateSupplierContact = async (contactId, contact) => {
  const response = await axiosInstance.put(`${config.API_URL}/suppliers/contacts/${contactId}`, contact);
  return response.data;
};

export const deleteSupplierContact = async (contactId) => {
  const response = await axiosInstance.delete(`${config.API_URL}/suppliers/contacts/${contactId}`);
  return response.data;
};

export const addSupplierNote = async (supplierId, note, user) => {
  try {
    if (!user || !user.username) {
      throw new Error('User is not logged in or username is missing');
    }
    const response = await axiosInstance.post(`${config.API_URL}/suppliers/${supplierId}/notes`, {
      message: note.message,
      author: user.username
    });
    return response.data; // Return the added note
  } catch (error) {
    console.error('Error adding note:', error);
    throw error;
  }
};

export const deleteSupplierNote = async (noteId) => {
  const response = await axiosInstance.delete(`${config.API_URL}/suppliers/notes/${noteId}`);
  return response.data;
};

export const fetchSupplierDocuments = async (supplierId) => {
  const response = await axiosInstance.get(`${config.API_URL}/suppliers/${supplierId}/documents`);
  return response.data;
};

export const downloadSupplierDocument = async (supplierId, documentId, documentName) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${config.API_URL}/suppliers/${supplierId}/documents/${documentId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
  });

  // Create a URL for the file blob and trigger a download
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName); // Specify the filename
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const deleteSupplierDocument = async (supplierId, documentId) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.delete(`${config.API_URL}/suppliers/${supplierId}/documents/${documentId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};
