// src/components/FAQ.js
import React from 'react';
import './FAQ.css'; // Import the CSS file for styling

function FAQ() {
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      
      <div className="question">
        <h2>Q1: How do I register for an account?</h2>
        <p>A: You can register by clicking on the Register link on the homepage and providing the required details.</p>
      </div>

      <div className="question">
        <h2>Q2: How long does it take to verify my account?</h2>
        <p>A: Account verification typically takes 1-2 business days. You will receive an email notification once your account is verified.</p>
      </div>

      <div className="question">
        <h2>Q3: How can I reset my password?</h2>
        <p>A: To reset your password, click on the Forgot Password link on the login page and follow the instructions.</p>
      </div>

      <div className="question">
        <h2>Q4: How do I contact support?</h2>
        <p>A: You can contact support by visiting the Support section or emailing us at support@factoringapp.com.</p>
      </div>

      <div className="question">
        <h2>Q5: How do I add a new client?</h2>
        <p>A: Navigate to the Clients section and click on Add Client. Fill in the necessary details and save the client information.</p>
      </div>
    </div>
  );
}

export default FAQ;
