import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

function AddInvoice() {
  const [formData, setFormData] = useState({
    clientId: '',
    customerId: '',
    amount: '',
    status: '',
    description: '',
    dueDate: '',
    paymentTerms: 'Net 30'
  });
  const [clients, setClients] = useState([]);
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchClientsAndCustomers() {
      try {
        const [clientsResponse, customersResponse] = await Promise.all([
          axiosInstance.get(`${config.API_URL}/clients`),
          axiosInstance.get(`${config.API_URL}/customers`)
        ]);
        setClients(clientsResponse.data);
        setCustomers(customersResponse.data);
      } catch (err) {
        console.error('Error fetching clients and customers:', err);
      }
    }
    fetchClientsAndCustomers();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption ? selectedOption.value : ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`${config.API_URL}/invoices`, formData);
      navigate('/invoices');
    } catch (error) {
      console.error('Error adding invoice:', error);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '34px',
      height: '34px',
      boxShadow: 'none'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '34px',
      padding: '0 6px'
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
  };

  return (
    <form onSubmit={handleSubmit}>
      <Select
        name="clientId"
        value={clients.find(client => client.id === formData.clientId) ? { label: clients.find(client => client.id === formData.clientId).name, value: formData.clientId } : null}
        onChange={handleSelectChange}
        options={clients.map(client => ({ label: client.name, value: client.id }))}
        isClearable
        placeholder="Select Client"
        styles={customStyles}
      />
      <Select
        name="customerId"
        value={customers.find(customer => customer.id === formData.customerId) ? { label: customers.find(customer => customer.id === formData.customerId).name, value: formData.customerId } : null}
        onChange={handleSelectChange}
        options={customers.map(customer => ({ label: customer.name, value: customer.id }))}
        isClearable
        placeholder="Select Customer"
        styles={customStyles}
      />
      <input type="number" name="amount" onChange={handleChange} placeholder="Amount" required />
      <input type="text" name="status" onChange={handleChange} placeholder="Status" required />
      <input type="text" name="description" onChange={handleChange} placeholder="Description" />
      <input type="date" name="dueDate" onChange={handleChange} placeholder="Due Date" required />
      <Select
        name="paymentTerms"
        value={{ label: formData.paymentTerms, value: formData.paymentTerms }}
        onChange={handleSelectChange}
        options={[
          { label: 'Net 30', value: 'Net 30' },
          { label: 'Net 60', value: 'Net 60' },
          { label: 'Net 90', value: 'Net 90' },
        ]}
        placeholder="Select Payment Terms"
        styles={customStyles}
      />
      <button type="submit">Add Invoice</button>
    </form>
  );
}

export default AddInvoice;
