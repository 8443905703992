import React from 'react';
import FeatureTemplate from './FeatureTemplate';

const CallCenter = () => {
  const title = "Call Customer Center";
  const description = "Manage customer calls and support.";
  const screenshots = [
    // Add the paths to your screenshots here
    "/path/to/screenshot1.png",
    "/path/to/screenshot2.png"
  ];
  const next = null;
  const prev = "/features/order-management";

  return <FeatureTemplate title={title} description={description} screenshots={screenshots} next={next} prev={prev} />;
};

export default CallCenter;
