import React from 'react';
import CustomerClients from './CustomerClients';

function CustomerClientsTab({ formData }) {
  return (
    <div>
      <h2>Customer Clients</h2>
      <CustomerClients clients={formData || []} /> {/* Initialize clients as an empty array if not present */}
    </div>
  );
}

export default CustomerClientsTab;
