import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function EnterDeposit() {
  const [date, setDate] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [payments, setPayments] = useState([{ invoiceId: '', amount: '', closeInvoice: false }]);
  const [customers, setCustomers] = useState([]);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/customers`);
        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  const handleAddPayment = () => {
    setPayments([...payments, { invoiceId: '', amount: '', closeInvoice: false }]);
  };

  const handlePaymentChange = (index, field, value) => {
    const newPayments = [...payments];
    newPayments[index][field] = value;
    setPayments(newPayments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        date,
        customerId,
        payments,
      };
      await axiosInstance.post(`${config.API_URL}/deposits`, payload);
      // Handle success - reset form, show message, etc.
    } catch (error) {
      console.error('Error creating deposit:', error);
    }
  };

  const handleCustomerChange = async (e) => {
    const customerId = e.target.value;
    setCustomerId(customerId);

    // Fetch invoices for the selected customer
    try {
      const response = await axiosInstance.get(`${config.API_URL}/invoices?customerId=${customerId}`);
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Date</label>
      <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
      
      <label>Customer</label>
      <select value={customerId} onChange={handleCustomerChange} required>
        <option value="">Select a customer</option>
        {customers.map(customer => (
          <option key={customer.id} value={customer.id}>{customer.name}</option>
        ))}
      </select>
      
      <h3>Payments</h3>
      {payments.map((payment, index) => (
        <div key={index}>
          <label>Invoice</label>
          <select 
            value={payment.invoiceId} 
            onChange={(e) => handlePaymentChange(index, 'invoiceId', e.target.value)} 
            required
          >
            <option value="">Select an invoice</option>
            {invoices.map(invoice => (
              <option key={invoice.id} value={invoice.id}>{invoice.invoiceNumber}</option>
            ))}
          </select>
          
          <label>Amount</label>
          <input 
            type="number" 
            value={payment.amount} 
            onChange={(e) => handlePaymentChange(index, 'amount', e.target.value)} 
            required 
          />
          
          <label>Close Invoice</label>
          <input 
            type="checkbox" 
            checked={payment.closeInvoice} 
            onChange={(e) => handlePaymentChange(index, 'closeInvoice', e.target.checked)} 
          />
        </div>
      ))}
      <button type="button" onClick={handleAddPayment}>Add Payment</button>
      <button type="submit">Submit</button>
    </form>
  );
}

export default EnterDeposit;
