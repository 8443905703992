// src/components/CustomerDetails/CustomerPaymentOptionsTab.js
import React, { useState } from 'react';
import CustomerEditForm from './CustomerEditForm'; // Reuse the edit form component

function CustomerPaymentOptionsTab({ formData, setFormData, customerId, updateCustomer, customer }) {
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = async () => {
    try {
      const updatedCustomer = await updateCustomer(customerId, formData);
      setFormData(updatedCustomer);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving customer payment options:', error);
    }
  };

  return (
    <div>
      <h2>Customer Payment Options</h2>
      {isEditing ? (
        <CustomerEditForm
          formData={formData}
          setFormData={setFormData}
          handleSave={handleSave}
          handleCancel={() => setIsEditing(false)}
        />
      ) : (
        <div>
          {/* Render customer payment options form */}
          <button onClick={() => setIsEditing(true)}>Edit</button>
        </div>
      )}
    </div>
  );
}

export default CustomerPaymentOptionsTab;
