import React, { useState, useEffect } from 'react';
import './EnterPaymentDetails.css';

const EnterPaymentDetails = ({ paymentDetails, onPaymentDetailsChange }) => {
  const [paymentType, setPaymentType] = useState(paymentDetails.paymentType || '');
  const [paymentDate, setPaymentDate] = useState(paymentDetails.paymentDate || new Date().toISOString().split('T')[0]);

  useEffect(() => {
    // Only update payment details if paymentDate changes
    if (paymentDetails.paymentDate !== paymentDate) {
      onPaymentDetailsChange({
        ...paymentDetails,
        paymentDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDate]);

  const handlePaymentTypeChange = (e) => {
    const newPaymentType = e.target.value;
    setPaymentType(newPaymentType);
    onPaymentDetailsChange({
      ...paymentDetails,
      paymentType: newPaymentType,
    });
  };

  const handlePaymentDateChange = (e) => {
    const newPaymentDate = e.target.value;
    setPaymentDate(newPaymentDate);
  };

  return (
    <div className="enter-payment-details">
      <div>
        <label>
          Payment Type:
          <select value={paymentType} onChange={handlePaymentTypeChange}>
            <option value="">Select Type</option>
            <option value="Check">Check</option>
            <option value="ACH">ACH</option>
            <option value="Wire">Wire</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          Payment Date:
          <input type="date" value={paymentDate} onChange={handlePaymentDateChange} />
        </label>
      </div>
    </div>
  );
};

export default EnterPaymentDetails;
