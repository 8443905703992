import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { throttle } from 'lodash';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState('/');
  const [tokenExpirationTime, setTokenExpirationTime] = useState(null);
  const navigate = useNavigate();

  const logoutUser = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  }, [navigate]);

  const refreshAuthToken = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.post('/auth/refresh-token', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const newToken = response.data.token;
      localStorage.setItem('token', newToken);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      console.log('Session refreshed successfully!');
    } catch (error) {
      console.error('Failed to refresh token:', error);
      alert('Session expired. You will be logged out.');
      logoutUser();
    }
  }, [logoutUser]);

  useEffect(() => {
    const fetchTokenExpirationTime = async () => {
      try {
        const response = await axiosInstance.get('/auth/token-expiration-time');
        const expirationTimeString = response.data.tokenExpirationTime;
        const expirationTimeInMilliseconds = parseExpirationTime(expirationTimeString);

        if (!isNaN(expirationTimeInMilliseconds)) {
          setTokenExpirationTime(expirationTimeInMilliseconds);
        } else {
          console.error('Failed to parse token expiration time:', expirationTimeString);
        }
      } catch (error) {
        console.error('Failed to fetch token expiration time:', error);
      }
    };

    const handleUserInteraction = throttle(() => {
      const token = localStorage.getItem('token');
      if (token && tokenExpirationTime) {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const timeRemaining = decodedToken.exp - currentTime;

        if (timeRemaining * 1000 <= tokenExpirationTime * 0.25) {
          refreshAuthToken();
        }
      }
    }, 1000);

    fetchUser();
    fetchTokenExpirationTime();

    const events = ['mousemove', 'click', 'keypress'];
    events.forEach(event => {
      window.addEventListener(event, handleUserInteraction);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleUserInteraction);
      });
    };
  }, [refreshAuthToken, tokenExpirationTime]);

  const parseExpirationTime = (timeString) => {
    const timeValue = parseInt(timeString.slice(0, -1), 10);
    const timeUnit = timeString.slice(-1);

    switch (timeUnit) {
      case 's':
        return timeValue * 1000; // Convert seconds to milliseconds
      case 'm':
        return timeValue * 60 * 1000; // Convert minutes to milliseconds
      case 'h':
        return timeValue * 60 * 60 * 1000; // Convert hours to milliseconds
      case 'd':
        return timeValue * 24 * 60 * 60 * 1000; // Convert days to milliseconds
      default:
        return NaN;
    }
  };

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found in localStorage');
        setLoading(false);
        return;
      }

      const response = await axiosInstance.get(`${config.API_URL}/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (credentials) => {
    try {
      const response = await axiosInstance.post(`${config.API_URL}/auth/login`, credentials);
      const { token } = response.data;
      localStorage.setItem('token', token);
      await fetchUser();
      navigate(redirectPath);
    } catch (error) {
      console.error('Error logging in user:', error);
      throw error;
    }
  };

  const updateUser = async (updatedUser) => {
    setUser(updatedUser);
  };

  return (
    <UserContext.Provider value={{ user, setUser, loginUser, logoutUser, updateUser, fetchUser, setRedirectPath, loading }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUserContext = () => {
  return useContext(UserContext);
};
