// src/components/OutstandingInvoices.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function OutstandingInvoices() {
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchOutstandingInvoices() {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/reports/outstanding-invoices`);
        setInvoices(response.data);
      } catch (err) {
        console.error('Error fetching outstanding invoices:', err);
        setError('Error fetching outstanding invoices. Please try again later.');
      }
    }
    fetchOutstandingInvoices();
  }, []);

  return (
    <div>
      <h1>Outstanding Invoices</h1>
      {error && <p>{error}</p>}
      <ul>
        {invoices.map(invoice => (
          <li key={invoice.id}>
            {invoice.description} - ${invoice.amount} - {invoice.dueDate}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OutstandingInvoices;
