import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Card, Col, Row, Button, Table, notification, Spin, Modal, Form, Input } from 'antd';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import axiosInstance from '../axiosConfig';
import './PendingActionsDashboard.css';

const { TextArea } = Input;

const PendingActionsDashboard = () => {
  const [pendingActions, setPendingActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const pagination = useRef({ current: 1, pageSize: 10, total: 0 });
  const [editingAction, setEditingAction] = useState(null);
  const [completingAction, setCompletingAction] = useState(null);
  const [filterClient, setFilterClient] = useState([]);
  const [filterCustomer, setFilterCustomer] = useState([]);
  const [form] = Form.useForm();

  const setFilterOptions = useCallback((data) => {
    const clients = [...new Set(data.map(item => item.client?.name).filter(Boolean))];
    const customers = [...new Set(data.map(item => item.customer?.name).filter(Boolean))];
    
    setFilterClient(clients.map(client => ({ text: client, value: client })));
    setFilterCustomer(customers.map(customer => ({ text: customer, value: customer })));
  }, []);

  const fetchPendingActions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get('/pending-actions', {
        params: {
          page: pagination.current.current,
          pageSize: pagination.current.pageSize,
        },
      });
      const data = res.data.data || [];
      setPendingActions(data);
      pagination.current.total = res.data.total || 0;
      setFilterOptions(data);
    } catch (error) {
      notification.error({ message: 'Error fetching pending actions', description: error.message });
      setPendingActions([]);
    } finally {
      setLoading(false);
    }
  }, [setFilterOptions]);

  useEffect(() => {
    fetchPendingActions();
  }, [fetchPendingActions]);

  const handleTableChange = (newPagination) => {
    pagination.current = {
      ...pagination.current,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    };
    fetchPendingActions();
  };

  const markAsComplete = async (id, disposition) => {
    try {
      await axiosInstance.post(`/pending-actions/${id}/complete`, { disposition });
      notification.success({ message: 'Pending action marked as complete' });
      fetchPendingActions();
    } catch (error) {
      notification.error({ message: 'Error marking as complete', description: error.message });
    }
  };

  const handleComplete = (id) => {
    setCompletingAction(id);
  };

  const handleEdit = (action) => {
    setEditingAction(action);
    form.setFieldsValue({ problem: action.problem });
  };

  const saveEdit = async () => {
    try {
      const values = await form.validateFields();
      await axiosInstance.put(`/pending-actions/${editingAction.id}`, { problem: values.problem });
      notification.success({ message: 'Pending action updated' });
      setEditingAction(null);
      fetchPendingActions();
    } catch (error) {
      notification.error({ message: 'Error saving changes', description: error.message });
    }
  };

  const completeAction = async () => {
    try {
      const values = await form.validateFields();
      await markAsComplete(completingAction, values.disposition);
      setCompletingAction(null);
    } catch (error) {
      notification.error({ message: 'Error completing action', description: error.message });
    }
  };

  const columns = [
    {
      title: 'Problem',
      dataIndex: 'problem',
      key: 'problem',
      sorter: (a, b) => a.problem.localeCompare(b.problem),
    },
    {
      title: 'Disposition',
      dataIndex: 'disposition',
      key: 'disposition',
      sorter: (a, b) => (a.disposition || '').localeCompare(b.disposition || ''),
    },
    {
      title: 'Client',
      dataIndex: ['client', 'name'],
      key: 'client',
      filters: filterClient,
      onFilter: (value, record) => record.client?.name.includes(value),
      sorter: (a, b) => (a.client?.name || '').localeCompare(b.client?.name || ''),
      render: (text, record) => record.client ? record.client.name : 'N/A',
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
      key: 'customer',
      filters: filterCustomer,
      onFilter: (value, record) => record.customer?.name.includes(value),
      sorter: (a, b) => (a.customer?.name || '').localeCompare(b.customer?.name || ''),
      render: (text, record) => record.customer ? record.customer.name : 'N/A',
    },
    {
      title: 'Invoice',
      dataIndex: ['invoice', 'invoiceNumber'],
      key: 'invoice',
      sorter: (a, b) => (a.invoice?.invoiceNumber || '').localeCompare(b.invoice?.invoiceNumber || ''),
      render: (text, record) => record.invoice ? record.invoice.invoiceNumber : 'N/A',
    },
    {
      title: 'Dispute Amount',
      dataIndex: 'disputeAmount',
      key: 'disputeAmount',
      sorter: (a, b) => a.disputeAmount - b.disputeAmount,
      render: (text, record) => {
        const amount = parseFloat(record.disputeAmount); // Ensure it's a number
        return !isNaN(amount) ? `$${amount.toFixed(2)}` : 'N/A'; // Handle non-numeric cases
      },
    },
    {
      title: 'Next Follow-up',
      dataIndex: 'nextFollowupAt',
      key: 'nextFollowupAt',
      sorter: (a, b) => new Date(a.nextFollowupAt) - new Date(b.nextFollowupAt),
      render: (text, record) => record.nextFollowupAt ? new Date(record.nextFollowupAt).toLocaleDateString() : 'N/A',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Open', value: 'Open' },
        { text: 'Resolved', value: 'Resolved' },
        { text: 'Closed', value: 'Closed' },
        { text: 'Pending', value: 'Pending' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleComplete(record.id)}
            disabled={record.status !== 'Open'}
            style={{ marginRight: 8 }}
          >
            Mark as Complete
          </Button>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
        </>
      ),
    },
  ];

  return (
    <Row gutter={16} className="pending-actions-dashboard">
      <Col span={24}>
        <Card
          title="Pending Actions"
          extra={<Button icon={<ReloadOutlined />} onClick={fetchPendingActions} />}
          className="pending-actions-dashboard"
        >
          {loading ? <Spin /> : (
            <div className="ant-table-wrapper">
              <Table
                dataSource={pendingActions}
                columns={columns}
                rowKey="id"
                pagination={{
                  ...pagination.current,
                  showSizeChanger: false,
                  onChange: handleTableChange,
                  position: ['bottomCenter'],
                }}
                loading={loading}
                onChange={handleTableChange}
                scroll={{ y: 'calc(100vh - 300px)' }}
              />
            </div>
          )}
        </Card>
      </Col>

      {/* Edit Modal */}
      <Modal
        title="Edit Pending Action"
        visible={!!editingAction}
        onOk={saveEdit}
        onCancel={() => setEditingAction(null)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="problem" label="Problem" rules={[{ required: true, message: 'Please enter the problem statement' }]}>
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      {/* Complete Modal */}
      <Modal
        title="Mark as Complete"
        visible={!!completingAction}
        onOk={completeAction}
        onCancel={() => setCompletingAction(null)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="disposition" label="Disposition" rules={[{ required: true, message: 'Please enter the disposition statement' }]}>
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default PendingActionsDashboard;
