import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchClient,
  updateClient,
  fetchClientMetadata,
} from "../utils/apiClient";
import { useUserContext } from "../../context/UserContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./ClientDetails.css";
import ClientDetailsTab from "./ClientDetailsTab";
import ClientContactsTab from "./ClientContactsTab";
import ClientPaymentOptionsTab from "./ClientPaymentOptionsTab";
import ClientNotesTab from "./ClientNotesTab";
import ClientCustomersTab from "./ClientCustomersTab";
import ClientDocumentsTab from "./ClientDocumentsTab";
import ClientMetadataTab from "./ClientMetadataTab";
import ClientAddressesTab from "./ClientAddressesTab";

function ClientDetails() {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [formData, setFormData] = useState(null);
  const { user } = useUserContext();
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const clientData = await fetchClient(clientId);
        setClient(clientData);
        setFormData({
          ...clientData,
          advanceRate: clientData.advanceRate
            ? (clientData.advanceRate * 100).toString()
            : "",
          additionalRate: clientData.additionalRate
            ? (clientData.additionalRate * 100).toString()
            : "",
          contacts: clientData.contacts || [],
          notes: clientData.notes || [],
          customers: clientData.customers || [],
          paymentOptions: clientData.paymentOptions || [],
          factoringRates: {
            type: clientData.feeType,
            rates:
              clientData.feeType === "flat"
                ? {
                    flatRate: clientData.flatFactoringRate
                      ? (clientData.flatFactoringRate * 100).toString()
                      : "",
                    additionalRate: clientData.additionalRate
                      ? (clientData.additionalRate * 100).toString()
                      : "",
                  }
                : clientData.slidingScaleRates,
          },
          newNote: { message: "" }, // Initialize newNote
        });

        const metadataData = await fetchClientMetadata(clientId);
        setMetadata(metadataData);
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };

    initializeData();
  }, [clientId]);

  if (!client) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flexDiv">
      <h1>Client Details</h1>
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Addresses</Tab>
          <Tab>Contacts</Tab>
          <Tab>Payment Options</Tab>
          <Tab>Notes</Tab>
          <Tab>Customers</Tab>
          <Tab>Documents</Tab>
          <Tab>Metadata</Tab>
        </TabList>

        <TabPanel>
          <ClientDetailsTab
            formData={formData}
            setFormData={setFormData}
            clientId={clientId}
            updateClient={updateClient}
            client={client}
          />
        </TabPanel>

        <TabPanel>
          <ClientAddressesTab
            formData={formData}
            setFormData={setFormData}
            clientId={clientId}
            updateClient={updateClient}
          />
        </TabPanel>

        <TabPanel>
          <ClientContactsTab
            formData={formData}
            setFormData={setFormData}
            clientId={clientId}
            updateClient={updateClient}
            client={client}
          />
        </TabPanel>

        <TabPanel>
          <ClientPaymentOptionsTab
            formData={formData}
            setFormData={setFormData}
            clientId={clientId}
            updateClient={updateClient}
            client={client}
          />
        </TabPanel>

        <TabPanel>
          <ClientNotesTab
            clientId={clientId}
            user={user}
            formData={formData}
            setFormData={setFormData}
            updateClient={updateClient}
          />
        </TabPanel>

        <TabPanel>
          <ClientCustomersTab formData={formData} />
        </TabPanel>

        <TabPanel>
          <ClientDocumentsTab clientId={clientId} user={user} />
        </TabPanel>

        <TabPanel>
          <ClientMetadataTab metadata={metadata} />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default ClientDetails;
