// PaymentWizard.js
import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SelectPayeeType from './SelectPayeeType';
import SelectPayee from './SelectPayee';
import SelectAccountsPayable from './SelectAccountsPayable';
import EditLineItems from './EditLineItems';
import EnterPaymentDetails from './EnterPaymentDetails';
import CreateAnotherPayment from './CreateAnotherPayment';
import ReviewPayments from './ReviewPayments';
// import PaymentProgress from './PaymentProgress';
import GenericSearch from '../GenericSearch';
import Modal from 'react-modal';
import entitySearchConfig from '../../entitySearchConfig';
import './PaymentWizard.css';

Modal.setAppElement('#root');

const submitPayments = async (payments) => {
  console.log('Submitting payments:', payments);
};

const PaymentWizard = () => {
  const location = useLocation();
  const { payeeType: initialPayeeType, payee: initialPayee } = location.state || {};

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPayeeType, setSelectedPayeeType] = useState(initialPayeeType || null);
  const [selectedPayee, setSelectedPayee] = useState(initialPayee || null);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [payments, setPayments] = useState([{ items: [], entityType: '', entityId: null }]);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentPaymentIndex, setCurrentPaymentIndex] = useState(0); // Track the current payment index
  const [savedPayments, setSavedPayments] = useState([]); // Track saved payments

  const handleSelectPayeeType = useCallback((type) => {
    setSelectedPayeeType(type);
    setSelectedPayee(null);
    setSelectedItems([]);
    setPaymentDetails({});
    setCurrentStep(1);

    const newPayment = { items: [], entityType: type, entityId: null };
    setPayments((prevPayments) => [...prevPayments.slice(0, currentPaymentIndex), newPayment]);
    setCurrentPaymentIndex((prevIndex) => prevIndex); // Maintain the current payment index
  }, [currentPaymentIndex]);

  const handleClientSelect = useCallback((client) => {
    setSelectedPayee(client);
    setSelectedItems([]);
    setPaymentDetails({});

    setPayments((prevPayments) => prevPayments.map((payment, index) => {
      if (index === currentPaymentIndex) {
        return {
          ...payment,
          entityName: client.name,
          entityType: 'Client',
          entityAddress: client.address,
          entityId: client.id,
          items: [],
          paymentAmount: 0,
        };
      }
      return payment;
    }));
    setErrors({});
  }, [currentPaymentIndex]);

  useEffect(() => {
    if (initialPayeeType) {
      handleSelectPayeeType(initialPayeeType);
    }
    if (initialPayee) {
      handleClientSelect(initialPayee);
    }
  }, [initialPayeeType, initialPayee, handleSelectPayeeType, handleClientSelect]);

  const validateStep = useCallback(() => {
    let errors = {};
    if (currentStep === 1 && !selectedPayee) {
      errors.payee = 'Please select a payee.';
    }
    if (currentStep === 2 && (!selectedItems || selectedItems.length === 0)) {
      errors.items = 'Please select at least one accounts payable item.';
    }
    if (currentStep === 3) {
      const payment = payments[currentPaymentIndex];
      if (payment.items.some(item => !item.paymentAmount || item.paymentAmount <= 0)) {
        errors.paymentAmount = 'Please enter valid payment amounts for all items.';
      }
    }
    return errors;
  }, [currentStep, payments, selectedPayee, selectedItems, currentPaymentIndex]);

  const next = useCallback(() => {
    const validationErrors = validateStep();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setCurrentStep((prevStep) => prevStep + 1);
  }, [validateStep]);

  const prev = () => {
    setErrors({});
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSelectAccountsPayable = useCallback((selectedAccountsPayable) => {
    setPayments((prevPayments) => prevPayments.map((payment, index) => {
      if (index === currentPaymentIndex) {
        const totalAmount = selectedAccountsPayable.reduce((sum, account) => sum + parseFloat(account.balance), 0);
        return {
          ...payment,
          items: selectedAccountsPayable,
          paymentAmount: totalAmount,
        };
      }
      return payment;
    }));
    setSelectedItems(selectedAccountsPayable);
    setErrors({});
  }, [currentPaymentIndex]);

  const handleUpdateAmount = useCallback((id, amount) => {
    setPayments((prevPayments) => prevPayments.map((payment, index) => {
      if (index === currentPaymentIndex) {
        const updatedItems = payment.items.map(item =>
          item.id === id ? { ...item, paymentAmount: amount } : item
        );
        return {
          ...payment,
          items: updatedItems,
        };
      }
      return payment;
    }));
  }, [currentPaymentIndex]);

  const handlePaymentDetailsChange = useCallback((details) => {
    setPaymentDetails(details);
    setPayments((prevPayments) => prevPayments.map((payment, index) => {
      if (index === currentPaymentIndex) {
        return {
          ...payment,
          ...details,
        };
      }
      return payment;
    }));
  }, [currentPaymentIndex]);

  const handleEditPayment = (index) => {
    const payment = payments[index];
    setSelectedPayeeType(payment.entityType);
    setSelectedPayee({ name: payment.entityName, id: payment.entityId });
    setPaymentDetails({
      paymentType: payment.paymentType,
      paymentDate: payment.paymentDate,
      checkNumber: payment.checkNumber || '',
    });
    setSelectedItems(payment.items); // Set only the items related to this payment
    setCurrentPaymentIndex(index); // Set the current payment index
    setCurrentStep(2);
  };

  // const handleDeletePayment = (index) => {
  //   setPayments((prevPayments) => prevPayments.filter((_, i) => i !== index));
  //   setSavedPayments((prevSavedPayments) => prevSavedPayments.filter((_, i) => i !== index));
  // };

  const handleSavePayment = (paymentIndex, savedPayment) => {
    setSavedPayments((prevSavedPayments) => {
      const newSavedPayments = [...prevSavedPayments];
      newSavedPayments[paymentIndex] = savedPayment;
      return newSavedPayments;
    });
  };

  const handleSubmit = () => {
    submitPayments(payments);
  };

  const handleCreateAnotherPayment = () => {
    setSelectedPayeeType(null);
    setSelectedPayee(null);
    setSelectedItems([]);
    setPaymentDetails({});
    const newPayment = { items: [], entityType: '', entityId: null };
    setPayments((prevPayments) => [...prevPayments, newPayment]);
    setCurrentPaymentIndex(payments.length); // Set the current payment index to the new payment
    setCurrentStep(0);
  };

  const openClientModal = () => {
    setIsClientModalOpen(true);
  };
  
  const steps = [
    {
      title: 'Select Payee Type',
      content: <SelectPayeeType onSelectPayeeType={handleSelectPayeeType} />,
    },
    {
      title: 'Select Payee',
      content: (
        <SelectPayee
          selectedPayeeType={selectedPayeeType}
          selectedPayee={selectedPayee}
          handleClientSelect={handleClientSelect}
          openClientModal={openClientModal}
          errors={errors}
        />
      ),
    },
    {
      title: 'Select Accounts Payable',
      content: (
        selectedPayee && payments[currentPaymentIndex] && (
          <SelectAccountsPayable
            entityType={selectedPayeeType}
            entityId={selectedPayee.id}
            selectedAccountsPayable={selectedItems}
            onSelectAccountsPayable={handleSelectAccountsPayable}
          />
        )
      ),
    },
    {
      title: 'Edit Line Items',
      content: (
        selectedPayee && payments[currentPaymentIndex] && (
          <EditLineItems
            selectedAccountsPayable={payments[currentPaymentIndex].items}
            onUpdateAmount={handleUpdateAmount}
          />
        )
      ),
    },
    {
      title: 'Specify Payment Method',
      content: (
        <EnterPaymentDetails
          paymentDetails={paymentDetails}
          onPaymentDetailsChange={handlePaymentDetailsChange}
        />
      ),
    },
    {
      title: 'Create Another Payment or Proceed to Review',
      content: (
        <CreateAnotherPayment
          onCreateAnotherPayment={handleCreateAnotherPayment}
          onProceedToReview={next}
        />
      ),
    },
    {
      title: 'Review and Verify Payments',
      content: (
        <ReviewPayments
          payments={payments}
          onEditPayment={handleEditPayment}
          onSavePayment={handleSavePayment}
          savedPayments={savedPayments}
        />
      ),
    },
  ];

  return (
    <div className="payment-wizard-container">
      <nav className="breadcrumb">
        {steps.map((step, index) => (
          <span key={index} className={currentStep === index ? 'breadcrumb-item active' : 'breadcrumb-item'}>
            {step.title}
            {index < steps.length - 1 && <span className="breadcrumb-separator"> / </span>}
          </span>
        ))}
      </nav>
      <div className="payment-wizard-content">
        <div className="payment-wizard-main">
          <h2>{steps[currentStep].title}</h2>
          <div>{steps[currentStep].content}</div>
        </div>
        <div className="payment-wizard-navigation">
          <div className="button-group">
            {currentStep > 0 && currentStep < steps.length - 1 && <button onClick={prev}>Previous</button>}
            {currentStep > 0 && currentStep < steps.length - 2 && <button onClick={next}>Next</button>}
            {payments.length > 0 && currentStep < steps.length - 1 && (<button onClick={() => setCurrentStep(steps.length - 1)}>Review Payments</button>)}
            {currentStep === steps.length - 2 && (<button onClick={handleCreateAnotherPayment}>Create Another Payment</button>)}
            {currentStep === steps.length - 2 && (<button onClick={next}>Review Payments</button>)}
            {currentStep === steps.length - 1 && <button onClick={handleSubmit}>Submit All Payments</button>}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isClientModalOpen}
        onRequestClose={() => setIsClientModalOpen(false)}
        contentLabel="Client Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Client Search</h2>
            <button className="close-button" onClick={() => setIsClientModalOpen(false)}>&times;</button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={entitySearchConfig.client}
              onSelectItem={handleClientSelect}
              closeModal={() => setIsClientModalOpen(false)}
              inModal={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentWizard;
