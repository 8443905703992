// AddSupplier.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './AddSupplier.css';

function AddSupplier() {
  const [formData, setFormData] = useState({
    name: '',
    account: '',
    address: '',
    phone: '',
    email: '',
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    
    try {
      const response = await axiosInstance.post(`${config.API_URL}/suppliers`, formData);
      const supplierId = response.data.id; // Assuming the response contains the new supplier's ID
      navigate(`/suppliers/${supplierId}`);
    } catch (err) {
      setError('Error adding supplier. Please try again.');
      console.error('Error adding supplier:', err);
    }
  };

  return (
    <div className="flexDiv">
      <h1>Add Supplier</h1>
      {message && <p className="message">{message}</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Account:</label>
          <input
            type="text"
            name="account"
            value={formData.account}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Phone:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Add Supplier</button>
      </form>
    </div>
  );
}

export default AddSupplier;
