// src/components/CustomerDashboard.js
import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useTable, usePagination, useSortBy, useFilters } from 'react-table';
import './CustomerDashboard.css'; // Import the CSS file for styling

function CustomerDashboard() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInvoices() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/customers/invoices`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchInvoices();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Invoice ID',
        accessor: 'id', // accessor is the "key" in the data
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => `$${value.toFixed(2)}`, // Format as currency
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: ({ value }) => new Date(value).toLocaleDateString(), // Format as date
      },
    ],
    []
  );

  const data = useMemo(() => invoices, [invoices]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="dashboard-container">
      <h1>Customer Dashboard</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table {...getTableProps()} className="invoices-table">
            <thead>
              {headerGroups.map(headerGroup => {
                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <tr key={key} {...restHeaderGroupProps}>
                    {headerGroup.headers.map(column => {
                      const { key, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                      return (
                        <th key={key} {...restColumnProps}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <tr key={key} {...restRowProps}>
                    {row.cells.map(cell => {
                      const { key, ...restCellProps } = cell.getCellProps();
                      return (
                        <td key={key} {...restCellProps}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
}

export default CustomerDashboard;
