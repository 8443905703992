// components/CustomerClients.js
import React from 'react';
import './CustomerClients.css'; // Ensure you have the CSS file in the same directory

function CustomerClients({ clients }) {
  return (
    <div className="customer-clients">
      <h2>Associated Clients</h2>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {clients.map(client => (
              <tr key={client.id}>
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td>{client.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerClients;
