import React from 'react';

function CustomerNotes({ notes = [], newNote = { message: '' }, setNewNote, handleAddNote, handleDeleteNote }) {
  const handleNoteChange = (e) => {
    const { name, value } = e.target;
    setNewNote({
      ...newNote,
      [name]: value
    });
  };

  const handleAddNoteAndReset = async () => {
    await handleAddNote(newNote); // Pass newNote to handleAddNote
    setNewNote({ message: '' });
  };

  return (
    <div>
      <h2>Notes</h2>
      <ul>
        {notes.map(note => (
          <li key={note.id}>
            <p><strong>Author:</strong> {note.createdBy}</p>
            <p><strong>Message:</strong> {note.note}</p>
            <p><strong>Timestamp:</strong> {new Date(note.createdAt).toLocaleString()}</p>
            <button type="button" onClick={() => handleDeleteNote(note.id)}>Delete Note</button>
          </li>
        ))}
      </ul>
      <h3>Add New Note</h3>
      <label>
        Message:
        <textarea name="message" value={newNote.message} onChange={handleNoteChange}></textarea>
      </label>
      <button type="button" onClick={handleAddNoteAndReset}>Add Note</button>
    </div>
  );
}

export default CustomerNotes;
