import React, { useState, useEffect } from "react";
import { notification } from "antd";
import axiosInstance from "../axiosConfig";
import config from "../config";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import GenericSearch from "./GenericSearch";
import moment from "moment";
import "./AddSchedule.css";

Modal.setAppElement("#root");

function AddSchedule() {
  const [formData, setFormData] = useState({
    clientId: "",
    clientName: "",
    scheduleNumber: "",
    date: moment().format("YYYY-MM-DD"),
    items: [
      {
        customerId: "",
        customerName: "",
        invoiceNumber: "",
        invoiceDate: "",
        dueDate: "",
        amount: "",
        paymentTerms: "Net 30",
        purchaseOrderId: "",
        purchaseOrderNumber: "",
        isFactored: true,
        advanceAmount: 0,
        description: "", // Added description field
        selected: false,
      },
    ],
  });
  const [advanceRate, setAdvanceRate] = useState(0);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isPurchaseOrderModalOpen, setIsPurchaseOrderModalOpen] =
    useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [currentCustomerName, setCurrentCustomerName] = useState("");
  const [files, setFiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (formData.clientId) {
      axiosInstance
        .get(`${config.API_URL}/clients/${formData.clientId}`)
        .then((response) => {
          const clientData = response.data;
          setFormData((prevFormData) => ({
            ...prevFormData,
            clientName: clientData.name,
            scheduleNumber: clientData.nextScheduleNumber,
          }));
          setAdvanceRate(clientData.advanceRate || 0);
        })
        .catch((error) => {
          console.error("Error fetching client name:", error);
        });
    }
  }, [formData.clientId]);

  const handleClientSelect = (client) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      clientId: client.id,
      clientName: client.name,
    }));
    setIsClientModalOpen(false);
  };

  const handleCustomerSelect = (customer) => {
    const updatedItems = [...formData.items];
    if (currentItemIndex !== null) {
      updatedItems[currentItemIndex].customerId = customer.id;
      updatedItems[currentItemIndex].customerName = customer.name;
      setFormData({
        ...formData,
        items: updatedItems,
      });
      setIsCustomerModalOpen(false);
      setCurrentItemIndex(null);
    }
  };

  const handlePurchaseOrderSelect = (purchaseOrder) => {
    const updatedItems = [...formData.items];
    if (currentItemIndex !== null) {
      updatedItems[currentItemIndex].purchaseOrderId = purchaseOrder.id;
      updatedItems[currentItemIndex].purchaseOrderNumber =
        purchaseOrder.poNumber;
      setFormData({
        ...formData,
        items: updatedItems,
      });
      setIsPurchaseOrderModalOpen(false);
      setCurrentItemIndex(null);
    }
  };

  const formatNumber = (value) => {
    if (!value) return "";
    const [integer, decimal] = value.toString().split(".");
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimal !== undefined
      ? `${formattedInteger}.${decimal}`
      : formattedInteger;
  };

  const parseNumber = (value) => {
    if (!value) return "";
    return value.toString().replace(/,/g, "");
  };

  const handleItemChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const newItems = formData.items.map((item, i) => {
      if (i === index) {
        const newItem = {
          ...item,
          [name]: type === "checkbox" ? checked : value,
        };

        if (name === "isFactored") {
          const amount = parseFloat(parseNumber(newItem.amount)) || 0;
          newItem.advanceAmount = checked
            ? (amount * advanceRate).toFixed(2)
            : 0;
        } else if (name === "amount") {
          const parsedValue = parseFloat(parseNumber(value));
          if (!isNaN(parsedValue)) {
            newItem.advanceAmount = newItem.isFactored
              ? (parsedValue * advanceRate).toFixed(2)
              : 0;
            newItem.amount = value;
          } else {
            newItem.amount = value; // Keep the invalid value to show in the input field
          }
        } else if (name === "invoiceDate" || name === "paymentTerms") {
          const dueDate = moment(newItem.invoiceDate)
            .add(parseInt(newItem.paymentTerms.split(" ")[1]), "days")
            .format("YYYY-MM-DD");
          newItem.dueDate = dueDate;
        }

        return newItem;
      }
      return item;
    });
    setFormData({
      ...formData,
      items: newItems,
    });
  };

  const addItem = () => {
    let newInvoiceNumber = "";

    // Check if the last item has a numeric invoiceNumber and increment it by 1
    if (formData.items.length > 0) {
      const lastItem = formData.items[formData.items.length - 1];
      if (!isNaN(lastItem.invoiceNumber)) {
        newInvoiceNumber = String(Number(lastItem.invoiceNumber) + 1); // Increment by 1
      }
    }

    // Add the new item with the incremented invoice number (if applicable)
    setFormData({
      ...formData,
      items: [
        ...formData.items,
        {
          customerId: "",
          customerName: "",
          invoiceNumber: newInvoiceNumber, // Set the new invoice number
          invoiceDate: "",
          dueDate: "",
          amount: "",
          paymentTerms: "Net 30",
          purchaseOrderId: "",
          purchaseOrderNumber: "",
          isFactored: true,
          advanceAmount: 0,
          selected: false,
          autoFocus: true,
        },
      ],
    });
  };

  const removeSelectedItems = () => {
    const newItems = formData.items.filter((item) => !item.selected);
    setFormData({
      ...formData,
      items: newItems,
    });
  };

  const copySelectedItems = () => {
    const selectedItems = formData.items.filter((item) => item.selected);
    const copiedItems = selectedItems.map((item, index) => ({
      ...item,
      invoiceNumber: "",
      invoiceDate: "",
      dueDate: "",
      amount: "",
      advanceAmount: 0,
      selected: false,
      autoFocus: index === 0, // Set autoFocus to true only for the first copied item
    }));

    setFormData({
      ...formData,
      items: [...formData.items, ...copiedItems],
    });
  };

  const copyLastRow = () => {
    if (formData.items && formData.items.length > 0) {
      const lastItem = formData.items[formData.items.length - 1];

      // Check if the last invoiceNumber is numeric and increment it if it is
      let newInvoiceNumber = "";
      if (!isNaN(lastItem.invoiceNumber)) {
        newInvoiceNumber = String(Number(lastItem.invoiceNumber) + 1); // Increment by 1
      }

      const copiedItem = {
        ...lastItem,
        invoiceNumber: newInvoiceNumber,
        invoiceDate: lastItem.invoiceDate, // Copy the invoice date
        amount: lastItem.amount, // Copy the amount
        dueDate: lastItem.dueDate, // Also copy the due date based on invoiceDate
        advanceAmount: lastItem.advanceAmount, // Copy the advance amount as well
        selected: false,
        autoFocus: true, // Always set autoFocus to true for the new copied row
      };

      setFormData({
        ...formData,
        items: [...formData.items, copiedItem],
      });
    }
  };

  const toggleSelectAll = () => {
    const newItems = formData.items.map((item) => ({
      ...item,
      selected: !selectAll,
    }));
    setSelectAll(!selectAll);
    setFormData({
      ...formData,
      items: newItems,
    });
  };

  const toggleSelectRow = (index) => {
    const newItems = [...formData.items];
    newItems[index].selected = !newItems[index].selected;
    setFormData({
      ...formData,
      items: newItems,
    });
  };

  const openClientModal = () => {
    setIsClientModalOpen(true);
  };

  const openCustomerModal = (index) => {
    setCurrentItemIndex(index);
    setIsCustomerModalOpen(true);
  };

  const openPurchaseOrderModal = (index) => {
    setCurrentItemIndex(index);
    setCurrentCustomerName(formData.items[index].customerName);
    setIsPurchaseOrderModalOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for duplicate invoice numbers with the same clientId and customerId
    const invoiceCombinations = formData.items.map((item) => ({
      clientId: formData.clientId, // Assuming clientId is at the top level of formData
      customerId: item.customerId,
      invoiceNumber: item.invoiceNumber,
    }));

    const duplicates = invoiceCombinations.filter(
      (item, index, self) =>
        item.invoiceNumber &&
        self.findIndex(
          (i) =>
            i.clientId === item.clientId &&
            i.customerId === item.customerId &&
            i.invoiceNumber === item.invoiceNumber
        ) !== index
    );

    if (duplicates.length > 0) {
      notification.error({
        message: "Validation Error",
        description:
          "Duplicate invoice numbers found for the same client and customer. Please ensure each invoice number is unique.",
      });
      return;
    }

    // Validation for missing customers
    const missingCustomers = formData.items.filter(
      (item) => !item.customerName
    );
    if (missingCustomers.length > 0) {
      notification.error({
        message: "Validation Error",
        description: "Please select a customer for each line.",
      });
      return;
    }

    // Validation for missing amounts
    const invalidItems = formData.items.filter((item) =>
      isNaN(parseFloat(parseNumber(item.amount)))
    );
    if (invalidItems.length > 0) {
      notification.error({
        message: "Validation Error",
        description: "Please enter valid numeric values for all amounts.",
      });
      return;
    }

    if (
      !formData.clientName ||
      !formData.date ||
      !moment(formData.date).isValid()
    ) {
      notification.error({
        message: "Validation Error",
        description:
          "Please provide a valid date and ensure all required fields are filled.",
      });
      return;
    }

    const scheduleData = new FormData();
    scheduleData.append("clientId", formData.clientId);
    scheduleData.append("scheduleNumber", formData.scheduleNumber); // Include scheduleNumber
    scheduleData.append("date", formData.date);
    scheduleData.append("totalAmount", formData.totalAmount);
    scheduleData.append("items", JSON.stringify(formData.items));

    for (const file of files) {
      scheduleData.append("documents", file);
    }

    try {
      const response = await axiosInstance.post(
        `${config.API_URL}/schedules`,
        scheduleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      const scheduleId = response.data.id;
      
      // Success notification
      notification.success({
        message: 'Schedule Created',
        description: `Schedule ${response.data.scheduleNumber} created successfully!`,
      });
      
      // Navigate to the newly created schedule
      navigate(`/schedules/${scheduleId}`);
    } catch (error) {
      console.error("Error adding schedule:", error);
      
      // Error notification
      notification.error({
        message: 'Error Creating Schedule',
        description: error.response?.data?.error || 'An error occurred while creating the schedule.',
      });
    }

  };

  const totalInvoiceAmount = formData.items
    .reduce((total, item) => {
      const amount = parseFloat(parseNumber(item.amount || 0));
      return !isNaN(amount) ? total + amount : total;
    }, 0)
    .toFixed(2);

  const totalAdvanceAmount = formData.items
    .reduce((total, item) => {
      const advanceAmount = parseFloat(parseNumber(item.advanceAmount || 0));
      return !isNaN(advanceAmount) ? total + advanceAmount : total;
    }, 0)
    .toFixed(2);

  const entitySearchConfig = {
    client: {
      entity: "clients",
      metadataEndpoint: `${config.API_URL}/metadata/clients`,
      searchEndpoint: `${config.API_URL}/clients/search`,
      title: "Client Search",
    },
    customer: {
      entity: "customers",
      metadataEndpoint: `${config.API_URL}/metadata/customers`,
      searchEndpoint: `${config.API_URL}/customers/search`,
      title: "Customer Search",
    },
    purchaseOrder: {
      entity: "purchaseorders",
      metadataEndpoint: `${config.API_URL}/metadata/orders`,
      searchEndpoint: `${config.API_URL}/orders/search`,
      title: "Purchase Order Search",
    },
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  // Inside the return statement
  return (
    <div className="flexDiv">
      <form onSubmit={handleSubmit}>
        <h1>Add Schedule</h1>
        <div className="form-header">
          <div className="flex-item full-width">
            <label>
              <span className="required">*</span> Client:
            </label>
            <div className="inline-search-container">
              <GenericSearch
                config={entitySearchConfig.client}
                onSelectItem={handleClientSelect}
                inlineSearch={true}
                selectedValue={formData.clientName}
                autoFocus={true}
                openModal={openClientModal}
              />
            </div>
          </div>

          <div className="flex-item">
            <label htmlFor="scheduleNumber"><span className="required">* </span>Schedule Number:</label>
            <input
              type="text"
              id="scheduleNumber"
              name="scheduleNumber"
              value={formData.scheduleNumber}
              onChange={(e) =>
                setFormData({ ...formData, scheduleNumber: e.target.value })
              }
              placeholder="Optional"
              required
            />
          </div>

          <div className="flex-item">
            <label htmlFor="date">
              <span className="required">*</span> Date:
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
              required
            />
          </div>

          <div className="flex-item">
            <label htmlFor="documents">Upload Documents:</label>
            <input
              type="file"
              id="documents"
              name="documents"
              multiple
              onChange={handleFileChange}
            />
          </div>
        </div>

        <Modal
          isOpen={isClientModalOpen}
          onRequestClose={() => setIsClientModalOpen(false)}
          contentLabel="Client Search"
          className="search-modal"
          overlayClassName="search-modal-overlay"
        >
          <div className="search-content">
            <div className="search-header">
              <h2>Client Search</h2>
              <button
                className="close-button"
                onClick={() => setIsClientModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <div className="search-body">
              <GenericSearch
                config={entitySearchConfig.client}
                onSelectItem={handleClientSelect}
                closeModal={() => setIsClientModalOpen(false)}
                inModal={true}
              />
            </div>
          </div>
        </Modal>

        <div className="actions-container">
          <button type="button" onClick={removeSelectedItems} tabIndex="-1">
            Remove Selected Row(s)
          </button>
          <button type="button" onClick={copySelectedItems} tabIndex="-1">
            Copy Selected Row(s)
          </button>
        </div>

        <div className="table-container table-container-add-schedule">
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    tabIndex="-1"
                    checked={selectAll}
                    onChange={toggleSelectAll}
                  />
                </th>
                <th>
                  Customer <span className="required">*</span>
                </th>
                <th>
                  Invoice Number <span className="required">*</span>
                </th>
                <th>
                  Invoice Date <span className="required">*</span>
                </th>
                <th>
                  Amount <span className="required">*</span>
                </th>
                <th>Payment Terms</th>
                <th>Purchase Order</th>
                <th>Factored</th>
                <th>Description</th>
                <th>Advance Amount</th>
              </tr>
            </thead>
            <tbody>
              {formData.items.map((item, index) => {
                // Check for duplicate invoice numbers
                const invoiceNumbers = formData.items.map(
                  (i) => i.invoiceNumber
                );
                const isDuplicateInvoice =
                  invoiceNumbers.filter((num) => num === item.invoiceNumber)
                    .length > 1;

                return (
                  <tr
                    key={index}
                    onFocus={() => setCurrentItemIndex(index)}
                    onClick={() => setCurrentItemIndex(index)}
                  >
                    <td>
                      <input
                        type="checkbox"
                        tabIndex="-1"
                        checked={item.selected}
                        onChange={() => toggleSelectRow(index)}
                      />
                    </td>
                    <td>
                      <GenericSearch
                        config={entitySearchConfig.customer}
                        onSelectItem={(customer) => {
                          setCurrentItemIndex(index);
                          handleCustomerSelect(customer);
                        }}
                        inlineSearch={true}
                        selectedValue={item.customerName}
                        openModal={() => openCustomerModal(index)}
                        autoFocus={item.autoFocus || false}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="invoiceNumber"
                        value={item.invoiceNumber}
                        onChange={(e) => handleItemChange(index, e)}
                        required
                        className={isDuplicateInvoice ? "input-error" : ""}
                      />
                      {isDuplicateInvoice && (
                        <span className="error-message">
                          Duplicate Invoice Number
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="date"
                        name="invoiceDate"
                        value={item.invoiceDate}
                        onChange={(e) => handleItemChange(index, e)}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="amount"
                        value={item.amount}
                        onChange={(e) => handleItemChange(index, e)}
                        onBlur={(e) =>
                          handleItemChange(index, {
                            target: {
                              name: "amount",
                              value: formatNumber(e.target.value),
                            },
                          })
                        }
                        required
                      />
                    </td>
                    <td>
                      <select
                        name="paymentTerms"
                        value={item.paymentTerms}
                        onChange={(e) => handleItemChange(index, e)}
                      >
                        <option value="Net 30">Net 30</option>
                        <option value="Net 60">Net 60</option>
                        <option value="Net 90">Net 90</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="purchaseOrderNumber"
                        value={item.purchaseOrderNumber}
                        readOnly
                        onClick={() => openPurchaseOrderModal(index)}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="isFactored"
                        checked={item.isFactored}
                        onChange={(e) => handleItemChange(index, e)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="description"
                        value={item.description} // Bound to the new description field
                        onChange={(e) => handleItemChange(index, e)}
                      />
                    </td>
                    <td>${formatNumber(item.advanceAmount)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="total-amounts">
          <div>
            <strong>
              Total Invoice Amount: ${formatNumber(totalInvoiceAmount)}
            </strong>
          </div>
          <div>
            <strong>
              Total Advance Amount: ${formatNumber(totalAdvanceAmount)}
            </strong>
          </div>
        </div>

        <div className="fixed-form-buttons">
          <button type="button" onClick={copyLastRow}>
            Copy Last Row
          </button>
          <button type="button" onClick={addItem}>
            Add Invoice
          </button>
          <button type="submit">Save</button>
        </div>
      </form>

      <Modal
        isOpen={isCustomerModalOpen}
        onRequestClose={() => setIsCustomerModalOpen(false)}
        contentLabel="Customer Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Customer Search</h2>
            <button
              className="close-button"
              onClick={() => setIsCustomerModalOpen(false)}
            >
              &times;
            </button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={entitySearchConfig.customer}
              onSelectItem={handleCustomerSelect}
              closeModal={() => setIsCustomerModalOpen(false)}
              inModal={true}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isPurchaseOrderModalOpen}
        onRequestClose={() => setIsPurchaseOrderModalOpen(false)}
        contentLabel="Purchase Order Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Purchase Order Search</h2>
            <button
              className="close-button"
              onClick={() => setIsPurchaseOrderModalOpen(false)}
            >
              &times;
            </button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={entitySearchConfig.purchaseOrder}
              onSelectItem={handlePurchaseOrderSelect}
              closeModal={() => setIsPurchaseOrderModalOpen(false)}
              inModal={true}
              initialFormData={{
                clientName: formData.clientName,
                customerName: currentCustomerName,
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddSchedule;
