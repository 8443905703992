import React, { useState, useEffect, useRef } from 'react';
import Navigation from './Navigation';
import TopBar from './TopBar';
import './Layout.css';

const sidebarStateKey = 'sidebarState'; // Key for localStorage

function Layout({ children }) {
  // Load sidebar state from localStorage (default to closed and unpinned)
  const getInitialSidebarState = () => {
    const savedState = localStorage.getItem(sidebarStateKey);
    return savedState ? JSON.parse(savedState) : { isSidebarOpen: false, isSidebarPinned: false };
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(getInitialSidebarState().isSidebarOpen);
  const [isSidebarPinned, setIsSidebarPinned] = useState(getInitialSidebarState().isSidebarPinned);
  const closeSidebarTimeout = useRef(null); // Ref to store the timeout ID

  // Save sidebar state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(sidebarStateKey, JSON.stringify({ isSidebarOpen, isSidebarPinned }));
  }, [isSidebarOpen, isSidebarPinned]);

  // Toggle pin/unpin sidebar
  const toggleSidebar = () => {
    const newIsPinned = !isSidebarPinned;
    setIsSidebarPinned(newIsPinned); // Toggle pin state
    setIsSidebarOpen(newIsPinned); // Open when pinned
  };

  // Open sidebar on hover (only when unpinned)
  const hoverSidebar = () => {
    if (!isSidebarPinned) {
      clearTimeout(closeSidebarTimeout.current); // Cancel any scheduled close
      setIsSidebarOpen(true); // Open sidebar on hover when unpinned
    }
  };

  // Close sidebar with a delay (only if not pinned)
  const closeSidebarWithDelay = () => {
    if (!isSidebarPinned) {
      closeSidebarTimeout.current = setTimeout(() => {
        setIsSidebarOpen(false); // Close sidebar after a delay
      }, 300); // Adjust the delay time as needed
    }
  };

  // Clear timeout when entering the sidebar
  const handleSidebarMouseEnter = () => {
    clearTimeout(closeSidebarTimeout.current); // Cancel the close delay
    hoverSidebar(); // Open the sidebar when hovered
  };

  return (
    <div className="layout">
      <TopBar
        toggleSidebar={toggleSidebar}
        hoverSidebar={hoverSidebar}
        closeSidebar={closeSidebarWithDelay} // Close with delay
        isSidebarOpen={isSidebarOpen}
        isSidebarPinned={isSidebarPinned}
      />
      <div className="content-wrapper">
        <div
          className={`sidebar-wrapper ${isSidebarOpen ? '' : 'collapsed'}`}
          onMouseEnter={handleSidebarMouseEnter} // Clear timeout and open
          onMouseLeave={closeSidebarWithDelay} // Close after leaving
        >
          <Navigation
            isSidebarOpen={isSidebarOpen}
            isSidebarPinned={isSidebarPinned}
            closeSidebar={closeSidebarWithDelay}
          />
        </div>
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
