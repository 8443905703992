// src/components/CreditRequest.js
import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './CreditRequest.css'; // Import the CSS file for styling

function CreditRequest() {
  const [type, setType] = useState('credit');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');

  const handleRequest = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(
        `${config.API_URL}/customer/requests`,
        { type, amount, description },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setMessage('Request submitted successfully');
    } catch (error) {
      console.error('Error submitting request:', error);
      setMessage('Request submission failed');
    }
  };

  return (
    <div className="credit-request-container">
      <h1>Credit or RA Request</h1>
      <form onSubmit={handleRequest} className="request-form">
        <div className="form-group">
          <label>Request Type:</label>
          <select value={type} onChange={(e) => setType(e.target.value)} required>
            <option value="credit">Credit</option>
            <option value="ra">RA</option>
          </select>
        </div>
        <div className="form-group">
          <label>Amount:</label>
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
        </div>
        <button type="submit" className="request-button">Submit Request</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default CreditRequest;
