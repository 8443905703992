import axiosInstance from '../../axiosConfig';
import config from '../../config';

export const fetchClient = async (clientId) => {
  const response = await axiosInstance.get(`${config.API_URL}/clients/${clientId}`);
  return response.data;
};

export const updateClient = async (clientId, formData) => {
  const response = await axiosInstance.put(`${config.API_URL}/clients/${clientId}`, formData);
  return response.data;
};

export const fetchClientMetadata = async (clientId) => {
  try {
    const response = await axiosInstance.get(`${config.API_URL}/clients/${clientId}/metadata`);
    return response.data;
  } catch (error) {
    console.error('Error fetching metadata:', error);
    throw error;
  }
};

export const addContact = async (contact, clientId) => {
    const response = await axiosInstance.post(`${config.API_URL}/contacts`, { ...contact, clientId });
    return response.data;
  };

export const updateContact = async (contactId, contact) => {
  const response = await axiosInstance.put(`${config.API_URL}/contacts/${contactId}`, contact);
  return response.data;
};

export const deleteContact = async (contactId, client, setClient) => {
  try {
    await axiosInstance.delete(`${config.API_URL}/contacts/${contactId}`);
    const updatedContacts = client.contacts.filter(contact => contact.id !== contactId);
    setClient({ ...client, contacts: updatedContacts });
  } catch (error) {
    console.error('Error deleting contact:', error);
  }
};

export const addNote = async (clientId, note, user) => {
  try {
    if (!user || !user.username) {
      throw new Error('User is not logged in or username is missing');
    }
    const response = await axiosInstance.post(`${config.API_URL}/notes`, {
      message: note.message,
      clientId,
      author: user.username
    });
    return response.data; // Return the added note
  } catch (error) {
    console.error('Error adding note:', error);
    throw error;
  }
};

export const deleteNote = async (noteId) => {
  try {
    const response = await axiosInstance.delete(`${config.API_URL}/notes/${noteId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
};

export const fetchClientDocuments = async (clientId) => {
  const response = await axiosInstance.get(`${config.API_URL}/clients/${clientId}/documents`);
  return response.data;
};

export const downloadClientDocument = async (clientId, documentId, documentName) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${config.API_URL}/clients/${clientId}/documents/${documentId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
  });

  // Create a URL for the file blob and trigger a download
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName); // Specify the filename
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const deleteClientDocument = async (clientId, documentId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.delete(`${config.API_URL}/clients/${clientId}/documents/${documentId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting client document:', error);
    throw error;
  }
};