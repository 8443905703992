import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { UserContext } from '../context/UserContext';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const { user } = useContext(UserContext);
  const [totalClients, setTotalClients] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [invoiceData, setInvoiceData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    async function fetchDashboardData() {
      try {
        const token = localStorage.getItem('token');

        const [clientsResponse, invoicesResponse] = await Promise.all([
          axiosInstance.get(`${config.API_URL}/clients`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }),
          axiosInstance.get(`${config.API_URL}/invoices`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        ]);

        setTotalClients(clientsResponse.data.length);
        setTotalInvoices(invoicesResponse.data.length);

        const invoices = invoicesResponse.data || [];
        const invoiceAmounts = invoices.map(invoice => invoice.amount);
        const invoiceDates = invoices.map(invoice => new Date(invoice.createdAt).toLocaleDateString());

        setInvoiceData({
          labels: invoiceDates,
          datasets: [
            {
              label: 'Invoice Amounts',
              data: invoiceAmounts,
              backgroundColor: 'rgba(75, 192, 192, 0.6)'
            }
          ]
        });
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    }

    if (user) {
      fetchDashboardData();
    }
  }, [user]);

  return (
    <div>
      <h2>Dashboard</h2>
      <div className="widget">
        <h3>Total Clients</h3>
        <p>{totalClients}</p>
      </div>
      <div className="widget">
        <h3>Total Invoices</h3>
        <p>{totalInvoices}</p>
      </div>
      <div className="chart">
        <h3>Invoice Amounts Over Time</h3>
        <Bar data={invoiceData} />
      </div>
    </div>
  );
}

export default Dashboard;
