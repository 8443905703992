import { useNavigate, useLocation } from 'react-router-dom';
import GenericSearch from './GenericSearch';
import './ClientSearch.css';
import entitySearchConfig from '../entitySearchConfig';

function ClientSearch() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clientName = searchParams.get('clientName') || '';

  const handleSelectEntity = (entity) => {
    navigate(`/clients/${entity.id}`);
  };

  return (
    <div className="flexDiv">
      <GenericSearch
        config={entitySearchConfig.client}
        onSelectItem={handleSelectEntity}
        closeModal={() => {}}
        inModal={false}
        initialFormData={{ clientName }}
      />
    </div>
  );
}

export default ClientSearch;
