import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';

function AddPayment() {
  const [formData, setFormData] = useState({
    invoiceId: '',
    amount: '',
    date: '',
    status: ''
  });
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchInvoices() {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/invoices`);
        setInvoices(response.data);
      } catch (err) {
        console.error('Error fetching invoices:', err);
      }
    }

    fetchInvoices();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const updatedFormData = {
        ...formData,
        date: formData.date // No need to convert the date as it's already in yyyy-MM-dd format
      };
      console.log('Submitting form data:', updatedFormData);
      await axiosInstance.post(`${config.API_URL}/payments`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      navigate('/payments');
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <select name="invoiceId" value={formData.invoiceId} onChange={handleChange} required>
        <option value="">Select Invoice</option>
        {invoices.map(invoice => (
          <option key={invoice.id} value={invoice.id}>{invoice.description}</option>
        ))}
      </select>
      <input type="number" name="amount" value={formData.amount} onChange={handleChange} placeholder="Amount" required />
      <input type="date" name="date" value={formData.date} onChange={handleChange} placeholder="Date" required />
      <input type="text" name="status" value={formData.status} onChange={handleChange} placeholder="Status" required />
      <button type="submit">Add Payment</button>
    </form>
  );
}

export default AddPayment;
