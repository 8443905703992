// src/components/GettingStarted.js
import React from 'react';
import './GettingStarted.css'; // Import the CSS file for styling

function GettingStarted() {
  return (
    <div className="getting-started-container">
      <h1>Getting Started with the Factoring Application</h1>
      <p>Welcome to the Factoring Application! This guide will help you get started with using the application effectively.</p>
      
      <div className="section">
        <h2>1. Registering Your Account</h2>
        <p>To begin, register your account by providing the necessary details. Once registered, your account will be reviewed and verified by our team.</p>
      </div>

      <div className="section">
        <h2>2. Exploring the Dashboard</h2>
        <p>After logging in, you will be directed to your dashboard. The dashboard provides an overview of your activities, metrics, and important notifications.</p>
      </div>

      <div className="section">
        <h2>3. Managing Invoices</h2>
        <p>Navigate to the Invoices section to manage your invoices. You can create, view, and track your invoices easily.</p>
      </div>

      <div className="section">
        <h2>4. Managing Clients</h2>
        <p>In the Clients section, you can add new clients, view client details, and manage client interactions.</p>
      </div>

      <div className="section">
        <h2>5. Support and Resources</h2>
        <p>If you need any assistance, visit our Support section or check out the Frequently Asked Questions (FAQ) page.</p>
      </div>
    </div>
  );
}

export default GettingStarted;
