// SupplierNotesTab.js
import React from 'react';
import SupplierNotes from './SupplierNotes';
import { addSupplierNote, deleteSupplierNote } from '../utils/apiSupplier';

function SupplierNotesTab({ supplierId, user, formData, setFormData }) {
  const handleAddNote = async (newNote) => {
    try {
      const addedNote = await addSupplierNote(supplierId, newNote, user);
      setFormData(prevFormData => ({
        ...prevFormData,
        notes: [...prevFormData.notes, addedNote]
      }));
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await deleteSupplierNote(noteId);
      setFormData(prevFormData => ({
        ...prevFormData,
        notes: prevFormData.notes.filter(note => note.id !== noteId)
      }));
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  return (
    <SupplierNotes
      notes={formData.notes}
      newNote={formData.newNote}
      setNewNote={(newNote) => setFormData(prevFormData => ({ ...prevFormData, newNote }))}
      handleAddNote={handleAddNote}
      handleDeleteNote={handleDeleteNote}
    />
  );
}

export default SupplierNotesTab;
