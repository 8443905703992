import React from 'react';
import GenericSearch from '../GenericSearch'; // Ensure correct path
import entitySearchConfig from '../../entitySearchConfig';
import './SelectPayee.css'; // Create or update CSS as needed

const SelectPayee = ({ selectedPayeeType, selectedPayee, handleClientSelect, openClientModal, errors }) => {
  return (
    selectedPayeeType === 'Client' ? (
      <div>
        <div className="client-selector">
          <label>
            Client:
            <div className="inline-search-container">
              <GenericSearch
                config={entitySearchConfig.client}
                onSelectItem={handleClientSelect}
                inlineSearch={true}
                selectedValue={selectedPayee ? selectedPayee.name : ''}
                autoFocus={true}
                openModal={openClientModal}
              />
            </div>
          </label>
        </div>
        {selectedPayee && (
          <div className="selected-client">
            <p>Selected Client: {selectedPayee.name}</p>
          </div>
        )}
        {errors.payee && <div className="error">{errors.payee}</div>}
      </div>
    ) : (
      <p>Select a {selectedPayeeType}</p>
    )
  );
};

export default SelectPayee;
