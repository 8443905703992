import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

function DepositList() {
  const [deposits, setDeposits] = useState([]);

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/deposits`);
        setDeposits(response.data);
      } catch (error) {
        console.error('Error fetching deposits:', error);
      }
    };

    fetchDeposits();
  }, []);

  return (
    <div>
      <h1>Deposits</h1>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Customer</th>
            <th>Invoice</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {deposits.map(deposit => (
            <tr key={deposit.id}>
              <td>{deposit.date || 'N/A'}</td>
              <td>{deposit.customer?.name || 'Unknown Customer'}</td>
              <td>{deposit.invoice?.invoiceNumber || 'No Invoice'}</td>
              <td>{deposit.amount || '0.00'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DepositList;
