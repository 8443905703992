import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { v4 as uuidv4 } from 'uuid'; // Make sure to install uuid package
import './JobManagement.css';
import ReactFlow, { MiniMap, Controls, Background } from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';

const JobManagement = () => {
  const [fileType, setFileType] = useState('Customers');
  const [dependencies, setDependencies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowJobs, setWorkflowJobs] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('createWorkflow');
  const [workflowGraph, setWorkflowGraph] = useState({ nodes: [], edges: [] });
  const pollingIntervalRef = useRef(null);

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const handleDependenciesChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setDependencies(selectedOptions);
  };

  const handleWorkflowNameChange = (e) => {
    setWorkflowName(e.target.value);
  };

  const handleAddWorkflowJob = () => {
    const newJob = {
      id: uuidv4(),
      jobType: fileType,
      sourceEntity: fileType,
      order: workflowJobs.length + 1,
      depends_on: dependencies,
    };
    setWorkflowJobs([newJob, ...workflowJobs]);
    setDependencies([]); // Reset dependencies
  };

  const handleCreateWorkflow = async () => {
    setIsSubmitting(true);

    try {
      await axiosInstance.post(`${config.API_URL}/workflows`, {
        name: workflowName,
        description: '',
        jobs: workflowJobs,
      });

      await fetchWorkflows();
    } catch (error) {
      console.error('Error creating workflow:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStartWorkflow = async () => {
    setIsSubmitting(true);

    try {
      await axiosInstance.post(`${config.API_URL}/workflows/${selectedWorkflow}/start`);
      fetchJobs();
    } catch (error) {
      console.error('Error starting workflow:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchJobs = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/jobs`);
      setJobs(response.data);

      const shouldPoll = response.data.some(job => job.status === 'PENDING' || job.status === 'IN_PROGRESS');
      if (shouldPoll) {
        if (!pollingIntervalRef.current) {
          pollingIntervalRef.current = setInterval(fetchJobs, 10000);
        }
      } else {
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  }, []);

  const fetchWorkflows = async () => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/workflows`);
      setWorkflows(response.data);
    } catch (error) {
      console.error('Error fetching workflows:', error);
    }
  };

  const downloadLog = async (jobId) => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/jobs/log/${jobId}`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `job_log_${jobId}.txt`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading log:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchWorkflows();

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [fetchJobs]);

  const filteredJobs = jobs.filter(job =>
    job.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dagreGraph = useMemo(() => {
    const graph = new dagre.graphlib.Graph();
    graph.setDefaultEdgeLabel(() => ({}));
    return graph;
  }, []); // No dependencies, so it will only be initialized once

  const nodeWidth = 250;
  const nodeHeight = 50;

  const getLayoutedElements = useCallback((nodes, edges, direction = 'TB') => {
    // const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      return node;
    });

    return { nodes: layoutedNodes, edges };
  }, [dagreGraph]);

  // Transform workflow jobs to nodes and edges, and then apply the layout
  const transformWorkflowToGraph = useCallback((workflow) => {
    if (!workflow || !workflow.jobs) return { nodes: [], edges: [] };
    
    const nodes = [];
    const edges = [];

    workflow.jobs.forEach((job) => {
      // Update node's label to include targetEntity, sourceEntity, and migrationScript
      const nodeLabel = (
        <div>
          <div><strong>Source:</strong> {job.sourceEntity}</div>
          <div><strong>Target:</strong> {job.targetEntity}</div>
          <div><strong>Script:</strong> {job.migrationScript}</div>
        </div>
      );

      nodes.push({
        id: job.id,
        data: { label: nodeLabel },
        position: { x: 0, y: 0 }, // Initial position; will be updated by dagre
        style: { width: 250 }
      });

      job.depends_on.forEach((dependency) => {
        edges.push({
          id: `e${dependency}-${job.id}`,
          source: dependency,
          target: job.id,
        });
      });
    });

    // Apply the layout
    return getLayoutedElements(nodes, edges);
  }, [getLayoutedElements]);


  // Update the graph whenever a workflow is selected
  useEffect(() => {
    const selected = workflows.find(workflow => workflow.id === parseInt(selectedWorkflow, 10));
    if (selected) {
      const graph = transformWorkflowToGraph(selected);
      console.log('Graph Nodes:', graph.nodes);
      console.log('Graph Edges:', graph.edges);
      setWorkflowGraph(graph);
    }
  }, [selectedWorkflow, workflows, transformWorkflowToGraph]);  
  
  return (
    <div className="flexDiv">
      <h2>Job Management</h2>
      <div className="tabs">
        <button className={`tab ${activeTab === 'createWorkflow' ? 'active' : ''}`} onClick={() => setActiveTab('createWorkflow')}>
          Create Workflow
        </button>
        <button className={`tab ${activeTab === 'startWorkflow' ? 'active' : ''}`} onClick={() => setActiveTab('startWorkflow')}>
          Start Workflow
        </button>
        <button className={`tab ${activeTab === 'jobList' ? 'active' : ''}`} onClick={() => setActiveTab('jobList')}>
          Job List
        </button>
      </div>

      {activeTab === 'createWorkflow' ? (
        <div className="tab-content">
          <form>
            <div>
              <label>Workflow Name:</label>
              <input
                type="text"
                value={workflowName}
                onChange={handleWorkflowNameChange}
                placeholder="Workflow Name"
              />
            </div>
            <div>
              <label>Target Entity:</label>
              <select value={fileType} onChange={handleFileTypeChange}>
                <option value="Accounts">Accounts</option>
                <option value="Customers">Customers</option>
                <option value="Clients">Clients</option>
                <option value="Suppliers">Suppliers</option>
                <option value="ClientCustomers">Client Customers</option>
                <option value="PurchaseOrders">Purchase Orders</option>
                <option value="Schedules">Schedules</option>
                <option value="Invoices">Invoices</option>
                <option value="InvoiceTransactions">Invoice Transactions</option>
                <option value="Journal">Journal</option>
                <option value="CollectionReports">Collection Reports</option>
                <option value="CollectionReportInvoices">Collection Report Invoices</option>
                <option value="AccountsPayable">Accounts Payable</option>
              </select>
            </div>
            <div>
              <label>Dependencies:</label>
              <select multiple value={dependencies} onChange={handleDependenciesChange}>
                {workflowJobs.map((job, index) => (
                  <option key={index} value={job.id}>{job.jobType}</option>
                ))}
              </select>
            </div>
            <div>
              <button type="button" onClick={handleAddWorkflowJob}>Add to Workflow</button>
              <button type="button" onClick={handleCreateWorkflow} disabled={isSubmitting}>
                {isSubmitting ? 'Creating Workflow...' : 'Create Workflow'}
              </button>
            </div>
          </form>

          <div className="collapsible">
            <button className="collapsible-header">Workflow Jobs</button>
            <div className="collapsible-content">
              <ul>
                {workflowJobs.map((job, index) => (
                  <li key={index}>
                    {job.jobType} (Order: {job.order}) - Source Entity: {job.sourceEntity}
                    <div>
                      <label>Depends On:</label>
                      <ul>
                        {job.depends_on.map(dep => (
                          <li key={dep}>{workflowJobs.find(j => j.id === dep)?.jobType || dep}</li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      {activeTab === 'startWorkflow' ? (
              <div className="tab-content">
                <h3>Workflows</h3>
                <select value={selectedWorkflow} onChange={(e) => setSelectedWorkflow(e.target.value)}>
                  <option value="">Select a Workflow</option>
                  {workflows.map((workflow) => (
                    <option key={workflow.id} value={workflow.id}>{workflow.name}</option>
                  ))}
                </select>
                <button onClick={handleStartWorkflow} disabled={isSubmitting || !selectedWorkflow}>
                  {isSubmitting ? 'Starting Workflow...' : 'Start Workflow'}
                </button>

                {selectedWorkflow && workflowGraph.nodes.length > 0 ? (
                  <div className="graph-container">
                    <ReactFlow nodes={workflowGraph.nodes} edges={workflowGraph.edges} fitView>
                      <MiniMap />
                      <Controls />
                      <Background />
                    </ReactFlow>
                  </div>
                ) : null}
              </div>
            ) : null}

      {activeTab === 'jobList' ? (
        <div className="tab-content">
          <input
            type="text"
            placeholder="Search jobs"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div style={{ overflowX: 'auto' }}>
            <table>
              <thead>
                <tr>
                  <th>Job ID</th>
                  <th>Target Entity</th>
                  <th>Source Entity</th>
                  <th>Order</th>
                  <th>Status</th>
                  <th>Started At</th>
                  <th>Finished At</th>
                  <th>Duration</th>
                  <th>Records Read</th>
                  <th>Records Written</th>
                  <th>Details</th>
                  <th>Download Log</th>
                </tr>
              </thead>
              <tbody>
                {filteredJobs.length > 0 ? (
                  filteredJobs.map((job) => (
                    <tr key={job.job_id}>
                      <td>{job.job_id}</td>
                      <td>{job.target_entity}</td>
                      <td>{job.source_entity}</td>
                      <td>{job.order}</td>
                      <td>{job.status}</td>
                      <td>{job.started_at ? new Date(job.started_at).toLocaleString() : 'N/A'}</td>
                      <td>{job.finished_at ? new Date(job.finished_at).toLocaleString() : 'N/A'}</td>
                      <td>{job.started_at && job.finished_at ? ((new Date(job.finished_at) - new Date(job.started_at)) / 1000) + 's' : 'N/A'}</td>
                      <td>{job.records_read || 'N/A'}</td>
                      <td>{job.records_written || 'N/A'}</td>
                      <td>{job.details || 'N/A'}</td>
                      <td><button onClick={() => downloadLog(job.job_id)}>Download Log</button></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12">No jobs found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default JobManagement;
