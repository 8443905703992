import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './IncomeStatementManager.css';

const IncomeStatementManager = () => {
    const [preparedYears, setPreparedYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [newYear, setNewYear] = useState('');
    const [selectedYears, setSelectedYears] = useState([]);
    const [incomeStatements, setIncomeStatements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchPreparedYears();
    }, []);

    const fetchPreparedYears = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${config.API_URL}/financials/income-statements/prepared-years`);
            setPreparedYears(response.data);
            setError('');
        } catch (error) {
            console.error('Error fetching prepared years:', error);
            setError('Error fetching prepared years');
        }
        setIsLoading(false);
    };

    const fetchIncomeStatement = async (year) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${config.API_URL}/financials/income-statements/${year}`);
            setIncomeStatements([{ year, data: response.data }]);
            setError('');
        } catch (error) {
            console.error('Error fetching income statement:', error);
            setIncomeStatements([]);
            setError('Error fetching income statement');
        }
        setIsLoading(false);
    };

    const fetchMultipleIncomeStatements = async (years) => {
        setIsLoading(true);
        try {
            const responses = await Promise.all(
                years.map(year => 
                    year === 'YTD'
                        ? axiosInstance.get(`${config.API_URL}/financials/income-statements/ytd`)
                        : axiosInstance.get(`${config.API_URL}/financials/income-statements/${year}`)
                )
            );
            const statements = responses.map((response, index) => ({
                year: years[index],
                data: response.data.incomeStatement || response.data
            }));
            setIncomeStatements(statements);
            setError('');
        } catch (error) {
            console.error('Error fetching multiple income statements:', error);
            setIncomeStatements([]);
            setError('Error fetching multiple income statements');
        }
        setIsLoading(false);
    };

    const prepareIncomeStatement = async (year) => {
        const yearExists = preparedYears.includes(parseInt(year));
        if (yearExists) {
            const confirmPrepare = window.confirm(`Year ${year} already exists. Do you want to overwrite it?`);
            if (!confirmPrepare) return;
        } else {
            const confirmPrepare = window.confirm(`Are you sure you want to prepare the income statement for the year ${year}?`);
            if (!confirmPrepare) return;
        }

        setIsLoading(true);
        try {
            if (yearExists) {
                await axiosInstance.delete(`${config.API_URL}/financials/income-statements/${year}`);
            }
            await axiosInstance.post(`${config.API_URL}/financials/income-statements/prepare/${year}`);
            fetchPreparedYears();
            fetchIncomeStatement(year);
            setError('');
        } catch (error) {
            console.error('Error preparing income statement:', error);
            setError('Error preparing income statement');
        }
        setIsLoading(false);
    };

    const fetchYearToDateIncomeStatement = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${config.API_URL}/financials/income-statements/ytd`);
            setIncomeStatements([{ year: 'YTD', data: response.data.incomeStatement }]);
            setError('');
        } catch (error) {
            console.error('Error fetching year-to-date income statement:', error);
            setIncomeStatements([]);
            setError('Error fetching year-to-date income statement');
        }
        setIsLoading(false);
    };

    const handleYearSelection = (year) => {
        setSelectedYears(prevSelected => {
            if (prevSelected.includes(year)) {
                return prevSelected.filter(y => y !== year);
            } else {
                return [...prevSelected, year];
            }
        });
    };

    const transposeData = (statements) => {
        const allEntries = {};
        statements.forEach(statement => {
            statement.data.forEach(entry => {
                const key = `${entry.minNumericPrefix}-${entry.accountCategory}-${entry.accountType}`;
                if (!allEntries[key]) {
                    allEntries[key] = {
                        minNumericPrefix: entry.minNumericPrefix,
                        accountCategory: entry.accountCategory,
                        accountType: entry.accountType,
                    };
                }
                allEntries[key][statement.year] = entry.netIncome;
            });
        });
        return Object.values(allEntries);
    };

    const calculateNetIncome = (statements) => {
        const netIncomeByYear = {};

        statements.forEach(statement => {
            const revenues = statement.data
                .filter(entry => entry.accountCategory === 'Revenues')
                .reduce((acc, entry) => acc + parseFloat(entry.netIncome || 0), 0);
            const expenses = statement.data
                .filter(entry => entry.accountCategory === 'Expenses')
                .reduce((acc, entry) => acc + parseFloat(entry.netIncome || 0), 0);
            const netIncome = (revenues - expenses).toFixed(2);
            netIncomeByYear[statement.year] = netIncome;
        });

        return netIncomeByYear;
    };

    const renderTable = () => {
        if (incomeStatements.length === 0) {
            return null;
        }

        const transposedData = transposeData(incomeStatements);
        const netIncomeByYear = calculateNetIncome(incomeStatements);

        const headers = [
            'Min Numeric Prefix',
            'Account Category',
            'Account Type',
            ...incomeStatements.map(statement => statement.year),
        ];

        return (
            <table className="income-statement-table">
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {transposedData.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.minNumericPrefix}</td>
                            <td>{entry.accountCategory}</td>
                            <td>{entry.accountType}</td>
                            {incomeStatements.map(statement => (
                                <td key={statement.year}>{entry[statement.year] || '-'}</td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="3"><strong>Net Income</strong></td>
                        {incomeStatements.map(statement => (
                            <td key={statement.year}><strong>{netIncomeByYear[statement.year]}</strong></td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    };

    return (
        <div className="income-statement-manager">
            <h1>Income Statement Manager</h1>
            <div className="actions">
                <button onClick={fetchYearToDateIncomeStatement} disabled={isLoading}>Fetch Year-to-Date Income Statement</button>
                <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    disabled={isLoading}
                >
                    <option value="" disabled>Select Year</option>
                    {preparedYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
                <button onClick={() => fetchIncomeStatement(selectedYear)} disabled={!selectedYear || isLoading}>
                    Fetch Selected Year Income Statement
                </button>
                <input
                    type="number"
                    placeholder="Enter Year"
                    value={newYear}
                    onChange={(e) => setNewYear(e.target.value)}
                    disabled={isLoading}
                />
                <button onClick={() => prepareIncomeStatement(newYear)} disabled={!newYear || isLoading}>
                    Prepare/Re-prepare Income Statement
                </button>
            </div>
            <div className="multiple-actions">
                <h2>Select Years to Fetch Income Statements</h2>
                <div className="year-checkboxes">
                    <label key="YTD">
                        <input
                            type="checkbox"
                            value="YTD"
                            checked={selectedYears.includes("YTD")}
                            onChange={() => handleYearSelection("YTD")}
                            disabled={isLoading}
                        />
                        YTD
                    </label>
                    {preparedYears.map((year) => (
                        <label key={year}>
                            <input
                                type="checkbox"
                                value={year}
                                checked={selectedYears.includes(year)}
                                onChange={() => handleYearSelection(year)}
                                disabled={isLoading}
                            />
                            {year}
                        </label>
                    ))}
                </div>
                <button onClick={() => fetchMultipleIncomeStatements(selectedYears)} disabled={selectedYears.length === 0 || isLoading}>
                    Fetch Multiple Years Income Statements
                </button>
            </div>
            {isLoading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            {renderTable()}
        </div>
    );
};

export default IncomeStatementManager;
