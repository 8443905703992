// src/components/ApplyCreditMemo.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './ApplyCreditMemo.css';

function ApplyCreditMemo() {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [creditMemo, setCreditMemo] = useState({
    amount: '',
    paymentMethod: 'Credit Card',
    cardNumber: '',
    cardExpiry: '',
    cardCVC: '',
    comment: ''
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchInvoice() {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(`${config.API_URL}/invoices/${invoiceId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setInvoice(response.data);
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchInvoice();
  }, [invoiceId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(`${config.API_URL}/invoices/${invoice.id}/apply-credit-memo`, creditMemo, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      navigate('/client-invoices');
    } catch (error) {
      console.error('Error applying credit memo:', error);
    }
  };

  const handlePaymentMethodChange = (e) => {
    const { value } = e.target;
    setCreditMemo({ ...creditMemo, paymentMethod: value });
  };

  return (
    <div className="credit-memo-container">
      <h1>Apply Credit Memo</h1>
      {loading ? (
        <p>Loading...</p>
      ) : invoice ? (
        <div>
          <div className="invoice-details">
            <h2>Invoice Details</h2>
            <p><strong>Invoice Number:</strong> {invoice.invoiceNumber}</p>
            <p><strong>Amount:</strong> ${invoice.amount}</p>
            <p><strong>Status:</strong> {invoice.status}</p>
            <p><strong>Due Date:</strong> {invoice.dueDate.split('T')[0]}</p>
            <p><strong>Invoice Date:</strong> {invoice.invoiceDate.split('T')[0]}</p>
            <p><strong>Terms:</strong> {invoice.paymentTerms}</p>
            <p><strong>Customer:</strong> {invoice.Customer ? invoice.Customer.name : 'N/A'}</p>
            <p><strong>Balance:</strong> ${invoice.balance}</p>
          </div>
          <form onSubmit={handleSubmit} className="credit-memo-form">
            <div className="form-group">
              <label>Amount:</label>
              <input
                type="number"
                value={creditMemo.amount}
                onChange={(e) => setCreditMemo({ ...creditMemo, amount: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Payment Method:</label>
              <select value={creditMemo.paymentMethod} onChange={handlePaymentMethodChange} required>
                <option value="Credit Card">Credit Card</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="PayPal">PayPal</option>
              </select>
            </div>
            {creditMemo.paymentMethod === 'Credit Card' && (
              <>
                <div className="form-group">
                  <label>Card Number:</label>
                  <input
                    type="text"
                    value={creditMemo.cardNumber}
                    onChange={(e) => setCreditMemo({ ...creditMemo, cardNumber: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Card Expiry:</label>
                  <input
                    type="text"
                    value={creditMemo.cardExpiry}
                    onChange={(e) => setCreditMemo({ ...creditMemo, cardExpiry: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Card CVC:</label>
                  <input
                    type="text"
                    value={creditMemo.cardCVC}
                    onChange={(e) => setCreditMemo({ ...creditMemo, cardCVC: e.target.value })}
                    required
                  />
                </div>
              </>
            )}
            <div className="form-group">
              <label>Comment:</label>
              <input
                type="text"
                value={creditMemo.comment}
                onChange={(e) => setCreditMemo({ ...creditMemo, comment: e.target.value })}
              />
            </div>
            <button type="submit" className="submit-button">Apply Credit Memo</button>
          </form>
        </div>
      ) : (
        <p>Invoice not found</p>
      )}
    </div>
  );
}

export default ApplyCreditMemo;
