// PaymentOptionForm.js
import React from 'react';

const PaymentOptionForm = ({ index, paymentOption, onChange, onRemove }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(index, { ...paymentOption, [name]: value });
  };

  return (
    <div className="payment-option-group">
      <div className="form-group">
        <label htmlFor={`payment-type-${index}`}>Payment Type</label>
        <select
          name="type"
          value={paymentOption.type}
          onChange={handleChange}
          required
        >
          <option value="Check">Check</option>
          <option value="ACH">ACH</option>
        </select>
      </div>
      {paymentOption.type === 'Check' && (
        <div className="form-group">
          <label htmlFor={`payeeName-${index}`}>Payee Name</label>
          <input
            type="text"
            name="payeeName"
            value={paymentOption.payeeName || ''}
            onChange={handleChange}
            placeholder="Payee Name"
            required
          />
        </div>
      )}
      {paymentOption.type === 'ACH' && (
        <>
          <div className="form-group">
            <label htmlFor={`bankName-${index}`}>Bank Name</label>
            <input
              type="text"
              name="bankName"
              value={paymentOption.bankName || ''}
              onChange={handleChange}
              placeholder="Bank Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`accountNumber-${index}`}>Account Number</label>
            <input
              type="text"
              name="accountNumber"
              value={paymentOption.accountNumber || ''}
              onChange={handleChange}
              placeholder="Account Number"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`routingNumber-${index}`}>Routing Number</label>
            <input
              type="text"
              name="routingNumber"
              value={paymentOption.routingNumber || ''}
              onChange={handleChange}
              placeholder="Routing Number"
            />
          </div>
        </>
      )}
      <div className="form-group">
        <label htmlFor={`preferenceRank-${index}`}>Preference Rank</label>
        <input
          type="number"
          name="preferenceRank"
          value={paymentOption.preferenceRank}
          onChange={handleChange}
          placeholder="Preference Rank"
          required
        />
      </div>
      <button type="button" onClick={() => onRemove(index)} className="remove-payment-option-button">Remove Payment Option</button>
    </div>
  );
};

export default PaymentOptionForm;
