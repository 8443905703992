// components/CustomerDocumentsTab.js
import React from 'react';
import CustomerStatement from './CustomerStatement';

function CustomerDocumentsTab({ customerId, user, statement }) {
  return (
    <div>
      <h2>Customer Documents</h2>
      {/* Add logic to handle other types of documents here */}
      <CustomerStatement statement={statement} />
    </div>
  );
}

export default CustomerDocumentsTab;
