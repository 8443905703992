// components/ClientCustomers.js
import React from 'react';
import './ClientCustomers.css'; // Ensure you have the CSS file in the same directory

function ClientCustomers({ customers }) {
  return (
    <div className="client-customers">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {customers.map(customer => (
              <tr key={customer.id}>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClientCustomers;
