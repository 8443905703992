import React from 'react';
import { useNavigate } from 'react-router-dom';
import GenericSearch from './GenericSearch';
import './EmployeeSearch.css';
import config from '../config';

function EmployeeSearch() {
  const navigate = useNavigate();

  const employeeSearchConfig = {
    entity: 'employees',
    metadataEndpoint: `${config.API_URL}/metadata/employees`,
    searchEndpoint: `${config.API_URL}/employees/search`,
    title: 'Employee Search',
  };

  const handleSelectEntity = (entity) => {
    navigate(`/employees/${entity.id}`);
  };

  return (
    <div className="flexDiv">
      <GenericSearch
        config={employeeSearchConfig}
        onSelectItem={handleSelectEntity}
        closeModal={() => {}} // No modal, so this can be empty or no-op
        inModal={false}
      />
    </div>
  );
}

export default EmployeeSearch;
