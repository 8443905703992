import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';  // Import axiosInstance
import config from '../config';  // Import config for API_URL

const IRSReports = () => {
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');  // New state for month
  const [reportType, setReportType] = useState('form-940-annual');  // Default report type
  const [headerData, setHeaderData] = useState(null);  // Change to handle single object
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchReport = async () => {
    setLoading(true);
    setError('');
    try {
      let url = `${config.API_URL}/irs-reports/${reportType}/${year}`;
      
      // If the new report is selected, include the month
      if ((reportType === 'monthly-payment-to-irs' || reportType === 'schwab-simple-ira-monthly')) {
        url = `${config.API_URL}/irs-reports/${reportType}/${year}/${month}`;
      }

      // Use axiosInstance to make the request based on the selected report type
      const response = await axiosInstance.get(url);
      setHeaderData(response.data.header);  // Handle single object
      setTableData(response.data.table);
    } catch (err) {
      setError('Error fetching data. Please try again.');
    }
    setLoading(false);
  };

  const renderForm940Table = () => {
    if (tableData.length > 0) {
      return (
        <div>
          <h3>Form 940 - Annual Report</h3>
          <table border="1">
            <thead>
              <tr>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.description}</td>
                  <td>{row.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  
    return <p>No data available for Form 940.</p>;
  };

  const renderW2Table = () => {
    if (tableData.length > 0) {
      return (
        <div>
          <h3>W2 - Annual Report</h3>
          <table border="1">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Social Security Number</th>
                <th>Gross YTD</th>
                <th>Total SS</th>
                <th>SS Only</th>
                <th>Medicare</th>
                <th>Federal Tax Withheld</th>
                <th>Simple IRA</th>
                <th>Gross Less IRA</th>
                <th>Simple Match</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.employee}</td>
                  <td>{row.ssn}</td>
                  <td>{row.gross_ytd}</td>
                  <td>{row.total_ss}</td>
                  <td>{row.ss_only}</td>
                  <td>{row.medicare}</td>
                  <td>{row.federal_withheld}</td>
                  <td>{row.simpla_ira}</td>
                  <td>{row.gross_less_ira}</td>
                  <td>{row.simple_match}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  
    return <p>No data available for W2.</p>;
  };
  

  return (
    <div className="flexDiv">
      <h2>IRS Reports</h2>

      <div>
        <label>Enter Year: </label>
        <input 
          type="number" 
          value={year} 
          onChange={(e) => setYear(e.target.value)} 
          placeholder="e.g., 2023"
        />

        {(reportType === 'monthly-payment-to-irs' || reportType === 'schwab-simple-ira-monthly') && (
          <div>
            <label>Enter Month: </label>
            <input 
              type="number" 
              value={month} 
              onChange={(e) => setMonth(e.target.value)} 
              placeholder="e.g., 2 for February"
            />
          </div>
        )}

        <label>Select Report Type: </label>
        <select value={reportType} onChange={(e) => setReportType(e.target.value)}>
          <option value="form-940-annual">Form 940 - Annual</option>
          <option value="schwab-simple-ira">Schwab Simple IRA - Annual</option>
          <option value="schwab-simple-ira-monthly">Schwab Simple IRA - Monthly</option>
          <option value="monthly-payment-to-irs">Payment to IRS - Monthly</option>
          <option value="w2-annual">W2 - Annual</option> 
        </select>

        <button onClick={fetchReport} disabled={!year || ((reportType === 'monthly-payment-to-irs' || reportType === 'schwab-simple-ira-monthly') && !month)}>
          Fetch Report
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {reportType === 'form-940-annual' && renderForm940Table()}
      {reportType === 'w2-annual' && renderW2Table()}  {/* Add new W2 report renderer */}

      {headerData && reportType !== 'form-940-annual' && reportType !== 'w2-annual' && (  // Check if headerData exists for other reports
        <div>
          <h3>Header Data</h3>
          <table border="1">
            <thead>
              <tr>
                <th>Check Date</th>
                <th>Check Number</th>
                <th>Supplier Name</th>
                <th>Supplier Address</th>
                <th>Supplier Account</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{new Date(headerData["Check Date"]).toLocaleDateString()}</td> {/* Format date */}
                <td>{headerData["Check Number"]}</td>
                <td>{headerData.supplierName}</td>
                <td>{headerData.supplierAddress}</td>
                <td>{headerData.supplierAccount}</td>
                <td>{headerData.totalamount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {tableData.length > 0 && reportType !== 'form-940-annual' && reportType !== 'w2-annual' && (
        <div>
          <h3>Table Data</h3>
          <table border="1">
            <thead>
              <tr>
                <th>Invoice</th>
                <th>Paid Amount</th>
                <th>Total Invoice Amount</th>
                <th>Balance</th>
                <th>Invoice Account Number</th>
                <th>Invoice Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.Invoice}</td>
                  <td>{row['Paid Amount']}</td>
                  <td>{row['Total Invoice Amount']}</td>
                  <td>{row.Balance}</td>
                  <td>{row['Invoice Account Number']}</td>
                  <td>{new Date(row['Invoice Date']).toLocaleDateString()}</td> {/* Format date */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default IRSReports;
