// src/components/PaymentPortal.js
import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './PaymentPortal.css'; // Import the CSS file for styling

function PaymentPortal() {
  const [amount, setAmount] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [message, setMessage] = useState('');

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(
        `${config.API_URL}/customer/payments`,
        { amount, invoiceId },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setMessage('Payment successful');
    } catch (error) {
      console.error('Error making payment:', error);
      setMessage('Payment failed');
    }
  };

  return (
    <div className="payment-portal-container">
      <h1>Payment Portal</h1>
      <form onSubmit={handlePayment} className="payment-form">
        <div className="form-group">
          <label>Invoice ID:</label>
          <input type="text" value={invoiceId} onChange={(e) => setInvoiceId(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Amount:</label>
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required />
        </div>
        <button type="submit" className="payment-button">Make Payment</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default PaymentPortal;
