import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './BalanceSheetManager.css';

const BalanceSheetManager = () => {
    const [preparedYears, setPreparedYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [newYear, setNewYear] = useState('');
    const [selectedYears, setSelectedYears] = useState([]);
    const [balanceSheets, setBalanceSheets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchPreparedYears();
    }, []);

    const fetchPreparedYears = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${config.API_URL}/financials/balance-sheets/prepared-years`);
            setPreparedYears(response.data);
            setError('');
        } catch (error) {
            console.error('Error fetching prepared years:', error);
            setError('Error fetching prepared years');
        }
        setIsLoading(false);
    };

    const fetchBalanceSheet = async (year) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${config.API_URL}/financials/balance-sheets/${year}`);
            setBalanceSheets([{ year, data: response.data }]);
            setError('');
        } catch (error) {
            console.error('Error fetching balance sheet:', error);
            setBalanceSheets([]);
            setError('Error fetching balance sheet');
        }
        setIsLoading(false);
    };

    const fetchMultipleBalanceSheets = async (years) => {
        setIsLoading(true);
        try {
            const responses = await Promise.all(
                years.map(year => 
                    year === 'YTD'
                        ? axiosInstance.get(`${config.API_URL}/financials/balance-sheets/ytd`)
                        : axiosInstance.get(`${config.API_URL}/financials/balance-sheets/${year}`)
                )
            );
            const sheets = responses.map((response, index) => ({
                year: years[index],
                data: response.data.balanceSheet || response.data
            }));
            setBalanceSheets(sheets);
            setError('');
        } catch (error) {
            console.error('Error fetching multiple balance sheets:', error);
            setBalanceSheets([]);
            setError('Error fetching multiple balance sheets');
        }
        setIsLoading(false);
    };

    const prepareBalanceSheet = async (year) => {
        const yearExists = preparedYears.includes(parseInt(year));
        if (yearExists) {
            const confirmPrepare = window.confirm(`Year ${year} already exists. Do you want to overwrite it?`);
            if (!confirmPrepare) return;
        } else {
            const confirmPrepare = window.confirm(`Are you sure you want to prepare the balance sheet for the year ${year}?`);
            if (!confirmPrepare) return;
        }

        setIsLoading(true);
        try {
            if (yearExists) {
                // Delete existing balance sheet for the year before preparing a new one
                await axiosInstance.delete(`${config.API_URL}/financials/balance-sheets/${year}`);
            }
            await axiosInstance.post(`${config.API_URL}/financials/balance-sheets/prepare/${year}`);
            fetchPreparedYears();
            fetchBalanceSheet(year);
            setError('');
        } catch (error) {
            console.error('Error preparing balance sheet:', error);
            setError('Error preparing balance sheet');
        }
        setIsLoading(false);
    };

    const fetchYearToDateBalanceSheet = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${config.API_URL}/financials/balance-sheets/ytd`);
            setBalanceSheets([{ year: 'YTD', data: response.data.balanceSheet }]);
            setError('');
        } catch (error) {
            console.error('Error fetching year-to-date balance sheet:', error);
            setBalanceSheets([]);
            setError('Error fetching year-to-date balance sheet');
        }
        setIsLoading(false);
    };

    const handleYearSelection = (year) => {
        setSelectedYears(prevSelected => {
            if (prevSelected.includes(year)) {
                return prevSelected.filter(y => y !== year);
            } else {
                return [...prevSelected, year];
            }
        });
    };

    const transposeData = (sheets) => {
        const allEntries = {};
        sheets.forEach(sheet => {
            sheet.data.forEach(entry => {
                const key = `${entry.minNumericPrefix}-${entry.accountCategory}-${entry.accountType}`;
                if (!allEntries[key]) {
                    allEntries[key] = {
                        minNumericPrefix: entry.minNumericPrefix,
                        accountCategory: entry.accountCategory,
                        accountType: entry.accountType,
                    };
                }
                allEntries[key][sheet.year] = entry.netBalance;
            });
        });
        return Object.values(allEntries);
    };

    const renderTable = () => {
        if (balanceSheets.length === 0) {
            return null;
        }

        const transposedData = transposeData(balanceSheets);

        const headers = [
            'Min Numeric Prefix',
            'Account Category',
            'Account Type',
            ...balanceSheets.map(sheet => sheet.year),
        ];

        return (
            <table className="balance-sheet-table">
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {transposedData.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.minNumericPrefix}</td>
                            <td>{entry.accountCategory}</td>
                            <td>{entry.accountType}</td>
                            {balanceSheets.map(sheet => (
                                <td key={sheet.year}>{entry[sheet.year] || '-'}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="balance-sheet-manager">
            <h1>Balance Sheet Manager</h1>
            <div className="actions">
                <button onClick={fetchYearToDateBalanceSheet} disabled={isLoading}>Fetch Year-to-Date Balance Sheet</button>
                <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    disabled={isLoading}
                >
                    <option value="" disabled>Select Year</option>
                    {preparedYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
                <button onClick={() => fetchBalanceSheet(selectedYear)} disabled={!selectedYear || isLoading}>
                    Fetch Selected Year Balance Sheet
                </button>
                <input
                    type="number"
                    placeholder="Enter Year"
                    value={newYear}
                    onChange={(e) => setNewYear(e.target.value)}
                    disabled={isLoading}
                />
                <button onClick={() => prepareBalanceSheet(newYear)} disabled={!newYear || isLoading}>
                    Prepare/Re-prepare Balance Sheet
                </button>
            </div>
            <div className="multiple-actions">
                <h2>Select Years to Fetch Balance Sheets</h2>
                <div className="year-checkboxes">
                    <label key="YTD">
                        <input
                            type="checkbox"
                            value="YTD"
                            checked={selectedYears.includes("YTD")}
                            onChange={() => handleYearSelection("YTD")}
                            disabled={isLoading}
                        />
                        YTD
                    </label>
                    {preparedYears.map((year) => (
                        <label key={year}>
                            <input
                                type="checkbox"
                                value={year}
                                checked={selectedYears.includes(year)}
                                onChange={() => handleYearSelection(year)}
                                disabled={isLoading}
                            />
                            {year}
                        </label>
                    ))}
                </div>
                <button onClick={() => fetchMultipleBalanceSheets(selectedYears)} disabled={selectedYears.length === 0 || isLoading}>
                    Fetch Multiple Years Balance Sheets
                </button>
            </div>
            {isLoading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            {renderTable()}
        </div>
    );
};

export default BalanceSheetManager;
