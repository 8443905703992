import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';

const LogLevelManager = () => {
  const [logLevel, setLogLevel] = useState('');
  const [currentLogLevel, setCurrentLogLevel] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchCurrentLogLevel = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/logs/get-log-level`);
        setCurrentLogLevel(response.data.level);
      } catch (error) {
        setMessage('Error fetching current log level');
      }
    };

    fetchCurrentLogLevel();
  }, []);

  const handleLogLevelChange = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(`${config.API_URL}/logs/set-log-level`, { level: logLevel });
      setMessage(response.data.message);
      setCurrentLogLevel(logLevel); // Update current log level
    } catch (error) {
      setMessage(error.response ? error.response.data.error : 'Error changing log level');
    }
  };

  return (
    <div>
      <h2>Log Level Manager</h2>
      <p>Current Log Level: {currentLogLevel}</p>
      <form onSubmit={handleLogLevelChange}>
        <div>
          <label>
            Select Log Level:
            <select value={logLevel} onChange={(e) => setLogLevel(e.target.value)}>
              <option value="">Select a level</option>
              <option value="error">Error</option>
              <option value="warn">Warn</option>
              <option value="info">Info</option>
              <option value="http">HTTP</option>
              <option value="verbose">Verbose</option>
              <option value="debug">Debug</option>
              <option value="silly">Silly</option>
              <option value="none">None</option>
            </select>
          </label>
        </div>
        <button type="submit">Change Log Level</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default LogLevelManager;
