import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { notification } from 'antd';
import config from '../config';
import GenericSearch from './GenericSearch';
import './AddOrder.css';

Modal.setAppElement('#root');

function AddOrder() {
  const [client, setClient] = useState(null);
  const [clientName, setClientName] = useState('');
  const [orders, setOrders] = useState([{
    poNumber: '',
    amount: '',
    date: '',
    customerId: '',
    customerName: '',
    balance: '',
    completionDate: '',
    terms: 'NET 30',
    approvalStatus: 'Pending',
    limit: '',
    comments: '',
    approvalDate: '',
    selected: false,
  }]);
  const [selectAll, setSelectAll] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [currentOrderIndex, setCurrentOrderIndex] = useState(null);

  const navigate = useNavigate();
  const lastPORef = useRef(null);

  useEffect(() => {
    if (client) {
      axiosInstance.get(`${config.API_URL}/clients/${client}`)
        .then(response => {
          setClientName(response.data.name);
        })
        .catch(error => {
          console.error('Error fetching client name:', error);
          notification.error({
            message: 'Error',
            description: 'Failed to fetch client name. Please try again later.',
          });
        });
    }
  }, [client]);

  const handleClientSelect = (client) => {
    setClient(client.id);
    setClientName(client.name);
  };

  const handleCustomerSelect = (customer) => {
    const updatedOrders = [...orders];
    if (currentOrderIndex !== null) {
      updatedOrders[currentOrderIndex].customerId = customer.id;
      updatedOrders[currentOrderIndex].customerName = customer.name;
      setOrders(updatedOrders);
      console.log('Customer selected:', customer); // Debug statement
      setIsCustomerModalOpen(false);
    }
  };

  const formatNumber = (value) => {
    if (!value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const parseNumber = (value) => {
    if (!value) return '';
    return value.toString().replace(/,/g, '');
  };

  const handleOrderChange = (index, e) => {
    const updatedOrders = [...orders];
    const { name, value } = e.target;

    if (name === 'amount' || name === 'limit') {
      const parsedValue = parseNumber(value);
      updatedOrders[index][name] = parsedValue;
      if (name === 'amount') {
        updatedOrders[index]['balance'] = parsedValue;
      }
    } else {
      updatedOrders[index][name] = value;
    }

    setOrders(updatedOrders);
  };

  const handleFocus = (index) => {
    setCurrentOrderIndex(index);
  };

  const addOrderRow = () => {
    const newOrder = {
      poNumber: '',
      amount: '',
      date: '',
      customerId: '',
      customerName: '',
      balance: '',
      completionDate: '',
      terms: 'NET 30',
      approvalStatus: 'Pending',
      limit: '',
      comments: '',
      approvalDate: '',
      selected: false,
    };
    setOrders([...orders, newOrder]);

    // Set focus to the PO# field of the newly added row
    setTimeout(() => {
      if (lastPORef.current) {
        lastPORef.current.focus();
      }
    }, 0);
  };

  const removeSelectedRows = () => {
    const updatedOrders = orders.filter(order => !order.selected);
    setOrders(updatedOrders);
  };

  const copySelectedRows = () => {
    const selectedOrders = orders.filter(order => order.selected);
    const copiedOrders = selectedOrders.map(order => ({
      ...order,
      poNumber: '',
      customerId: order.customerId,
      customerName: order.customerName,
      date: '',
      completionDate: '',
      approvalDate: '',
      selected: false,
    }));
    setOrders([...orders, ...copiedOrders]);
    console.log('Copied Orders:', copiedOrders); // Debug statement

    // Set focus to the PO# field of the newly copied row
    setTimeout(() => {
      if (lastPORef.current) {
        lastPORef.current.focus();
      }
    }, 0);
  };

  const copyLastRow = () => {
    if(orders && orders.length > 0) {
      const lastOrder = orders[orders.length - 1];
      const copiedOrder = {
        ...lastOrder,
        poNumber: '',
        customerId: lastOrder.customerId,
        customerName: lastOrder.customerName,
        amount: '',
        date: '',
        limit: '',
        completionDate: '',
        approvalDate: '',
        comments: '',
        selected: false,
      };
      setOrders([...orders, copiedOrder]);
      //console.log('Copied Last Order:', copiedOrder); // Debug statement
  
      // Set focus to the PO# field of the newly copied row
      setTimeout(() => {
        if (lastPORef.current) {
          lastPORef.current.focus();
        }
      }, 0);
    }
  };

  const toggleSelectAll = () => {
    const updatedOrders = orders.map(order => ({
      ...order,
      selected: !selectAll,
    }));
    setSelectAll(!selectAll);
    setOrders(updatedOrders);
  };

  const toggleSelectRow = (index) => {
    const updatedOrders = [...orders];
    updatedOrders[index].selected = !updatedOrders[index].selected;
    setOrders(updatedOrders);
  };

  const openCustomerModal = (index) => {
    setCurrentOrderIndex(index);
    setIsCustomerModalOpen(true);
  };

  const openClientModal = () => {
    setIsClientModalOpen(true);
  };

  const validateForm = () => {
    if (!client) {
      notification.error({
        message: 'Validation Error',
        description: 'Client is required.',
      });
      return false;
    }

    for (let order of orders) {
      if (!order.poNumber) {
        notification.error({
          message: 'Validation Error',
          description: 'PO # is required.',
        });
        return false;
      }

      if (!order.customerId) {
        notification.error({
          message: 'Validation Error',
          description: 'Customer is required.',
        });
        return false;
      }

      if (!order.amount || isNaN(order.amount)) {
        notification.error({
          message: 'Validation Error',
          description: 'Amount is required and must be numeric.',
        });
        return false;
      }

      if (!order.date) {
        notification.error({
          message: 'Validation Error',
          description: 'Date is required.',
        });
        return false;
      }

      if (order.limit && isNaN(order.limit)) {
        notification.error({
          message: 'Validation Error',
          description: 'Credit limit must be numeric if provided.',
        });
        return false;
      }
    }

    if (orders.length === 0) {
      notification.error({
        message: 'Validation Error',
        description: 'At least one Purchase Order row must exist.',
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const formattedOrders = orders.map(order => ({
        poNumber: order.poNumber,
        amount: parseFloat(order.amount),
        date: order.date,
        customerId: order.customerId,
        clientId: client,
        balance: parseFloat(order.balance),
        completionDate: order.completionDate,
        terms: order.terms,
        approvalStatus: order.approvalStatus,
        limit: parseFloat(order.limit),
        comments: order.comments,
        approvalDate: order.approvalDate,
      }));

      await axiosInstance.post(`${config.API_URL}/orders`, formattedOrders);
      notification.success({
        message: 'Success',
        description: 'Purchase orders submitted successfully.',
      });
      navigate(`/purchase-order-search?clientName=${encodeURIComponent(clientName)}`);
    } catch (error) {
      console.error('Error creating orders:', error);
      const errorMessage = error.response?.data?.message || 'Failed to create orders. Please check the details and try again.';
      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    }
  };

  const entitySearchConfig = {
    client: {
      entity: 'clients',
      metadataEndpoint: `${config.API_URL}/metadata/clients`,
      searchEndpoint: `${config.API_URL}/clients/search`,
      title: 'Client Search',
    },
    customer: {
      entity: 'customers',
      metadataEndpoint: `${config.API_URL}/metadata/customers`,
      searchEndpoint: `${config.API_URL}/customers/search`,
      title: 'Customer Search'
    }
  };

  return (
    <div className="flexDiv">
      <form onSubmit={handleSubmit}>
        <h1>Add Purchase Orders</h1>
        <div className="form-header">
          <div className="form-element-full-width">
            <label>
              Client:
              <div className="inline-search-container">
                <GenericSearch
                  config={entitySearchConfig.client}
                  onSelectItem={handleClientSelect}
                  inlineSearch={true}
                  selectedValue={clientName}
                  autoFocus={true}
                  openModal={openClientModal} // Pass open modal function
                />
              </div>
            </label>
          </div>
        </div>

        <Modal
          isOpen={isClientModalOpen}
          onRequestClose={() => setIsClientModalOpen(false)}
          contentLabel="Client Search"
          className="search-modal"
          overlayClassName="search-modal-overlay"
        >
          <div className="search-content">
            <div className="search-header">
              <h2>Client Search</h2>
              <button className="close-button" onClick={() => setIsClientModalOpen(false)}>&times;</button>
            </div>
            <div className="search-body">
              <GenericSearch config={entitySearchConfig.client} onSelectItem={handleClientSelect} closeModal={() => setIsClientModalOpen(false)} inModal={true} selectedValue={clientName} />
            </div>
          </div>
        </Modal>

        <div className="actions-container">
          <button type="button" onClick={removeSelectedRows} tabIndex="-1">Remove Selected Row(s)</button>
          <button type="button" onClick={copySelectedRows} tabIndex="-1">Copy Selected Row(s)</button>
        </div>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    tabIndex="-1"
                    checked={selectAll}
                    onChange={toggleSelectAll}
                  />
                </th>
                <th>PO#</th>
                <th>Customer</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Completion Date</th>
                <th className="terms-column">Payment Terms</th>
                <th className="approval-status-column">Approval Status</th>
                <th>Credit Limit</th>
                <th>Approval Date</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index} onFocus={() => handleFocus(index)}>
                  <td>
                    <input
                      type="checkbox"
                      tabIndex="-1"
                      checked={order.selected}
                      onChange={() => toggleSelectRow(index)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="poNumber"
                      value={order.poNumber}
                      onChange={(e) => handleOrderChange(index, e)}
                      ref={index === orders.length - 1 ? lastPORef : null}
                    />
                  </td>
                  <td>
                    <GenericSearch
                      config={entitySearchConfig.customer}
                      onSelectItem={(customer) => {
                        setCurrentOrderIndex(index); // Set currentOrderIndex before selecting an item
                        handleCustomerSelect(customer, index);
                      }}
                      inlineSearch={true}
                      selectedValue={order.customerName}
                      openModal={() => openCustomerModal(index)} // Pass open modal function
                    />
                  </td>
                  <td><input type="text" name="amount" value={formatNumber(order.amount)} onChange={(e) => handleOrderChange(index, e)} /></td>
                  <td><input type="date" name="date" value={order.date} onChange={(e) => handleOrderChange(index, e)} className="date-input" /></td>
                  <td style={{ display: 'none' }}><input type="text" name="balance" value={formatNumber(order.balance)} onChange={(e) => handleOrderChange(index, e)} /></td>
                  <td><input type="date" name="completionDate" value={order.completionDate} onChange={(e) => handleOrderChange(index, e)} className="date-input" /></td>
                  <td>
                    <select name="terms" value={order.terms} onChange={(e) => handleOrderChange(index, e)} required>
                      <option value="NET 30">NET 30</option>
                      <option value="NET 60">NET 60</option>
                      <option value="NET 90">NET 90</option>
                    </select>
                  </td>
                  <td>
                    <select name="approvalStatus" value={order.approvalStatus} onChange={(e) => handleOrderChange(index, e)} required>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                      <option value="Denied">Denied</option>
                      <option value="See Limit">See Limit</option>
                      <option value="Recourse">Recourse</option>
                    </select>
                  </td>
                  <td><input type="text" name="limit" value={formatNumber(order.limit)} onChange={(e) => handleOrderChange(index, e)} /></td>
                  <td><input type="date" name="approvalDate" value={order.approvalDate} onChange={(e) => handleOrderChange(index, e)} className="date-input" /></td>
                  <td><input type="text" name="comments" value={order.comments} onChange={(e) => handleOrderChange(index, e)}></input></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        <div className="fixed-form-buttons">
          <button type="button" onClick={copyLastRow}>Copy Last Row</button>
          <button type="button" onClick={addOrderRow}>Add Purchase Order</button>
          <button type="submit">Submit Purchase Orders</button>
        </div>
      </form>

      <Modal
        isOpen={isCustomerModalOpen}
        onRequestClose={() => setIsCustomerModalOpen(false)}
        contentLabel="Customer Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Customer Search</h2>
            <button className="close-button" onClick={() => setIsCustomerModalOpen(false)}>&times;</button>
          </div>
          <div className="search-body">
            <GenericSearch config={entitySearchConfig.customer} onSelectItem={handleCustomerSelect} closeModal={() => setIsCustomerModalOpen(false)} inModal={true} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddOrder;
