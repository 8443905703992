import React, { useState, useEffect } from 'react';
import FactoringRateComponent from '../FactoringRateComponent';
import { fetchClient, updateClient } from '../utils/apiClient';

function ClientDetailsTab({ clientId }) {
  const [client, setClient] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isEditingDetails, setIsEditingDetails] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const clientData = await fetchClient(clientId);
        setClient(clientData);
        setFormData({
          ...clientData,
          advanceRate: clientData.advanceRate ? (clientData.advanceRate * 100).toString() : '',
          additionalRate: clientData.additionalRate ? (clientData.additionalRate * 100).toString() : '',
          factoringRates: {
            type: clientData.feeType,
            rates: clientData.feeType === 'flat' ? {
              flatRate: clientData.flatFactoringRate ? (clientData.flatFactoringRate * 100).toString() : '',
              additionalRate: clientData.additionalRate ? (clientData.additionalRate * 100).toString() : ''
            } : clientData.slidingScaleRates
          }
        });
      } catch (error) {
        console.error('Error initializing client data:', error);
      }
    };

    initializeData();
  }, [clientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      advanceRate: formData.advanceRate ? parseFloat(formData.advanceRate) / 100 : null,
      additionalRate: formData.factoringRates.type === 'flat' && formData.factoringRates.rates.additionalRate
        ? parseFloat(formData.factoringRates.rates.additionalRate) / 100
        : null,
      flatFactoringRate: formData.factoringRates.type === 'flat' && formData.factoringRates.rates.flatRate
        ? parseFloat(formData.factoringRates.rates.flatRate) / 100
        : null,
      slidingScaleRates: formData.factoringRates.type === 'sliding'
        ? formData.factoringRates.rates
        : null,
      feeType: formData.factoringRates.type
    };
    try {
      const updatedClient = await updateClient(clientId, updatedFormData);
      setClient(updatedClient);
      setFormData({
        ...updatedClient,
        advanceRate: updatedClient.advanceRate ? (updatedClient.advanceRate * 100).toString() : '',
        additionalRate: updatedClient.additionalRate ? (updatedClient.additionalRate * 100).toString() : '',
        factoringRates: {
          type: updatedClient.feeType,
          rates: updatedClient.feeType === 'flat' ? {
            flatRate: updatedClient.flatFactoringRate ? (updatedClient.flatFactoringRate * 100).toString() : '',
            additionalRate: updatedClient.additionalRate ? (updatedClient.additionalRate * 100).toString() : ''
          } : updatedClient.slidingScaleRates
        }
      });
      setIsEditingDetails(false);
    } catch (error) {
      console.error('Error saving client details:', error);
    }
  };

  const handleCancelDetails = () => {
    setFormData({
      ...client,
      advanceRate: client.advanceRate ? (client.advanceRate * 100).toString() : '',
      additionalRate: client.additionalRate ? (client.additionalRate * 100).toString() : '',
      factoringRates: {
        type: client.feeType,
        rates: client.feeType === 'flat' ? {
          flatRate: client.flatFactoringRate ? (client.flatFactoringRate * 100).toString() : '',
          additionalRate: client.additionalRate ? (client.additionalRate * 100).toString() : ''
        } : client.slidingScaleRates
      }
    });
    setIsEditingDetails(false);
  };

  const handleRateChange = (rateData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      factoringRates: rateData,
    }));
  };

  if (!formData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isEditingDetails ? (
        <form onSubmit={handleSave}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="faxNumber">Fax Number</label>
            <input
              type="text"
              name="faxNumber"
              value={formData.faxNumber || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="advanceRate">Advance Rate (%)</label>
            <input
              type="number"
              name="advanceRate"
              value={formData.advanceRate || ''}
              onChange={handleChange}
            />
          </div>
          <FactoringRateComponent
            initialRateData={formData.factoringRates}
            onRateChange={handleRateChange}
          />
          <div className="form-group">
            <label htmlFor="termsOfContract">Terms of Contract</label>
            <textarea
              name="termsOfContract"
              value={formData.termsOfContract || ''}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="submit-button">Save</button>
          <button type="button" className="cancel-button" onClick={handleCancelDetails}>Cancel</button>
        </form>
      ) : (
        <div className="client-info">
          <p><strong>Name:</strong> {formData.name}</p>
          <p><strong>Account:</strong> {formData.account}</p>
          <p><strong>Fax Number:</strong> {formData.faxNumber}</p>
          <p><strong>Advance Rate:</strong> {formData.advanceRate}%</p>
          <p><strong>Terms of Contract:</strong> {formData.termsOfContract}</p>
          <p><strong>Fee Type:</strong> {formData.factoringRates.type}</p>
          {formData.factoringRates.type === 'flat' ? (
            <>
               <p><strong>Flat Factoring Rate:</strong> {formData.factoringRates.rates.flatRate}%</p>
                <p><strong>Additional Rate:</strong> {formData.factoringRates.rates.additionalRate}%</p>
            </>
        ) : (
            <div>
              <h3>Sliding Scale Rates</h3>
              <table className="sliding-scale-table">
                <thead>
                  <tr>
                    <th>Days</th>
                    <th>Rate (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.factoringRates.rates.length ? formData.factoringRates.rates.map((rate, index) => (
                    <tr key={index}>
                      <td>{rate.days}</td>
                      <td>{rate.rate}%</td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="2">No sliding scale rates available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <div className="client-actions">
            <button onClick={() => setIsEditingDetails(true)}>Edit</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientDetailsTab;
