// components/SimpleJsonViewer.js
import React from 'react';

const SimpleJsonViewer = ({ data }) => {
  return (
    <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {JSON.stringify(data, null, 2)}
    </div>
  );
};

export default SimpleJsonViewer;
