import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GenericSearch from './GenericSearch';
import config from '../config';

function ScheduleSearch() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialClientName = searchParams.get('clientName') || '';

  const handleSelectItem = (schedule) => {
    const scheduleId = schedule.id;
    navigate(`/schedules/${scheduleId}`);
  };

  const scheduleSearchConfig = {
    entity: 'schedules',
    metadataEndpoint: `${config.API_URL}/metadata/schedules`,
    searchEndpoint: `${config.API_URL}/schedules/search`,
    title: 'Schedule Search', // Ensure title is provided here
  };

  return (
    <GenericSearch
      config={scheduleSearchConfig}
      onSelectItem={handleSelectItem}
      closeModal={() => {}}
      inModal={false}
      title="Schedule"
      initialFormData={{ clientName: initialClientName }}
    />
  );
}

export default ScheduleSearch;
