import React, { useState, useEffect, useRef } from "react";
import CustomerAddresses from "./CustomerAddresses";  // Component to display addresses

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function CustomerAddressesTab({ formData, setFormData, customerId, updateCustomer }) {
  const [isEditingAddresses, setIsEditingAddresses] = useState(false);
  const [originalAddresses, setOriginalAddresses] = useState([]);
  const isInitialLoad = useRef(true);

  useEffect(() => {
    if (isInitialLoad.current && formData && formData.addresses) {
      setOriginalAddresses(deepCopy(formData.addresses));
      isInitialLoad.current = false;
    }
  }, [formData]);

  const handleAddressChange = (e, index) => {
    const { name, value } = e.target;
    const newAddresses = formData.addresses.map((address, i) =>
      i === index ? { ...address, [name]: value } : address
    );
    setFormData({
      ...formData,
      addresses: newAddresses,
    });
  };

  const addAddress = () => {
    const newAddresses = [
      ...formData.addresses,
      {
        addressLine1: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        addressee: "",
        type: "",  // Include the type field for address type
      },
    ];
    setFormData({
      ...formData,
      addresses: newAddresses,
    });
  };

  const removeAddress = (index) => {
    const newAddresses = formData.addresses.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      addresses: newAddresses,
    });
  };

  const handleSaveAddresses = async (e) => {
    e.preventDefault();  // Prevent the form from navigating to a different URL
  
    console.log("Before saving, formData:", formData);  // Log the formData to ensure addresses are included
    if (!formData.addresses) {
      console.error("Addresses are missing in formData!");
      return;  // Prevent save if addresses are missing
    }
    
    try {
      const updatedCustomer = await updateCustomer(customerId, {
        addresses: formData.addresses,
      });
      console.log("Updated customer data:", updatedCustomer);  // Log the updated customer data
      setFormData(updatedCustomer);  // Update formData after saving
      setIsEditingAddresses(false);
    } catch (error) {
      console.error("Error saving addresses:", error);
    }
  };

  const handleCancelAddresses = () => {
    setFormData({
      ...formData,
      addresses: deepCopy(originalAddresses),
    });
    setIsEditingAddresses(false);
  };

  return (
    <div>
      {isEditingAddresses ? (
        <form onSubmit={handleSaveAddresses}>
          <h3>Addresses</h3>
          {formData.addresses.map((address, index) => (
            <div key={index} className="address-group">
              <div className="form-group">
                <label htmlFor={`address-type-${index}`}>Address Type</label>
                <input
                  type="text"
                  name="type"
                  value={address.type || ""} // New Type Field
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-addressee-${index}`}>Addressee</label>
                <input
                  type="text"
                  name="addressee"
                  value={address.addressee || ""}
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-line1-${index}`}>Address Line 1</label>
                <input
                  type="text"
                  name="addressLine1"
                  value={address.addressLine1 || ""}
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-line2-${index}`}>Address Line 2</label>
                <input
                  type="text"
                  name="addressLine2"
                  value={address.addressLine2 || ""}
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-city-${index}`}>City</label>
                <input
                  type="text"
                  name="city"
                  value={address.city || ""}
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-state-${index}`}>State</label>
                <input
                  type="text"
                  name="state"
                  value={address.state || ""}
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-postalCode-${index}`}>
                  Postal Code
                </label>
                <input
                  type="text"
                  name="postalCode"
                  value={address.postalCode || ""}
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-country-${index}`}>Country</label>
                <input
                  type="text"
                  name="country"
                  value={address.country || ""}
                  onChange={(e) => handleAddressChange(e, index)}
                />
              </div>
              <button
                type="button"
                onClick={() => removeAddress(index)}
                className="remove-address-button"
              >
                Remove Address
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addAddress}
            className="add-address-button"
          >
            Add Another Address
          </button>
          <button type="submit" className="submit-button">
            Save
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={handleCancelAddresses}
          >
            Cancel
          </button>
        </form>
      ) : (
        <>
          <CustomerAddresses addresses={formData.addresses} />
          <div className="customer-actions">
            <button onClick={() => setIsEditingAddresses(true)}>Edit</button>
          </div>
        </>
      )}
    </div>
  );
}

export default CustomerAddressesTab;
