import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useParams } from 'react-router-dom';
import './EmployeeDetails.css';

function EmployeeDetails() {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    account: '',
    lastName: '',
    firstName: '',
    ssn: '',
    filingStatus: '',
    exemptions: '',
    periodType: '',
    payRate: '',
    code: '',
    discAcct1: '',
    discAcct2: '',
    taxAcct1: '',
    taxAcct2: '',
    initials: '',
  });

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/employees/${id}`);
        setEmployee(response.data);
        setFormData({
          account: response.data.account || '',
          lastName: response.data.lastName || '',
          firstName: response.data.firstName || '',
          ssn: response.data.ssn || '',
          filingStatus: response.data.filingStatus || '',
          exemptions: response.data.exemptions || '',
          periodType: response.data.periodType || '',
          payRate: response.data.payRate || '',
          code: response.data.code || '',
          discAcct1: response.data.discAcct1 || '',
          discAcct2: response.data.discAcct2 || '',
          taxAcct1: response.data.taxAcct1 || '',
          taxAcct2: response.data.taxAcct2 || '',
          initials: response.data.initials || '',
        });
      } catch (error) {
        console.error('Error fetching employee:', error);
      }
    };

    fetchEmployee();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`${config.API_URL}/employees/${id}`, formData);
      setEmployee({ ...employee, ...formData });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating employee:', error);
    }
  };

  if (!employee) {
    return <div>Loading...</div>;
  }

  return (
    <div className="employee-details-container">
      <h2>Employee Details</h2>
      {isEditing ? (
        <form onSubmit={handleSubmit} className="employee-details-form">
          <div className="form-group">
            <label htmlFor="account">Account</label>
            <input type="text" name="account" value={formData.account} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="ssn">SSN</label>
            <input type="text" name="ssn" value={formData.ssn} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="filingStatus">Filing Status</label>
            <input type="text" name="filingStatus" value={formData.filingStatus} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="exemptions">Exemptions</label>
            <input type="number" name="exemptions" value={formData.exemptions} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="periodType">Period Type</label>
            <input type="text" name="periodType" value={formData.periodType} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="payRate">Pay Rate</label>
            <input type="number" name="payRate" value={formData.payRate} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input type="text" name="code" value={formData.code} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="discAcct1">Discount Account 1</label>
            <input type="text" name="discAcct1" value={formData.discAcct1} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="discAcct2">Discount Account 2</label>
            <input type="text" name="discAcct2" value={formData.discAcct2} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="taxAcct1">Tax Account 1</label>
            <input type="text" name="taxAcct1" value={formData.taxAcct1} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="taxAcct2">Tax Account 2</label>
            <input type="text" name="taxAcct2" value={formData.taxAcct2} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="initials">Initials</label>
            <input type="text" name="initials" value={formData.initials} onChange={handleChange} />
          </div>
          <button type="submit" className="submit-button">Save</button>
          <button type="button" onClick={handleEditToggle} className="cancel-button">Cancel</button>
        </form>
      ) : (
        <div className="employee-details">
          <p><strong>Account:</strong> {employee.account}</p>
          <p><strong>Last Name:</strong> {employee.lastName}</p>
          <p><strong>First Name:</strong> {employee.firstName}</p>
          <p><strong>SSN:</strong> {employee.ssn}</p>
          <p><strong>Filing Status:</strong> {employee.filingStatus}</p>
          <p><strong>Exemptions:</strong> {employee.exemptions}</p>
          <p><strong>Period Type:</strong> {employee.periodType}</p>
          <p><strong>Pay Rate:</strong> {employee.payRate}</p>
          <p><strong>Code:</strong> {employee.code}</p>
          <p><strong>Discount Account 1:</strong> {employee.discAcct1}</p>
          <p><strong>Discount Account 2:</strong> {employee.discAcct2}</p>
          <p><strong>Tax Account 1:</strong> {employee.taxAcct1}</p>
          <p><strong>Tax Account 2:</strong> {employee.taxAcct2}</p>
          <p><strong>Initials:</strong> {employee.initials}</p>
          <button onClick={handleEditToggle} className="edit-button">Edit</button>
        </div>
      )}
    </div>
  );
}

export default EmployeeDetails;
