// src/components/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file for styling

function Home() {
  return (
    <div className="home-container">
      <h1>Welcome to the Factoring Application</h1>
      <p>We are glad to have you here. Our application helps you manage your invoices, clients, and payments efficiently.</p>
      
      <div className="info-section">
        <h2>New Users</h2>
        <p>Your account is currently under review. Once verified, appropriate access will be granted based on your role. You will receive an email notification once your account is verified.</p>
        <p>In the meantime, feel free to explore our resources and get familiar with the application.</p>
      </div>

      <div className="features-section">
        <h2>Application Features</h2>
        <ul>
          <li><Link to="/features/client-management">Client and Customer Management</Link></li>
          <li><Link to="/features/invoice-management">Invoice and Schedule Management</Link></li>
          <li><Link to="/features/transactions">Deposits and Transactions</Link></li>
          <li><Link to="/features/reports">Reports and Collections</Link></li>
          <li><Link to="/features/order-management">Order Management</Link></li>
          <li><Link to="/features/call-center">Call Customer Center</Link></li>
        </ul>
      </div>

      <div className="resources-section">
        <h2>Resources</h2>
        <ul>
          <li><Link to="/getting-started">Getting Started Guide</Link></li>
          <li><Link to="/faq">Frequently Asked Questions</Link></li>
          <li><Link to="/support">Support</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
