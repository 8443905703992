import React from 'react';

const CreateAnotherPayment = ({ onCreateAnotherPayment, onProceedToReview }) => {
  return (
    <div className="create-another-payment">
    </div>
  );
};

export default CreateAnotherPayment;
