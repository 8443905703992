// SupplierDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSupplier, updateSupplier, fetchSupplierMetadata } from '../utils/apiSupplier';
import { useUserContext } from '../../context/UserContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './SupplierDetails.css';
import SupplierDetailsTab from './SupplierDetailsTab';
import SupplierContactsTab from './SupplierContactsTab';
import SupplierPaymentOptionsTab from './SupplierPaymentOptionsTab';
import SupplierNotesTab from './SupplierNotesTab';
import SupplierDocumentsTab from './SupplierDocumentsTab';
import SupplierMetadataTab from './SupplierMetadataTab';
import SupplierAddressesTab from './SupplierAddressesTab';  // New addresses tab component

function SupplierDetails() {
  const { supplierId } = useParams();
  const [supplier, setSupplier] = useState(null);
  const [formData, setFormData] = useState(null);
  const { user } = useUserContext();
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const supplierData = await fetchSupplier(supplierId);
        setSupplier(supplierData);
        setFormData({
          ...supplierData,
          contacts: supplierData.contacts || [],
          notes: supplierData.notes || [],
          paymentOptions: supplierData.paymentOptions || [],
          newNote: { message: '' }
        });

        const metadataData = await fetchSupplierMetadata(supplierId);
        setMetadata(metadataData);
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    };

    initializeData();
  }, [supplierId]);

  if (!supplier) {
    return <div>Loading...</div>;
  }

  return (
    <div className="supplier-details-container">
      <h1>Supplier Details</h1>
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Addresses</Tab>
          <Tab>Contacts</Tab>
          <Tab>Payment Options</Tab>
          <Tab>Notes</Tab>
          <Tab>Documents</Tab>
          <Tab>Metadata</Tab>
        </TabList>

        <TabPanel>
          <SupplierDetailsTab
            formData={formData}
            setFormData={setFormData}
            supplierId={supplierId}
            updateSupplier={updateSupplier}
            supplier={supplier}
          />
        </TabPanel>

        <TabPanel>
          <SupplierAddressesTab
            formData={supplier}
            setFormData={setSupplier}
            supplierId={supplierId}
            updateSupplier={updateSupplier}
          />
        </TabPanel>

        <TabPanel>
          <SupplierContactsTab
            formData={formData}
            setFormData={setFormData}
            supplierId={supplierId}
            updateSupplier={updateSupplier}
            supplier={supplier}
          />
        </TabPanel>

        <TabPanel>
          <SupplierPaymentOptionsTab
            formData={formData}
            setFormData={setFormData}
            supplierId={supplierId}
            updateSupplier={updateSupplier}
            supplier={supplier}
          />
        </TabPanel>

        <TabPanel>
          <SupplierNotesTab
            supplierId={supplierId}
            user={user}
            formData={formData}
            setFormData={setFormData}
            updateSupplier={updateSupplier}
          />
        </TabPanel>

        <TabPanel>
          <SupplierDocumentsTab
            supplierId={supplierId}
            user={user}
          />
        </TabPanel>

        <TabPanel>
          <SupplierMetadataTab
            metadata={metadata}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default SupplierDetails;
