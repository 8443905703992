import React from 'react';
import CustomerNotes from './CustomerNotes';
import { addCustomerNote, deleteCustomerNote } from '../utils/apiCustomer';

function CustomerNotesTab({ customerId, user, formData, setFormData }) {
  const handleAddNote = async (newNote) => {
    try {
      console.log(`Adding note to customer ${customerId}`, newNote);
      const addedNote = await addCustomerNote(customerId, newNote, user);
      setFormData(prevFormData => ({
        ...prevFormData,
        customerNotes: [...prevFormData.customerNotes, addedNote],
        newNote: { message: '' } // Reset newNote after adding
      }));
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      console.log(`Deleting note ${noteId}`);
      await deleteCustomerNote(customerId, noteId);
      setFormData(prevFormData => ({
        ...prevFormData,
        customerNotes: prevFormData.customerNotes.filter(note => note.id !== noteId)
      }));
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  return (
    <CustomerNotes
      notes={formData.customerNotes}
      newNote={formData.newNote}
      setNewNote={(newNote) => setFormData(prevFormData => ({ ...prevFormData, newNote }))}
      handleAddNote={handleAddNote}
      handleDeleteNote={handleDeleteNote}
    />
  );
}

export default CustomerNotesTab;
