import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';

function PaymentList() {
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPayments() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token provided.');
          return;
        }

        const response = await axiosInstance.get(`${config.API_URL}/payments`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPayments(response.data);
      } catch (err) {
        console.error('Error fetching payments:', err);
        setError('Error fetching payments. Please try again later.');
      }
    }
    fetchPayments();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit-payment/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`${config.API_URL}/payments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPayments(payments.filter(payment => payment.id !== id));
    } catch (err) {
      console.error('Error deleting payment:', err);
      setError('Error deleting payment. Please try again later.');
    }
  };

  const formatDateString = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
  };

  return (
    <div>
      <h1>Payments</h1>
      {error && <p>{error}</p>}
      <table>
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Invoice ID</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {payments.map(payment => (
            <tr key={payment.id}>
              <td>{payment.id}</td>
              <td>{payment.invoiceId}</td>
              <td>{payment.amount}</td>
              <td>{payment.status}</td>
              <td>{formatDateString(payment.date)}</td> {/* Format date as MM/dd/yyyy */}
              <td>
                <button onClick={() => handleEdit(payment.id)}>Edit</button>
                <button onClick={() => handleDelete(payment.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentList;
